import React, { Component} from 'react'
import { connect } from 'react-redux'
import { 
    Row, 
    Col, 
    Card, 
    Button, 
    Form, 
    Tab,
    Tabs} from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user, maps_key } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import { validateEmail } from '../../lib/helpers/helpers'
import SinDatos from '../../subComponents/general/sin_registros'
import PantallaCargando from '../../subComponents/general/pantalla_cargando'
import UsuariosListado from '../../subComponents/usuarios/listado'
import { confirmAlert } from "react-confirm-alert"
import { BsBriefcaseFill } from "react-icons/bs";
import withRouter from '../RoutesApp/wrapped_component'
import CrearUsuario from '../Usuarios/crear'
import ConteosListado from '../../subComponents/conteos/listado'
import PagosListado from '../../subComponents/pagos/list'
import MetodosPagoListado from '../../subComponents/metodos_pago/list'
import PlanListado from '../../subComponents/plan/list'
import SelectorPlanes from '../../subComponents/plan/selector'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import MediosBox from '../../subComponents/general/mediosbox'
import MantenedorEsquema from '../Estructura/mantenedorEsquema'
const acciones = [
    { slug: "habilitar", label: "Habilitar módulo"}
]

const acciones_especiales = {
    flota: [
        { slug: "crear-vehiculo", label: "Crear vehículo" },
        { slug: "modificar-vehiculo", label: "Modificar vehículo" },
        { slug: "monitoreo-flota", label: "Monitoreo de vehículos" },
        { slug: "informes-rastreo", label: "Informes de rastreo" },
        { slug: "expediciones", label: "Expediciones" },
        { slug: "invitaciones-ruta", label: "Invitaciones de ruta" },
        { slug: "reporte-app-conductor", label: "Reporte de aplicación" },
    ],
    tarifas: [
        { slug: "cotizacion-individual", label: "Cotización individual" },
        { slug: "cotizacion-excel", label: "Cotización excel" },
        { slug: "cotizacion-api", label: "Cotización desde API" },
        { slug: "tarifarios", label: "Tarifario" },
        { slug: "contratos", label: "Contratos" },
    ],
    tickets: [
        { slug: "crear-tickets", label: "Crear tickets" },
        { slug: "responder-tickets", label: "Responder tickets" },
        { slug: "cerrar-tickets", label: "Cerrar tickets" },
    ],
    viajes: [
        { slug: "crear-ordenes", label: "Crear órdenes" },
        { slug: "torre-control", label: "Torre de control" },
        { slug: "visualizar-ordenes", label: "Visualizar órdenes" },
        { slug: "rutas-asignadas", label: "Rutas asignadas" },
        { slug: "reporte-pedidos", label: "Reporte de pedidos" },
        { slug: "reporte-conductores", label: "Reporte de conductores" },
        { slug: "otif-proveedores", label: "Informe de OTIF" },
        { slug: "crear-notificationes-viajes", label: "Crear notificaciones" },
    ],
    planificador: [
        { slug: "generar-planificacion-entregas", label: "Generar planificación de entregas" },
        { slug: "generar-planificacion-visitas", label: "Generar planificación de visitas" },
        { slug: "confirmar-planificacion-visitas", label: "Confirmar planificación de visitas" },
        { slug: "confirmar-planificacion-entregas", label: "Confirmar planificación de entregas" },
    ],
    facturacion: [
        { slug: "informes-facturacion", label: "Informes de facturación" },
        { slug: "facturacion-pendiente", label: "Facturación pendiente" },
        { slug: "metodos-pago", label: "Gestionar métodos de pago" },
        { slug: "gestionar-costos", label: "Gestionar costos" },
    ],
    clientes: [
        { slug: "crear-clientes", label: "Crear clientes" },
        { slug: "modificar-clientes", label: "Modificar clientes" },
        { slug: "eliminar-clientes", label: "Eliminar clientes" },
        { slug: "crear-contacto", label: "Crear contacto" },
        { slug: "modificar-contacto", label: "Modificar contacto" },
        { slug: "eliminar-contacto", label: "Eliminar contacto" },
        { slug: "crear-bodega", label: "Crear bodegas" },
        { slug: "modificar-bodega", label: "Modificar bodegas" },
        { slug: "eliminar-bodega", label: "Eliminar bodegas" },
    ],
    integraciones: [
        { slug: "crear-llave-api", label: "Crear llaves de API" },
        { slug: "crear-webhooks", label: "Crear webhooks" },
        { slug: "modificar-webhooks", label: "Modificar webhooks" },
        { slug: "crear-tracking-embedido", label: "Tracking embedido" },
        { slug: "gestionar-conexion-sin-api", label: "Gestionar conexión sin API" },
        { slug: "gestionar-plugin-conexion", label: "Configurar plugins de conexión" },
    ],

}

class EmpresasEditar extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            id: this.props.params.id,
            usuario: false,
            loadingUsuario: false,
            alerta: false,
            loadingUsuarioTarifas: false,
            comunas_descartar_creacion: [],
            show_password: false,
            guardandoCambios: false,
            cambiandoPassword: false,
            password: '',
            password_confirm: '',
            loadingPermisos: true,
            permisos: [],
            permisos_otorgados: [],
            recoleccion: {},
            tarifas: [],
            modeloNegocio: {},
            posicion: 0,
            dias: []
        }
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleChangeTarifa = this.handleChangeTarifa.bind(this)
        this.guardarCambiosTarifa = this.guardarCambiosTarifa.bind(this)
        this.solicitarEliminar = this.solicitarEliminar.bind(this)
        this.handleChangeNombre = this.handleChangeNombre.bind(this)
        this.handleChangeDiasParaRecoleccion = this.handleChangeDiasParaRecoleccion.bind(this)
        this.handleChangeMinimoRecoleccion = this.handleChangeMinimoRecoleccion.bind(this)
        this.agregarTarifaCrear = this.agregarTarifaCrear.bind(this)
        this.handleChangeHoraDesde = this.handleChangeHoraDesde.bind(this)
        this.handleChangeHoraHasta = this.handleChangeHoraHasta.bind(this)
        this.handleChangeRecoleccion = this.handleChangeRecoleccion.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
        this.handleChangePassword = this.handleChangePassword.bind(this)
        this.handleChangeUsuarioInterfaz = this.handleChangeUsuarioInterfaz.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.handleChangeModelo = this.handleChangeModelo.bind(this)
        this.handleChangeModeloCantidad = this.handleChangeModeloCantidad.bind(this)

    }

    handleChangePassword(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleCheck(e){
        const { permisos_otorgados } = this.state
        const { name } = e.target
        const permiso = e.target.getAttribute('permiso')
        // check if permissions are granted
        const i = permisos_otorgados.findIndex(per => per.slug === permiso)
        if(i > -1){

            if(e.target.checked === true){
                if(!permisos_otorgados[i].actions.includes(name)) permisos_otorgados[i].actions.push(name)
            } else {
                const pos = permisos_otorgados[i].actions.findIndex(action => action === name)
                if(pos > -1) permisos_otorgados[i].actions.splice(pos,1)
            }

        } else {
            permisos_otorgados.push({
                slug: permiso,
                actions: [ name ]
            })
        }

        return this.setState({ permisos_otorgados })
    }

    handleChangeUsuario(e){
        const { usuario } = this.state
        const { name, value } = e.target
        usuario[name] = value
        console.log("usuario", usuario)
        return this.setState({ usuario })
    }

    handleChangeUsuarioInterfaz(e){
        const { usuario } = this.state
        const { name, value } = e.target
        if(!usuario.interface_config) usuario.interface_config = {}
        usuario.interface_config[name] = value
        return this.setState({ usuario })
    }
    
    handleChangeRecoleccion(e){
        const { recoleccion } = this.state
        const { name, value } = e.target
        recoleccion[name] = value
        console.log("recoleccion", recoleccion)
        return this.setState({ recoleccion })
    }

    handleChangeTarifa(e){
        const { tarifas } = this.state
        const { name, value } = e.target
        // tarifas[name] = value
        const obj = {name: value}
        tarifas.push(obj) 
        console.log("tarifa", tarifas)
        return this.setState( tarifas )
    }

    guardarCambiosTarifa(){
        const { tarifas } = this.state
        const { user } = this.state
     
        let loading = 'loadingUsuario'
        let alerta = 'alerta'
        const { usuario } = this.state
        let arr = {
            id: usuario._id,
            tarifas
        }
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas/recoleccion`,{
            method:'PUT',
            body: JSON.stringify( arr ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${this.props.miusuario.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(res._id){ 
                toast.success('Guardado exitosamente')
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            this.setState({ [alerta]: false})
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }


    guardarCambiosNegocio(){
        const { modeloNegocio } = this.state
        const { user } = this.state
        const { usuario } = this.state
     
        let loading = 'loadingUsuario'
        let alerta = 'alerta'
   
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas/modelo/negocio`,{
            method:'PUT',
            body: JSON.stringify( {...modeloNegocio, propietario: usuario.rut} ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${this.props.miusuario.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(res._id){ 
                toast.success('Guardado exitosamente')
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            this.setState({ [alerta]: false})
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    
    handleChangeModelo(e){
        const { modeloNegocio } = this.state
        const { name, value } = e.target
        modeloNegocio[name] = value
        console.log("modeloNegocio", modeloNegocio)
        return this.setState( modeloNegocio )
    }

    handleChangeModeloCantidad(e){
        const { modeloNegocio } = this.state
        const { name, value } = e.target
        let valor = 0
        if(name === "maximo_conductores") valor = parseInt(value)
        if(name === "maximo_ruteos") valor = parseInt(value)
        if(name === "maximo_ordenes") valor = parseInt(value)
        modeloNegocio[name] = valor
        console.log("modeloNegocio", modeloNegocio)
        return this.setState( modeloNegocio )
    }


    handleChangeNombre(e){
        let alerta = 'alerta'
        this.setState({ [alerta]: true})
        const { tarifas } = this.state
        const { name, value } = e.target
        const id =  name
        const i = tarifas.findIndex(campo => campo.id == id)
        if(i < 0) return false
        tarifas[i].nombre = value
        console.log("tarifas", tarifas)
        return this.setState( tarifas )
    }
    handleChangeTarifa(e){
         let alerta = 'alerta'
        this.setState({ [alerta]: true})
        const { tarifas } = this.state
        const { name, value } = e.target
        const id =  name
        const i = tarifas.findIndex(campo => campo.id == id)
        if(i < 0) return false
        tarifas[i].tarifa = value
        console.log("tarifas", tarifas)
        return this.setState( tarifas )
    }

    handleChangeDiasParaRecoleccion(e){
        let alerta = 'alerta'
        this.setState({ [alerta]: true})
        const { tarifas } = this.state
        const { name, value } = e.target
        const id =  name
        const i = tarifas.findIndex(campo => campo.id == id)
        if(i < 0) return false
        tarifas[i].dias_para_recoleccion = value
        console.log("tarifas", tarifas)
        return this.setState( tarifas )
    }

    handleChangeMinimoRecoleccion(e){
        let alerta = 'alerta'
        this.setState({ [alerta]: true})
        const { tarifas } = this.state
        const { name, value } = e.target
        const id =  name
        const i = tarifas.findIndex(campo => campo.id == id)
        if(i < 0) return false
        tarifas[i].minimo_de_recoleccion = value
        console.log("tarifas", tarifas)
        return this.setState( tarifas )
    }

    handleChangeHoraDesde(e){    
        let alerta = 'alerta'
        this.setState({ [alerta]: true})   
        const { tarifas } = this.state
        const { name, value, id } = e.target    
        console.log("target", e.target)     
        const i = tarifas.findIndex(campo => campo.id == name)
        const j = tarifas[i].dias_de_recoleccion.findIndex(campo => campo.dia == id )       
        console.log("i", i)
        console.log("j fn", j)
        if(i < 0) return false
        tarifas[i].dias_de_recoleccion[j].hora_desde = value
        console.log("tarifas", tarifas)
        return this.setState( tarifas )
    }

    handleChangeHoraHasta(e){    
        let alerta = 'alerta'
        this.setState({ [alerta]: true})   
        const { tarifas } = this.state
        const { name, value, id } = e.target   
        console.log("target", e.target)      
        const i = tarifas.findIndex(campo => campo.id == name)
        const j = tarifas[i].dias_de_recoleccion.findIndex(campo => campo.dia == id )       
        console.log("i", i)
        console.log("j fn", j)
        if(i < 0) return false
        tarifas[i].dias_de_recoleccion[j].hora_hasta = value
        console.log("tarifas", tarifas)
        return this.setState( tarifas )
    }

    agregarTarifaCrear(){
        let alerta = 'alerta'
        let loading = 'loadingUsuarioTarifas'
        this.setState({ [alerta]: true})
        this.setState({ [loading]: true })
        const { tarifas } = this.state
        let { posicion } = this.state
        const arr = tarifas
        const numero = posicion + 1
        const tarifa = {
            id: numero,
            nombre: "",
            tarifa: 0,
            dias_para_recoleccion: 0,
            minimo_de_recoleccion: 0,
            dias_de_recoleccion: [{
            id: numero,
            dia: "Lunes",
            hora_desde: "",
            hora_hasta: "",
        }, {
            id: numero,
            dia: "Martes",
            hora_desde: "",
            hora_hasta: "",
        }, {
            id: numero,
            dia: "Miercoles",
            hora_desde: "",
            hora_hasta: "",
        }, {
            id: numero,
            dia: "Jueves",
            hora_desde: "",
            hora_hasta: "",
        }, {
            id: numero,
            dia: "Viernes",
            hora_desde: "",
            hora_hasta: "",
        }, {
            id: numero,
            dia: "Sabado",
            hora_desde: "",
            hora_hasta: "",
        }, {
            id: numero,
            dia: "Domingo",
            hora_desde: "",
            hora_hasta: "",
        }]           
        } 
        arr.push(tarifa) 
        console.log("tarifas", tarifas)  
        posicion = numero  
        setTimeout(() => {
            this.setState({ [loading]: false })            
        }, 190);          
        return this.setState({tarifas, posicion})
       }   
       
    solicitarEliminar(i){
        let alerta = 'alerta'
        let loading = 'loadingUsuario'
        this.setState({ [alerta]: true})
        this.setState({ [loading]: true })
        let {tarifas} = this.state   
        console.log("i", i)
        tarifas.splice(i,1)
        setTimeout(() => {
            this.setState({ [loading]: false })            
        }, 220);
        console.log("tarifas_actuales", tarifas)

        return this.setState({tarifas})
    }     


    handleChangeChecked(checked){
        const { usuario } = this.state
        usuario.activo = checked
        return this.setState({ usuario })
    }

    componentDidMount(){
        const { id } = this.state
        if(!id) return this.setState({ loadingUsuario: true })
        this.getUsuario(id)

        this.getEsquemaPermisos()

    }

    async getEsquemaPermisos(){
        const { user } = this.state
        let loading = 'loadingPermisos'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/permisos/modelo`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            return this.setState({ permisos: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }
    async getUsuario(id){
        const { user } = this.state
        let loading = 'loadingUsuario'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas/details?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log("res", res)
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res.permisos){
                if(Array.isArray(res.permisos) !== false){
                    this.setState({ permisos_otorgados: res.permisos.map(p => ({ slug: p.module, actions: p.actions })) })
                }
            }
            if(!res.interface_config) res.interface_config = { mode: "cliente" }
            if(res.tarifas){
                console.log("respuesta", res.tarifas)
                this.setState({tarifas: res.tarifas})
                this.setState({posicion: res.tarifas.length + 1})
            }
            this.setState({ usuario: res, [loading]: false })
            return         this.getModeloNegocio(res)
            
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    async getModeloNegocio(){
        const { user } = this.state
        const { usuario } = this.state
        let loading = 'loadingUsuario'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas/modelo/negocio`,{
            method:'POST',
            headers: {
                'body':  JSON.stringify( {
                    propietario: usuario.rut
                } ),
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log("res", res)
            if(!res){
                // toast.info('Esta empresa no tiene modelo de negocios')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            if(res){
                if(typeof ref === 'object'){
                    this.setState({ modeloNegocio: res })
                }
            }
            return this.setState({ modeloNegocio: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    cambiarPassword(){
        const { usuario, user, password, password_confirm } = this.state
        if(!password_confirm || !password) return toast.error('Contraseña es requerida')
        if(password.length < 5) return toast.error('La contraseña debe tener al menos 5 carácteres')
        if(password !== password_confirm) return toast.error('Las contraseñas no coinciden')
        const loading = 'cambiandoPassword'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/reset-password`,{
            method:'PUT',
            body: JSON.stringify( {
                id: usuario._id,
                password, 
                password_confirm
            } ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${this.props.miusuario.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(res._id){ 
                toast.success('Guardado exitosamente')
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    guardarCambios(){
        const { usuario, user, permisos_otorgados } = this.state
        const requeridos = [
            { value:'razon_social', label: 'Razón social' },
            { value:'rut', label: 'Rut' }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        const loading = 'guardandoCambios'
        usuario.permisos = permisos_otorgados
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas`,{
            method:'PUT',
            body: JSON.stringify( usuario ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${this.props.miusuario.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(res._id){ 
                toast.success('Guardado exitosamente')
            } else {
                toast.error('Ocurrió un error inesperado')
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    permisos(){
        const { loadingPermisos, permisos_otorgados, permisos } = this.state
        if(loadingPermisos===true) return <Col xs={12} className="mt-3">
           <Spinner animation='border' />
           <h4>Cargando permisos</h4>
        </Col>
        return <Col xs={12}>
        <hr />
        <i className="fa-solid fa-sliders"></i>
        <h3>Permisos</h3>
        <Row>
        {
            permisos.map(permiso => {
                return <Col key={permiso._id} md={12} className="mb-4">
                    <Card className='p-3 mb-3'>
                    <h5><b>{permiso.titulo}</b></h5>
                    <Row>
                    {
                        acciones_especiales[permiso.slug] ? acciones_especiales[permiso.slug].map((accion,i) => {
                            let checkeado = false
                            const pos = permisos_otorgados.findIndex(per => per.slug === permiso.slug)
                            if(pos > -1){
                                if(permisos_otorgados[pos].actions.includes(accion.slug)) checkeado = true
                            }
                            return <Col md={3} key={permiso.slug}>
                                <Form.Group key={accion.slug} className="mb-3" controlId={`${permiso._id}-${accion.slug}`}>
                                    <Form.Check type="switch" defaultChecked={checkeado} permiso={permiso.slug} name={accion.slug} label={accion.label} onChange={this.handleCheck} />
                                </Form.Group>
                            </Col>
                        }) : <Col md={12}><h4>Faltan las definiciones del módulo</h4></Col>
                    }
                    </Row>
                    </Card>
                    </Col>
            })
        }
        </Row>
        </Col>
    }

    mostrarUsuarios(){
        const { user, usuario } = this.state
        if(!usuario.rut) return <h5>Empresa sin rut válido</h5>
        return <div>            
            <Tabs defaultActiveKey="activos" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="activos" title="Activas">
                <UsuariosListado token={this.props.miusuario.tokenSession} condicion_default={{ status: 'active', propietario: usuario.rut }} />
            </Tab>
            <Tab eventKey="archivados" title="Archivados">
                <UsuariosListado trash={true} token={this.props.miusuario.tokenSession} condicion_default={{ status: 'trash', propietario: usuario.rut }} />
            </Tab>
            </Tabs>
        </div>
    }

    mostrarDias(i){
        const {tarifas} = this.state
        if(tarifas[i].dias_de_recoleccion.length > 0) return <div >
            <Row>
            {
            tarifas[i].dias_de_recoleccion.map((d,index) => {
                    return <Col md={4} key={d.dia} >
                        <Card className='p-3 mb-3 shadow'>
                        <h4>{d.dia}</h4>
                        <Row>
                             <Col md={6} >
                                <label className='form-control-label d-block'>Hora desde</label>
                                <input className="form-control" name={d.id} defaultValue={d.hora_desde} id={d.dia} onChange={this.handleChangeHoraDesde}  type='Time'   />
                             </Col>    
                             <Col md={6} >
                                <label className='form-control-label d-block'>Hora hasta</label>
                                <input className="form-control" name={d.id}  id={d.dia} onChange={this.handleChangeHoraHasta} type='Time'   defaultValue={d.hora_hasta} />
                             </Col>  
                        </Row>   
                        </Card>         
                </Col>
                } )                
            }    
            </Row>
        </div>
    }


    mostrarProductosMantenedor(){      
            return <div>
                 <Row>
                <Col md={12} className='mb-3'>
                 {this.mostrarTarifas()}


     </Col>
     </Row>
     </div>
    }

    mostrarAlerta(){
        const { alerta } = this.state
        if(alerta) return <h6 style={{fontSize: 14}} className='text-danger hover mb-2'><i className="fa-solid fa-triangle-exclamation"></i> ! No has guardado cambios !</h6>
        return <h6 style={{fontSize: 14}} className='text-success'><i className="fa-solid fa-circle-check"></i> Estás actualizado!</h6>
    }

    mostrarTarifas(){
        const { loadingUsuarioTarifas } = this.state
        if(loadingUsuarioTarifas) return <Spinner animation='border' />
        const {tarifas} = this.state         
        if(tarifas.length > 0) return <div>
        <Row>
            <Col md={12} className='mb-3'>
                  {            
                    tarifas.map((tarifa,i) => {
                       
                        return <div>
                         <Card className='p-3 mb-3' key={`producto-${i}`}>
                            {/* <h4>{tarifa.nombre}</h4> */}
                            <Row >
                            <Col xs={12}className='mb-3'><p onClick={() => this.solicitarEliminar(i)} style={{ fontSize: 13, fontWeight: "bold", textTransform: "uppercase" }} className='text-danger hover mb-0'>Remover</p></Col>                          
                            <Col md={3} className='mb-3'>
                                <label className='form-control-label d-block'>Nombre</label>
                                <input className="form-control" name={tarifa.id} onChange={this.handleChangeNombre}   defaultValue={tarifa.nombre} />
                             </Col>
                            <Col md={3} className='mb-3'>
                                <label className='form-control-label d-block'>Tarifa</label>
                                <input className="form-control" name={tarifa.id} type='Number' onChange={this.handleChangeTarifa}   defaultValue={tarifa.tarifa} />
                             </Col>
                            <Col md={3} className='mb-3'>
                                <label className='form-control-label d-block'>Dias para recoleccion</label>
                                <input className="form-control" name={tarifa.id} type='Number' onChange={this.handleChangeDiasParaRecoleccion}  defaultValue={tarifa.dias_para_recoleccion} />
                             </Col>
                            <Col md={3} className='mb-3'>
                                <label className='form-control-label d-block'>Minimo de recoleccion</label>
                                <input className="form-control" name={tarifa.id} type='Number' onChange={this.handleChangeMinimoRecoleccion}   defaultValue={tarifa.minimo_de_recoleccion} />
                             </Col>
                            <Col md={12} className='mb-3'>
                                <label className='form-control-label d-block'>Dias de recoleccion</label> 
                                {this.mostrarDias(i)}
                             </Col>
                        </Row>
                        </Card>
                        </div>
                    })
                }
               
                </Col>
            </Row>
    </div>
}

mostrarModeloNegocio(){
    const { loadingUsuarioTarifas } = this.state
    const { usuario } = this.state
    if(loadingUsuarioTarifas) return <Spinner animation='border' />
    const {modeloNegocio} = this.state     
    return  <div>
    <Row>
        <Col md={12}>
            <Row>
            <Col md={4} className="mb-3">
                <Card className='p-3 mb-3 shadow'>
                <label className='form-control-label'>Cantidad maxima de conductores</label>
                <input name="maximo_conductores" type="number" defaultValue={modeloNegocio.maximo_conductores} className='form-control' onChange={this.handleChangeModeloCantidad} />
                <label className='form-control-label'>Frecuencia de cantidad maxima de conductores</label>
                <select name="frecuencia_maximo_conductores" defaultValue={modeloNegocio.frecuencia_maximo_conductores} className='form-control' onChange={this.handleChangeModelo}>
                     <option value="">Seleccione</option>
                    <option value="dia">Dia</option>
                    <option value="mes">Mes</option>
                    <option value="año">Año</option>
                </select>
                </Card>
            </Col>
            <Col md={4} className="mb-3">
                <Card className='p-3 mb-3 shadow'>
                <label className='form-control-label'>Cantidad maxima de ruteos realizados</label>
                <input name="maximo_ruteos" type="number" defaultValue={modeloNegocio.maximo_ruteos} className='form-control' onChange={this.handleChangeModeloCantidad} />
                <label className='form-control-label'>Frecuencia de cantidad maxima de ruteos realizados</label>
                <select name="frecuencia_maximo_ruteos" defaultValue={modeloNegocio.frecuencia_maximo_ruteos} className='form-control' onChange={this.handleChangeModelo}>
                     <option value="">Seleccione</option>
                    <option value="dia">Dia</option>
                    <option value="mes">Mes</option>
                    <option value="año">Año</option>
                </select>
                </Card>
            </Col>
            <Col md={4} className="mb-3">
                <Card className='p-3 mb-3 shadow'>
                <label className='form-control-label'>Cantidad maxima de ordenes creadas</label>
                <input name="maximo_ordenes" type="number" defaultValue={modeloNegocio.maximo_ordenes} className='form-control' onChange={this.handleChangeModeloCantidad} />
                <label className='form-control-label'>Frecuencia de cantidad maxima de ordenes creadas</label>
                <select name="frecuencia_maximo_ordenes" defaultValue={modeloNegocio.frecuencia_maximo_ordenes} className='form-control' onChange={this.handleChangeModelo}>
                     <option value="">Seleccione</option>
                    <option value="dia">Dia</option>
                    <option value="mes">Mes</option>
                    <option value="año">Año</option>
                </select>
                </Card>
            </Col>
           
            </Row>
        </Col>

    </Row>
    {/* <hr />
                {
                    guardandoCambios === true ? <Spinner animation='border' /> : <Button size="sm" className='mb-3' variant='success' onClick={()=>this.guardarCambios()}>GUARDAR CAMBIOS</Button>
                } */}
</div>
}


    formularioUsuario(){
        const { loadingUsuario, show_password, cambiandoPassword, guardandoCambios, usuario } = this.state
        if(loadingUsuario) return <Spinner animation='border' />
        if(!usuario) return <SinDatos />
        return <div> <Tabs id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="empresa" title="Empresa">
                <Row>
                <Col md={12}>
                <i className="icono fa-solid fa-gear"></i>
                <h3>Datos generales</h3>
                    <Row>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label' style={{fontWeight:700}}>Razón Social</label>
                        <input name="razon_social" defaultValue={usuario.razon_social} className='form-control' onChange={this.handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label' style={{fontWeight:700}}>Rut</label>
                        <h3>{usuario.rut}</h3>
                        {/* <input name="rut" defaultValue={usuario.rut} className='form-control' disabled /> */}
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label' style={{fontWeight:700}}>Teléfono</label>
                        <input name="telefono" defaultValue={usuario.telefono} className='form-control' onChange={this.handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label' style={{fontWeight:700}}>Web</label>
                        <input name="web" defaultValue={usuario.web} className='form-control' onChange={this.handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label' style={{fontWeight:700}}>Dirección</label>
                        <ReactGoogleAutocomplete
                            defaultValue={usuario.direccion}
                            className="form-control"
                            options={{
                                types: ["address"],
                                // cokmponentRestrictions: { country: pais },
                            }}                    
                            apiKey={maps_key}
                            onPlaceSelected={(place) => {
                                const { usuario } = this.state
                                if(!place) return false
                                const lat = place.geometry.location.lat()
                                const lng = place.geometry.location.lng()
                                let pais = ''
                                let codigo_pais = ''

                                let region = ''
                                const buscar_region = place.address_components.findIndex(comp => {
                                    return comp.types.includes('administrative_area_level_1')
                                })
                                if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                                let comuna = ''
                                const buscar_comuna = place.address_components.findIndex(comp => {
                                    return comp.types.includes('administrative_area_level_2')
                                })
                                if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                                const buscar_pais = place.address_components.findIndex(comp => {
                                    return comp.types.includes('country')
                                })
                                if(buscar_pais > -1) {
                                    pais = place.address_components[buscar_pais].long_name
                                    codigo_pais = place.address_components[buscar_pais].short_name
                                }

                                const geo_data = {
                                    lat, 
                                    lng,
                                    direccion: place.formatted_address,
                                    region,
                                    comuna,
                                    pais,
                                    codigo_pais
                                }
                                usuario.direccion = place.formatted_address
                                usuario.geodata = geo_data
                                usuario.maps_data = place
                                usuario.location = {
                                    type: "Point",
                                    coordinates: [
                                        geo_data.lng, geo_data.lat
                                    ]
                                }
                                
                                return this.setState({ usuario })
                            }}
                            />
                        {/* <input name="direccion" defaultValue={usuario.direccion} className='form-control' onChange={this.handleChangeUsuario} /> */}
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label' style={{fontWeight:700}}>Tax ID</label>
                        <input name="tax_id" defaultValue={usuario.tax_id} className='form-control' onChange={this.handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label' style={{fontWeight:700}}>Estado</label>
                        <select name="status" defaultValue={usuario.status} className='form-control' onChange={this.handleChangeUsuario}>
                            <option value="">Selecciona</option>
                            <option value="active">Activo</option>
                            <option value="trash">Desactivado</option>
                        </select>
                    </Col>
                    
                    <Col md={3} className="mb-3">
                        <SelectorPlanes titulo="Plan seleccionado" onChange={(data) => this.handleChangeUsuario({ target: { name: "id_plan", value: data?.value } })} />
                    </Col>
                    </Row>
                </Col>

            </Row>
            {this.permisos()}
            {
                    guardandoCambios === true ? <Spinner animation='border' /> : <Button size="sm" className='mb-3 shadow-sm' variant='success' onClick={()=>this.guardarCambios()}><b>GUARDAR CAMBIOS</b></Button>
                }
         </Tab>
                <Tab eventKey="usuarios" title="Usuarios">
                    <CrearUsuario rut_empresa={usuario.rut} />
                    <div className='mt-3'>{this.mostrarUsuarios()}</div>
                </Tab>
        </Tabs>
           

            {/* <hr /> */}
            {/* <Col md={12} className='row justify-content-end'>
            {}
                
            </Col> */}
             <hr /> 
             {this.mostrarAlerta()}
             <Tabs defaultActiveKey="modelo-negocios" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="modelo-negocios" title="Modelo de negocios">
                    <Row>
                        <Col md={12}>
                        <BsBriefcaseFill />
                        <h5 style={{fontWeight:700}}>Modelo de Negocios</h5>
                        </Col>
                    </Row>
                    {this.mostrarModeloNegocio()}
                    <Button size="sm" className='mb-3' variant='success' onClick={()=>this.guardarCambiosNegocio()}><b>GUARDAR CAMBIOS MODELO DE NEGOCIOS</b></Button> 
                </Tab>
                <Tab eventKey="recolecciones" title="Recolecciones">
                    <i className="icono fa-solid fa-gear"></i>
                    <h5 style={{fontWeight:700}}>Sistema de Recolecciones</h5>
                    {this.mostrarProductosMantenedor()}
                    <Button size="sm" className='mb-3' variant='success' onClick={()=>this.guardarCambiosTarifa()}><b>GUARDAR CAMBIOS TARIFA</b></Button> 

                </Tab>
                <Tab eventKey="modulos" title="Módulos">
                    <MantenedorEsquema rut_empresa={usuario.rut}/>
                </Tab>
                <Tab eventKey="consumo" title="Consumo">
                    <ConteosListado token={this.props.miusuario.tokenSession} propietario={usuario.rut} />
                </Tab>
                <Tab eventKey="pagos" title="Pagos">
                    <PagosListado token={this.props.miusuario.tokenSession} propietario={usuario.rut} />
                </Tab>
                <Tab eventKey="documentacion" title="Documentación">
                    <MediosBox id_destino={usuario?._id} />
                </Tab>
                
             </Tabs>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        return(
            <div className="fluid text-primary">
       {/* <Header/> */}
       <div className="container-fluid">
        <div className="row">
            <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pt-3 pb-2 mb-3 border-bottom">
            <Link to={`/empresas`} className='text-dark' ><i className="fas fa-chevron-left"></i> Volver</Link>
            </div>
            <h4 className="" style={{fontWeight:900}}>Editar empresa</h4>
            <Card >
                <Card.Body>
                {this.formularioUsuario()}
                </Card.Body>
            </Card>
            </main>
        </div>
        </div>
        </div>
    )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default withRouter(connect(mapStateToProps)(EmpresasEditar));