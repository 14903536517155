export const tipos_campos_personalizados = [
    { value: "ordenes", label: "Ordenes"},

]

export const modulo = 'formatos'

export const modulos_contenido = [
    {
        name: "column",
        title: "Columnas",
        options: [
            {
                name:"1",
                meta_value: 1
            },
            {
                name:"1/2",
                meta_value: 2
            }
        ]
    }
]

export const modulos_datos = {
    "ordenes": {
        fields: [
            {
                name: "Información de destino",
                slug: "destino",
                custom_component: "destino"
            },
            {
                name: "Información de origen",
                slug: "origen",
                custom_component: "origen"
            },
            {
                name: "Información del destinatario",
                slug: "destinatario",
                custom_component: "destinatario"
            },
            {
                name: "Información del remitente",
                slug: "remitente",
                custom_component: "remitente"
            },
            {
                name: "Campos personalizados",
                slug: "campos_personalizados",
                custom_component: "campos_personalizados"
            },
            {
                name: "Información del conductor",
                slug: "conductor",
                custom_component: "conductor"
            },
            {
                name: "Información de la notificación",
                slug: "notificaciones"
            },
            {
                name: "Fechas de compromiso",
                slug: "fechas",
                custom_component: "fechas"
            },
            {
                name: "Mapa",
                slug: "mapa"
            }
        ],
        segments: [
            {
                name:"productos",
                custom_component: "productos",
                config: [
                    {
                        type: "select",
                        options: [
                            { value: "", label: "Vista simple" }
                        ]
                    }
                ],
                extra_content: "",
                default_data: [
                    {
                        "description": "CAMPANA SLIM M60 INOX                   ",
                        "codigo_item": "CA0008091",
                        "peso": 20,
                        "alto": 30,
                        "ancho": 40,
                        "largo": 50,
                        "price": 14245,
                        "quantity": 5,
                        meta: [
                            {
                                key: "Campo especial",
                                value: "Valor Especial"
                            }
                        ]
                      }
                ]
            }
        ]
    }
}

export const components = [
    {
        name: "logotipo",
        title: "Logotipo",
        custom_component: "logotipo"
    },
    {
        name: "texto",
        title: "Texto",
        custom_component: "input"
    },
    {
        name: "fecha_creacion",
        title: "Fecha del documento"
    },
]