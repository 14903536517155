import Map, { Layer, Marker, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import Pin from '../../subComponents/mapas/pin';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import { BsFillStopCircleFill } from 'react-icons/bs';
import polyline from '@mapbox/polyline';

const colores = {
    0: "#0028ff",  // Gris Pizarra
    1: "#ff8300",  // Rosado Pálido
    2: "#b40b6a",  // Verde Oliva
    3: "#0ba4b4",  // Acero Azul
    4: "#b40b0b",  // Bronceado
    5: "#b4920b",  // Turquesa Oscuro
    6: "#50b40b",  // Verde Oscuro Oliva
    7: "#0b84b4",  // Verde Mar Oscuro
    8: "#5f0bb4",  // Lila Oscuro
    9: "#8B0000",  // Rojo Oscuro
    10: "#E9967A", // Salmón Oscuro
    11: "#483D8B", // Azul Pizarra Oscuro
    12: "#2F4F4F", // Verde Pizarra Oscuro
    13: "#00CED1", // Turquesa Oscuro
    14: "#9400D3", // Violeta Oscuro
    15: "#FF8C00", // Naranja Oscuro
    16: "#696969", // Gris Oscuro
    17: "#1E90FF", // Azul Dodger
    18: "#B22222", // Ladrillo Refractario
    19: "#FFD700", // Dorado
    20: "#ADFF2F", // Verde Amarillo
    21: "#F0E68C", // Caqui
    22: "#E6E6FA", // Lavanda
    23: "#FFF0F5", // Lavanda Rosada
    24: "#7B68EE", // Azul Medio Pizarra
    25: "#6A5ACD", // Azul Pizarra
    26: "#20B2AA", // Verde Mar Claro
    27: "#778899", // Gris Pizarra Claro
    28: "#B0C4DE", // Acero Azul Claro
    29: "#FFFFE0", // Amarillo Claro
    30: "#00FA9A", // Verde Primavera Medio
    31: "#48D1CC", // Turquesa Medio
    32: "#C71585", // Rojo Violeta Medio
    33: "#191970", // Azul Marino Medianoche
    34: "#F5FFFA", // Menta Crema
    35: "#FFE4E1", // Rosado Místico
    36: "#FFDEAD", // Blanquecino Navajo
    37: "#FFDAB9", // Melocotón
    38: "#CD853F", // Perú
    39: "#FFC0CB", // Rosado
    40: "#DDA0DD", // Ciruela
    41: "#B0E0E6", // Azul Polvo
    42: "#800080", // Púrpura
    43: "#663399", // Rebecca Púrpura
    44: "#BA55D3", // Orquídea Medio
    45: "#9370DB", // Púrpura Medio
    46: "#3CB371", // Verde Mar Medio
    47: "#7FFF00", // Verde Primavera
    48: "#DB7093", // Rosa Pálido
    49: "#FFB6C1"  // Rosado Claro
} 
const MapaRuteo = (props) => {
    const {
        height,
        resultados,
        hovered,
        markers,
        pinType,
        resize,
        point_hovered
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    const sesion                    = useSelector(state => state.miusuario)
    const pais                      = useSelector(state => state.pais)
    const defaultViewState          = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef                    = useRef()
    let geometrias                  = []
    let puntos                      = []

    if(resultados) if(resultados.routes) if(Array.isArray(resultados.routes)) if(resultados.routes.length > 0) {
        resultados.routes.forEach((route, iroute) => {

            let paradas = []
            let geometries = []

            if(route.geometrias){
                for( const geometry of route.geometrias){
                    geometries.push({ vehicle: route.vehicle, geometry })
                }
            }
            
            if(route.stops) if(Array.isArray(route.stops)) if(route.stops.length > 0){
                route.stops.forEach(stop => {
                    if(stop.location_metadata) if(typeof stop.location_metadata === "object") if(stop.location_metadata.supplied_coordinate){
                        paradas.push({
                            lng: stop.location_metadata.supplied_coordinate[0],
                            lat: stop.location_metadata.supplied_coordinate[1]
                        })
                    }
                })
            }

            puntos.push({
                vehicle: route.vehicle,
                paradas,
                geometries
            })

        })
    }

    const centrarMapa = (centro, max_zoom) => {
        console.log({ centro, max_zoom })
        const nuevo_zoom = defaultViewState.zoom + (max_zoom ? max_zoom : 3)
        mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
        setTimeout(() => {
          setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
        }, 2010);
      }

    const ajustarCentro = (markers) => {
        console.log({ markers })
        if(markers.length < 1) return false
        if(markers.length === 1){
            return centrarMapa({ latitude: markers[0].latitude, longitude: markers[0].longitude  }, 2)
          }
        setZoomInit(true)
        const points = markers.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current.fitBounds(bounds, options);
    }

    const centrarComportamientoMapa = () => {
        if(puntos.length > 0){
            let points_to_fit = []
                for( const stop of puntos ){
                    points_to_fit = [...points_to_fit, ...stop.paradas]
            }
            return ajustarCentro(points_to_fit.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
        } else {
            ajustarCentro(marcadores.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
        }
    }

    const obtenerCoordenadas = (cord) => {
        if(typeof cord === "object"){
            if(typeof cord.lat !== "undefined" && typeof cord.lng !== "undefined") return [ cord.lng, cord.lat ]
            if(typeof cord.lattitude !== "undefined" && typeof cord.longitude !== "undefined") return [ cord.longitude, cord.latitude ]
            return false
        }
        if(Array.isArray(cord)) return [ cord[0], cord[1] ]
        return false
    }

    const centrarMapaCoordenadas = (coordinates) => {
        const filtradas = coordinates.map(e => obtenerCoordenadas(e)).filter(o => o)
        const points = filtradas.map(marker => turf.point([marker[0], marker[1]]));
        const collection = turf.featureCollection(points);
        const bounds = turf.bbox(collection);
        
        const newViewport = {
            ...viewState,
            latitude: (bounds[1] + bounds[3]) / 2,
            longitude: (bounds[0] + bounds[2]) / 2
        };
        
        console.log({newViewport})
        const options = {
          padding: 30 // Ajusta el valor de padding según tus necesidades
        };

        mapRef.current?.fitBounds(bounds, options);
    }

      useEffect(() => {
            setMarcadores([...[], ...markers])
            if(mapRef) if(mapRef.current) mapRef.current.resize()
            centrarMapaCoordenadas(markers)
      }, [ resize, markers ])


    const showPin = () => {
        const tipo = typeof pinType !== "undefined" ? pinType : false
        switch (tipo) {
            case "uber":
                return <BsFillStopCircleFill size={15} />
            default:
                return <Pin />
        }
    }

    const seleccionarPunto = (id) => {
        if(props.onSelect) props.onSelect(id)
        setMarcadores(prev => {
            let actual = [...prev]
            actual = actual.filter(marker => marker._id !== id)
            return [...[], ...actual]
        })
    }

    return <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: height ? height : 600}}
    // pitch={25}
    // scrollZoom={false}
    // dragPan={false}
    mapStyle="mapbox://styles/mapbox/light-v9"
    // mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
    {
       puntos.length > 0 ? false : marcadores.map(marker => {
            return <Marker
                    key={marker.id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                <div onClick={() => seleccionarPunto(marker.id)} >
                    {showPin()}
                </div>
            </Marker>
        })
    }
    {
        puntos.map((marker,imarker) => {
            
            if(hovered) if(marker.vehicle !== hovered) return false
            return marker.paradas.map((mark,im) => {
                if(point_hovered){
                    if(marker.vehicle !== point_hovered.id_vehicle) return false
                    if(im !== point_hovered.i_point) return false
                }
                return <Marker
                    key={`${marker.vehicle}-mark-${im}`}
                    longitude={mark.lng}
                    latitude={mark.lat}
                >
                <div className='mt-0 mb-0' style={{ borderRadius: "50%", margin:0, padding: 5, backgroundColor: "white", fontWeight: "bold", border: `1px solid ${colores[imarker]}` }}><h3 style={{ margin: 0, fontSize: 13, color: colores[imarker], }} level={5} >{(im+1)}</h3></div>
            </Marker>
            })
        })
    }

{
                puntos.map((marker,imarker) => {

                    return marker.geometries.map((geometry,ir) => {

                        if(point_hovered) return false

                        const decodedCoords = polyline.decode(geometry.geometry)

                        if(hovered) if(marker.vehicle !== hovered) return false
                        return <Source
                          id={`route-${imarker}-${ir}`}
                          type='geojson'
                          data={{
                              type: 'Feature',
                              properties: {},
                              geometry: {
                                  type: "LineString",
                                  coordinates: decodedCoords.map(co => [co[1], co[0]])
                              }
                            }}
                      >
                          <Layer
                          id={`route-layer-${imarker}-${ir}`}
                          type="line"
                          layout={{
                              'line-join': 'round',
                              'line-cap': 'round',
                            }}                
                          paint={{
                              'line-color': colores[imarker],
                              'line-width': 4,
                              'line-opacity': 0.75
                          }}
                      />
                      </Source>
                    })
                })
              }
  </Map>
}

export default MapaRuteo