import { Badge, Button, ButtonGroup, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { useState } from "react"
import { opciones_origenes, optiones_estricto_tiempo } from "./data"
import { useSelector } from "react-redux"
import Select from 'react-select'
import { formatoMoneda } from "../../lib/helpers/main"
import { MdDeleteOutline } from "react-icons/md"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/helper_main"
import { BsFillStopCircleFill } from "react-icons/bs"
import { IoIosHelpCircle, IoIosResize } from "react-icons/io"
import { TbWeight } from "react-icons/tb"
import { FaEdit, FaRegClock } from "react-icons/fa"

const DetalleVehiculo = (props) => {
    const {
        data
    } = props
    const [ show, setShow ] = useState(false)
    const [ edit, setEdit ] = useState(false)
    const punto = data
    const pais = useSelector(state => state.pais)
    const requeridos = []
    const showModal = () => {
        setShow(true);
    };

    const handleOk = () => {
        setShow(false);
    };

    const handleCancel = () => {
        setShow(false);
    };

    const minutos = punto.duration / 60

    const handleChangeSelect = (val, key) => {
        let actual = {...punto}
            actual[key] = val
            if(props.onChange) props.onChange(actual)
    }

    const handleChangeSelectSpecial = (val, key) => {
        let actual = {...punto}
        actual[key] = val
        if(props.onChange) props.onChange(actual)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        let actual = {...punto}
        actual[name] = value
        if(props.onChange) props.onChange(actual)
    }
    
    const handleCheckDescanso = (e) => {
        let actual = {...punto}
        actual.descanso = e
        if(props.onChange) props.onChange(actual)
    }

    const mostrarCapacidad = () => {

        const boton = <Col md={12} xs={12}><h3>Medidas y peso <Button size="sm" variant="light" onClick={() => setEdit(!edit)}><FaEdit /> EDITAR</Button></h3> </Col>
        const snippet = <Badge variant="dark">CM</Badge>
        const snippet_peso = <Badge variant="dark">KG</Badge>

        if(edit){
            return <Row>
                {boton}
            <Col md={4} xs={12}>
                <label className="form-control-label d-block">Ancho {snippet}</label>
                <input className="mb-3 form-control" variant="filled" type="number" name="ancho" value={punto.ancho} onChange={handleChange} />
            </Col>
            <Col md={4} xs={12}>
                <label className="form-control-label d-block">Alto {snippet}</label>
                <input className="mb-3 form-control" variant="filled" type="number" name="alto" value={punto.alto} onChange={handleChange} />
            </Col>
            <Col md={4} xs={12}>
                <label className="form-control-label d-block">Largo {snippet}</label>
                <input className="mb-3 form-control" variant="filled" type="number" name="largo" value={punto.largo} onChange={handleChange} />
            </Col>
            <Col md={12} xs={12}>
                <label className="form-control-label d-block">Capacidad de peso {snippet_peso}</label>
                <input className="mb-3 form-control" type="number" name="peso" value={punto.peso} onChange={handleChange} />
            </Col>
            </Row>
        }


        return <Row>
            {boton}
        <Col md={4} xs={12} className="mb-3">
            <p className="mb-0"><IoIosResize /> Ancho</p>
            <h3 level={3} className="mb-0 mt-0">{punto.ancho ? punto.ancho : 0} {snippet}</h3>
        </Col>
        <Col md={4} xs={12} className="mb-3">
            <p className="mb-0"><IoIosResize /> Alto</p>
            <h3 level={3} className="mb-0 mt-0">{punto.alto ? punto.alto : 0} {snippet}</h3>
        </Col>
        <Col md={4} xs={12} className="mb-3">
            <p className="mb-0"><IoIosResize /> Largo</p>
            <h3 level={3} className="mb-0 mt-0">{punto.largo ? punto.largo : 0} {snippet}</h3>
        </Col>
        <Col md={12} xs={12} className="mb-3">
            <p className="mb-0"><TbWeight /> Capacidad de peso </p>
            <h3 level={3} className="mb-0 mt-0">{formatoMoneda(punto.peso ? punto.peso : 0)} {snippet_peso}</h3>
        </Col>
        </Row>
    }

    const isError = (val) => punto.descanso === true && ! val
    const errorMsg = "Ingresa un valor"

    const componente_descanso = <div>
        <Form layout="vertical">
        <Row gutter={15}>
                    <Col span={24}>
                            <h3 className="mt-0" level={5}>Horario de descanso</h3>
                        <Row gutter={15}>
                            <Col span={8}>
                                <label className="form-control-label d-block">Desde</label>
                                <input type="time" name="break_desde" defaultValue={punto.break_desde} onChange={handleChange} />
                            </Col>
                            <Col span={8}>
                                <label className="form-control-label d-block">Hasta</label>
                                <input type="time" name="break_hasta" defaultValue={punto.break_hasta} onChange={handleChange} />
                            </Col>
                            <Col span={8}>
                                <label className="form-control-label d-block">Duración en minutos</label>
                                <input type="number" name="break_duration" defaultValue={punto.break_duration} onChange={handleChange} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
        </Form>
    </div>

    return <div>
        <Modal show={show} onOk={handleOk}  onHide={handleCancel}  >
            <Modal.Header closeButton><h4 className="mb-0">Configuración del vehículo</h4></Modal.Header>
            <Modal.Body>
            <h3 level={4} className="mt-0">{punto.titulo}</h3>
            <Form layout="vertical">
                <Row >
                    <Col span={12}>
                            <h4 className="mt-0" level={5}><FaRegClock style={{ verticalAlign: "middle" }} /> Horario de trabajo permitido <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={'vehiclesss'}>Este es el horario en que este vehículo podrá realizar entregas, no se incluirán en el ruteo entregas fuera de este horario</Tooltip>
                            }>
                                <IoIosHelpCircle size={20} />
                            </OverlayTrigger></h4>
                        <Row >
                            <Col md={6} className="mb-3" >
                                <label className="form-control-label d-block">Desde</label>
                                <input type="time" className="form-control" name="horario_desde" defaultValue={punto.horario_desde} onChange={handleChange} />
                            </Col>
                            <Col md={6} className="mb-3" >
                                <label className="form-control-label d-block">Hasta</label>
                                <input type="time" className="form-control" name="horario_hasta" defaultValue={punto.horario_hasta} onChange={handleChange} />
                            </Col>
                            <Col md={12} className="mb-3" >
                                <label className="form-control-label d-block">Punto de llegada <OverlayTrigger
                                    placement="auto"
                                    overlay={
                                        <Tooltip id={'right'}>Este es el último punto al que deberá volver este vehículo al finalizar la ruta</Tooltip>
                                    }>
                                        <IoIosHelpCircle size={20} />
                                    </OverlayTrigger></label>
                                <Select placeholder="Seleccionar" value={punto.end_location ? punto.end_location : opciones_origenes[0]} options={opciones_origenes} onChange={(val) => handleChangeSelect(val,'end_location')} />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* <Switch className="mb-3" checkedChildren="DESCANSO ACTIVADO" unCheckedChildren="TOCA PARA PERSONALIZAR HORARIO DE DESCANSO" onChange={handleCheckDescanso} defaultChecked={punto.descanso===true} /> */}

                { punto.descanso === true ? componente_descanso : false }
                
                <Row gutter={15}>
                    {/* <Col md={12}>
                        <label className="form-control-label d-block">Punto de partida" >
                            <Select value={punto.start_location ? punto.start_location : "warehouse"} options={opciones_origenes} onChange={(val) => handleChangeSelect(val,'start_location')} />
                        </Form.Item>
                    </Col> */}
                    <Col md={24} className="mb-3">
                    {/* <SelectorGeneral mode="multiple" module="categorias" value={punto.capabilities ? punto.capabilities.map(e => e._id) : []} titulo="Capacidades" condicion_default={{ tipo: "capacidades" }} onChange={(val) => handleChangeSelectSpecial(val, "capabilities")} /> */}
                    </Col>
                    <Col xs={12} className="mb-3">
                        <label className="form-control-label d-block">Cantidad máxima de bultos <OverlayTrigger
                            key={'right'}
                            overlay={
                                <Tooltip id={'right'}>Válido solo cuando activas la opción de gestión de bultos en configuración</Tooltip>
                            }>
                                <IoIosHelpCircle size={20} />
                            </OverlayTrigger>
                            </label>
                        <input mb={3} className="form-control" type="number" name="bultos" value={punto.bultos} onChange={handleChange} />
                    </Col>
                </Row>
                {mostrarCapacidad()}
            </Form>
            </Modal.Body>
      </Modal>
        <div className="p-2">
            <span style={{ fontSize: 11 }}>Vehículo</span>
            <h6 className="mb-2 hover mb-0" style={{ fontWeight: "bold" }} >{punto.titulo}</h6>
            { punto.capabilities ? punto.capabilities.map(item => <Badge style={{ marginTop: 10 }}>{item.title}</Badge> ) : false }
            <ButtonGroup>
                <Button size="sm" variant="light" onClick={() => showModal()}>CONFIGURAR</Button>
                <Button size="sm" variant="light" className="text-danger" onClick={() => props.onDelete(punto)} icon={<MdDeleteOutline />} >REMOVER</Button>
            </ButtonGroup>
        </div>
    </div>
}

export default DetalleVehiculo