import React, { useState} from 'react'
import { useSelector } from 'react-redux'
import { Card, Nav, Tab } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import ApiKeysListado from '../../subComponents/integraciones/apikeys/apikeys_listado'
import Webhooks from '../../subComponents/integraciones/webhooks/webhooks'
import WidgetsListado from '../../subComponents/integraciones/widgets/widgets_listado'
import PluginsListado from '../../subComponents/integraciones/plugins/plugins_listado'
import ApiLess from '../../subComponents/integraciones/apiless'
import FuncionesListado from '../../subComponents/integraciones/funciones/listado'
import CourriersListado from '../../subComponents/integraciones/courriers/listado'
import { useQuery } from '../../lib/helpers/router'
import HelperIntegraciones from '../../subComponents/general/helperIntegraciones'
import { default_proveedores } from '../../subComponents/proveedores_transporte/data'
import { gps_integrados, url_images } from '../../lib/global/data'

const sistemas = [
  { logo: `${url_images}/integraciones/mandrill-logo.png` },
  { logo: `${url_images}/integraciones/twilio.png` },
  { logo: `${url_images}/integraciones/whatsapp.png` },
  { logo: `${url_images}/integraciones/odoo.png` },
  { logo: `${url_images}/integraciones/sap.png` },
  { logo: `${url_images}/integraciones/bsale.png` },
  { logo: `${url_images}/integraciones/skualo.png` },
  { logo: `${url_images}/integraciones/hubspot.png` },
  { logo: `${url_images}/integraciones/laudus.png` },
  { logo: `${url_images}/integraciones/defontana.png` },
  { logo: `${url_images}/integraciones/docuware.png` },
]

const marketplaces = [
  {
    logo: `${url_images}/woocommerce.png`
  },
  {
    logo: `${url_images}/marketplaces/magento.png`
  },
  {
    logo: `${url_images}/marketplaces/jumpseller.png`
  },
  {
    logo: `${url_images}/marketplaces/shopify.png`
  },
  {
    logo: `${url_images}/marketplaces/prestashop.png`
  }
]

const Integraciones = (props) => {  

    const user = useSelector(state => state.miusuario)
    const location = useLocation()
    const [ selectedTab, setSelectedTab ] = useState('')
    const navigate = useNavigate();
    const query = useQuery()
    let default_key = 'apikeys'
    if(query.get("tab")) default_key = query.get("tab")

    const handleChangeTab = (e) => {
      const newTab = e;
      const queryParams = new URLSearchParams();
      queryParams.set('tab', newTab);
      navigate({
        pathname: location.pathname,
        search: `?${queryParams.toString()}`,
      });
      return setSelectedTab(newTab);
    }

    const mostrarOperadoresLogisticos = () => {
      return <Card className='p-3 mb-3'>
        <h5>Proveedores logísticos</h5>
        <hr className='mt-1 mb-3' />
          {/* {default_proveedores.map((proveedor, index) => <div style={{ height: 30, width: 130, backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></div>)} */}
          <Row>{default_proveedores.map((proveedor, index) => <Col className='mb-3' md={2} style={{ height: 30, width: "40%", backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></Col>)}</Row>
      </Card>
    }
    
    const mostrarGPS = () => {
      return <Card className='p-3 mb-3'>
        <h5>GPS Integrados</h5>
        <hr className='mt-1 mb-3' />
          {/* {default_proveedores.map((proveedor, index) => <div style={{ height: 30, width: 130, backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></div>)} */}
          <Row>{gps_integrados.map((proveedor, index) => <Col className='mb-3' md={2} style={{ height: 30, width: "40%", backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></Col>)}</Row>
      </Card>
    }

    const mostrarVarios = () => {
      return <Card className='p-3 mb-3'>
        <h5>Otros sistemas</h5>
        <hr className='mt-1 mb-3' />
          {/* {default_proveedores.map((proveedor, index) => <div style={{ height: 30, width: 130, backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></div>)} */}
          <Row>{sistemas.map((proveedor, index) => <Col className='mb-3' md={2} style={{ height: 30, width: "40%", backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></Col>)}</Row>
      </Card>
    }
    
    const mostrarMarketplaces = () => {
      return <Card className='p-3 mb-3'>
        <h5>Ecommerce CMS</h5>
        <hr className='mt-1 mb-3' />
          {/* {default_proveedores.map((proveedor, index) => <div style={{ height: 30, width: 130, backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></div>)} */}
          <Row>{marketplaces.map((proveedor, index) => <Col className='mb-3' md={2} style={{ height: 30, width: "40%", backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></Col>)}</Row>
      </Card>
    }

      return <div className="fluid">
        <h1 className="h2">Integraciones</h1> 

        {mostrarOperadoresLogisticos()}
        {mostrarMarketplaces()}
        {mostrarGPS()}
        {mostrarVarios()}

        <Tab.Container id="left-tabs-example" defaultActiveKey={default_key} >
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column" onSelect={handleChangeTab}>
            <Nav.Item><Nav.Link eventKey="apikeys">Llaves de API</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="webhooks">Webhooks</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="widgets">Tracking embedido</Nav.Link></Nav.Item>
            {/* <Nav.Item><Nav.Link eventKey="functions">Funciones</Nav.Link></Nav.Item> */}
            <Nav.Item><Nav.Link eventKey="apiless">Conexión sin API</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="plugins">Plugins ecommerce</Nav.Link></Nav.Item>
            {/* <Nav.Item><Nav.Link eventKey="courriers">Courriers</Nav.Link></Nav.Item> */}
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
          <Tab.Pane eventKey="apikeys"  >
                <HelperIntegraciones tipo="apikeys" style="modal" />
                <ApiKeysListado trash={true} token={user.tokenSession} condicion_default={{ status: 'trash' }} />
            </Tab.Pane>
            <Tab.Pane eventKey="webhooks">
                <HelperIntegraciones tipo="webhooks" style="modal" />
                <Webhooks token={user.tokenSession} />
            </Tab.Pane>
            <Tab.Pane eventKey="apiless">
                <HelperIntegraciones tipo="apiless" style="modal" />
                <ApiLess token={user.tokenSession} />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="functions">
                <HelperIntegraciones tipo="functions" style="modal" />
                <FuncionesListado token={user.tokenSession} />
            </Tab.Pane> */}
            <Tab.Pane eventKey="widgets">
                <HelperIntegraciones tipo="widgets" style="modal" />
                <WidgetsListado token={user.tokenSession} />
            </Tab.Pane>
            <Tab.Pane eventKey="plugins">
                <HelperIntegraciones tipo="plugins" style="modal" />
                <PluginsListado token={user.tokenSession} />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="courriers">
                <HelperIntegraciones tipo="courriers" style="modal" />
                <CourriersListado />
            </Tab.Pane> */}
          </Tab.Content>
        </Col>
        </Row>
      </Tab.Container>
    </div>

}

export default Integraciones