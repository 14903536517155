import React, { useEffect, useRef, useState} from 'react'
import { mapbox_token, urlapi } from '../../lib/backend/data';
import socket from '../../lib/websockets';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { cerrarSesion } from '../../redux/actions/session';
import { fechaATexto } from '../../lib/helpers/helpers';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import { Map, Marker } from 'react-map-gl';
import * as turf from '@turf/turf';
import { Alert, Badge, Button, ButtonGroup, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsCarFrontFill, BsFillCursorFill, BsFillStopCircleFill } from 'react-icons/bs'
import { RxAvatar, RxOpenInNewWindow } from 'react-icons/rx'
import { rutas } from '../../lib/routes/routes';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import Skeleton from 'react-loading-skeleton';
import OrdenesListadoV2 from '../ordenes/listadov2';
import { AiOutlineClose } from 'react-icons/ai';
import ListadoEstadosCarga from '../estados_carga/list';
import ListadoAlertas from '../../components/GeoAlertas/listado';
import GraficoVehiculo from '../actividad/flota/graficos_vehiculo';
import IdentificadorConductor from '../actividad/flota/identificador_vehiculo';
import LoadingFlota from '../general/loadingAnimations/loadingFlota';
import { gps_integrados, url_images } from '../../lib/global/data';
import { estilo_last_mile } from '../../lib/global/styles';
import FuentesDato from './fuentes_datos';
import { RiRadioLine } from 'react-icons/ri';
import { FaCheckCircle } from 'react-icons/fa';
import { IoPhonePortraitOutline } from 'react-icons/io5';
import { HiIdentification } from 'react-icons/hi';
import { FaCircleInfo } from 'react-icons/fa6';

mapboxgl.accessToken = mapbox_token

const ModalCourriers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return <div style={{ display: "inline" }}>
    <b className='hover' onClick={() => setIsModalOpen(true)}><FaCircleInfo /></b>
        <Modal  className="mb-0 p-3" show={isModalOpen}size="lg" onHide={()=>setIsModalOpen(false)} centered >
          <Modal.Header closeButton ><h4>GPS Integrados</h4></Modal.Header>
        <Modal.Body>
          <p className='mb-3'>Los siguientes GPS están integrados en esta plataforma, nuestro equipo puede integrar cualquier GPS la central de monitoreo para que puedas monitorear eficientemente</p>
          <Row>{gps_integrados.map((proveedor, index) => <Col className='mb-3' md={2} style={{ height: 50, width: "40%", backgroundImage: `url(${proveedor.logo})`, backgroundSize: "contain", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} key={index} ></Col>)}</Row>
        </Modal.Body>
      </Modal>
  </div>
}

const MapaMonitoreoFlota = () => {
    const pais = useSelector(state => state.pais)
    const [marcadores, setMarcadores] = useState([])
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [loadingRefrescar, setLoadingRefrescar] = useState(false)
    const [filtroBusqueda, setFiltroBusqueda] = useState("")
    const [intervalo, setIntervalo] = useState(false)
    const sesion = useSelector(state => state.miusuario)
    const idioma = useSelector(state => state.idioma)
    const [coordenadasRecibidas, setCoordenadasRecibidas] = useState(0)
    const [ condicionBusqueda, setCondicionBusqueda ] = useState({})
    const [ arreglo_conductores, setArregloConductores ] = useState()
    const [ condicionAlertas, setCondicionAlertas ] = useState({})
    const [ condicionGrafico, setCondicionGrafico ] = useState({})
    const [ fuente, setFuente ] = useState('')
    const [ conductorSeleccionadox, setConductorSeleccinoado ] = useState(false)
    const conductorSeleccionado = useRef(false)
    const dispatch = useDispatch()
    const coordRef = useRef();
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()
    coordRef.current = coordenadasRecibidas
    const controls = []

    const adaptarTamanoMapa = () => {
      if(mapRef) if(mapRef.current) mapRef.current.resize()
    }

    const ajustarCentro = (markers) => {
    if(markers.length < 1) return false
    const points = markers.map(marker => turf.point([marker.lng, marker.lat]));
    const collection = turf.featureCollection(points);
    const bounds = turf.bbox(collection);

    const newViewport = {
      ...viewState,
      latitude: (bounds[1] + bounds[3]) / 2,
      longitude: (bounds[0] + bounds[2]) / 2
    };

    const options = {
      padding: 50 // Ajusta el valor de padding según tus necesidades
    };

    console.log(newViewport)
    setViewState(newViewport);
    mapRef.current.fitBounds(bounds, options);
  }

  const handleGeocoderResult = (event) => {
    if (event.result && event.result.geometry && event.result.geometry.coordinates) {
      const [lng, lat] = event.result.geometry.coordinates;
      setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
    }
  };

  const geocodificador = () => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false, // Desactivar el marcador de ubicación seleccionada
      placeholder: "Busca una dirección",
      countries: pais
    });
    geocoder.on('result', handleGeocoderResult);
    setTimeout(() => {
      if(controls.length < 1) mapRef.current?.addControl(geocoder, "top-right")
      controls.push('geocoder')
    }, 200);
  }

  const handleLoadMap = () => {
    geocodificador()
  }

    useEffect(() => {
      refrescarListado(null, true)
      const id_intervalo = setInterval(() => {
        refrescarListado()
      }, 4000);
      setIntervalo(id_intervalo)
      const identificador_1 = 'coordenadas_mapa'
      const identificador_2 = 'conexion_conductor'

      socket.on(identificador_1, data => {
        const nuevo = coordRef.current + 1
        setCoordenadasRecibidas(nuevo)
        let nuevos = marcadores
        const i = nuevos.findIndex(m => m.idusuario === data.idusuario)
        if(i > -1){
          nuevos[i] = data
        } else {
          nuevos.unshift(data)
        }
        return setMarcadores(prev => [...[], ...nuevos])
      })
        socket.on(identificador_2, datos => {
            refrescarListado()
        })
      return () => {
        clearInterval(id_intervalo)
        socket?.off(identificador_1)
        socket?.off(identificador_2)
      }
  },[ ])

  const refrescarListado = async (cond, centrar, canal) => {
    
    const query = cond ? cond : condicionBusqueda
    return fetch(`${urlapi}/conductores/vehicles-actives`,{
        method:'POST',
        body: JSON.stringify({
          condicion: query,
          canal: typeof canal === undefined ? fuente : canal
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            return false
        } else if(res.errorMessage){
            return false
        } else if(Array.isArray(res) !== false){

          let ids_usuarios = []
          for( const marker of marcadores ){
            const { idusuario } = marker
            if(!idusuario) continue
            const i = res.findIndex(m => m._id === marker.idusuario)
            if(i < 0){
              ids_usuarios.push(marker.idusuario)
            }
          }
          // console.log({ids_usuarios})
          for( const id of ids_usuarios ){
            const pos = marcadores.findIndex(m => m.idusuario === id)
            if(pos > -1) marcadores.splice(pos,1)
          }

          setMarcadores(prev => [...[], ...res])
          console.log({conductorSeleccionado: conductorSeleccionado?.current})
          if(centrar === true){
            setTimeout(() => {
              ajustarCentro(res)
            }, 300);
          } else {
            if(conductorSeleccionado?.current){
              const posi = res.findIndex(co => co.idusuario === conductorSeleccionado?.current)
              if(posi > -1) centrarMapa({ latitude: res[posi].lat, longitude: res[posi].lng })
            }
          }
        }
        setLoadingMaster(false)
        return setLoadingRefrescar(false)
    })
    .catch(error => {
        return setLoadingRefrescar(false)
    })
}

const handleChangeFiltroProveedor = (e) => {
    const { value } = e.target
    return setFiltroBusqueda(value)
}

const centrarMapa = (centro, max_zoom) => {
  const nuevo_zoom = defaultViewState.zoom + (max_zoom ? max_zoom : 3)
  console.log({centro, max_zoom})
  mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
}

const seleccionarConductor = (mark) => {
    clearInterval(intervalo)
    centrarMapa({ latitude: mark.lat, longitude: mark.lng })
    setArregloConductores([...[], ...[mark.idusuario]])
    setCondicionAlertas({...{}, ...{ id_target: mark.idusuario }})
    setCondicionGrafico({...{}, ...{ idconductor: mark.idusuario }})
    // setConductorSeleccinoado(mark.idusuario)
    conductorSeleccionado.current = mark.idusuario
    setTimeout(() => {
      const id_intervalo = setInterval(() => {
        refrescarListado( null, null, fuente)
      }, 4000);
      setIntervalo(id_intervalo)
    }, 100);
    setTimeout(() => {
      adaptarTamanoMapa()
    }, 200);
}

const ultimaConexion = (val) => {
  return <p className='mb-1' style={{ fontSize:10 }}><b style={{ fontWeight: "bold" }}>Última ubicación</b> <Moment locale="ES" fromNow>{val}</Moment></p>
  return <OverlayTrigger
  placement={'top'}
  overlay={
    <Tooltip >Última vez que el dispositivo emitió su ubicación</Tooltip>
  }
  ><p className='mb-0' style={{ fontSize:10 }}><b style={{ fontWeight: "bold" }}>Última ubicación</b> <Moment locale="ES" fromNow>{val}</Moment></p></OverlayTrigger>  
}

const mostrarFotoPerfil = (val) => {
  if(!val) return <div style={{ backgroundColor: "white", borderRadius: "50%", width: 30, height: 30, margin: "0 auto" }}><RxAvatar size={30} /></div>

  return <div style={{ width: 30, height: 30, borderRadius: "50%", backgroundPosition: "center center", backgroundImage: `url(${val})`, backgroundSize: 'cover' }}></div>
}

  const visualizarListado = () => {
    
    if(loadingMaster) return <LoadingFlota />


        const filtrar = filtroBusqueda.toLowerCase();
        let datos_filtrados = marcadores.filter(item => {
            return Object.keys(item).some(key => {
            if(!item[key]) return false
            if(key === 'orden'){
                if(!item[key].billing) return false
                return Object.keys(item[key].billing).some(llave => {
                    if(!item[key].billing[llave]) return false
                    return item[key].billing[llave].toString().toLowerCase().includes(filtrar)
                })
            }
            return item[key].toString().toLowerCase().includes(filtrar)
            })
        })

       
        
    return <div>
      { loadingRefrescar ? <Spinner /> : false }
      <h6 className='mb-0'><b>{marcadores.length} Conductores</b></h6>
      <p style={{ fontSize: 11 }}>Se muestran conductores de tu flota con su última ubicación conocida</p>
      <input className='form-control mb-3' placeholder='BUSCAR VEHÍCULO' onChange={handleChangeFiltroProveedor} />
      {/* { marcadores.length > 0 ? <Button disabled={!conductorSeleccionado.current} size='sm' className='w-100 mb-3' variant='light' onClick={() => {
        conductorSeleccionado.current = false
        ajustarCentro(marcadores)
      }}>VER TODOS EN MAPA</Button> : false } */}
      
      <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
      {
        datos_filtrados.length > 0 ? datos_filtrados.map((mark,i) => {
          return <div className='pt-2 pr-2 pl-2 hover' style={{ background: conductorSeleccionado?.current === mark.idusuario ? "#e2e7ea" : "white" }} key={`driver-${mark.idusuario}`} onClick={() => seleccionarConductor(mark)}>
            {ultimaConexion(mark.fecha)}
            <Row>
              <Col xs={2}>
              <img src={`${url_images}/truck-icon.png`} style={{ height: 30 }} />
              </Col>
              <Col xs={10}>
                <h5 className='mb-0' style={{ textTransform: "uppercase", fontSize: 12 }}>{mark.identificador_conductor} <a target='_blank' href={`/${rutas.vehiculos.slug}/edit/${mark._id}`}><RxOpenInNewWindow /></a> </h5>
                <h6 className='mb-0' style={{ textTransform: "uppercase", fontSize: 12  }} >PATENTE: <b>{mark.patente}</b></h6>
              </Col>
            </Row>
            { (i+1) === datos_filtrados.length ? false : <hr className='mt-3 mb-0' /> }
            {/* <Button size="sm" disabled={ conductorSeleccionado?.current === mark.idusuario ? true : false } className='mt-2' variant='light' style={{ fontSize: 12 }} >SELECCIONAR</Button> */}
          </div>
        }) : <h5>No hay conductores para mostrar</h5>
      }
      </div>
    </div>
  }

  const pantallaVacia = () => {
    return <div className='text-center p-4'>
      <h6 className='mb-0' style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>Aquí verás toda la información relacionada con la operación del conductor</h6>
      <p className='mb-0'><BsFillCursorFill /> Selecciona un conductor para visualizar los detalles</p>
      <img src={`${url_images}/animations/modules/Truck delivery service.gif`} style={{ width: 250, marginTop: -60 }} />
    </div>
  }

  const mostrarInformacionConductor = () => {
    if(!conductorSeleccionado?.current) return pantallaVacia()

    return <div style={{ height: "100%", overflowX: "scroll"}}>
      <div className=''>
      <Tabs defaultActiveKey="ordenes" >
        <Tab eventKey="ordenes" title="Órdenes" className='pt-2'>
            <OrdenesListadoV2 hideTitle={true} typeView="simple" hideHeader={true} showAddButton={false} showSearch={false} refresh={conductorSeleccionado?.current} condicion_default={{ idconductor: conductorSeleccionado?.current }} />
        </Tab>
        <Tab eventKey="estados" title="Estados de carga" className='pt-2'>
          <ListadoEstadosCarga hideTitle={true} size="sm" hideMetaData={true} hideCreator={true} showLink={true} conductores={arreglo_conductores} />
        </Tab>
        <Tab eventKey="alertas" title="Geo Alertas" className='pt-2'>
          <ListadoAlertas condicion_default={condicionAlertas} />
        </Tab>
      </Tabs>
      </div>
    </div>
  }

  const refrescarCiclo = (data) => {
    clearInterval(intervalo)
    setFuente(data)
    conductorSeleccionado.current = false
    ajustarCentro(marcadores)
    const id_intervalo = setInterval(() => {
      refrescarListado(null, null, data)
    }, 4000);
    setIntervalo(id_intervalo)
  }

  const marcadorSimple = (marker) => {
    return <div className='text-center text-uppercase'>
      {/* <BsFillStopCircleFill size={15} style={{ marginBottom: 0 }} /> */}
      <img src={`${url_images}/truck-icon.png`} style={{ height: 30 }} />
      <div><Badge variant="dark">{marker.patente}</Badge></div>
    </div>
  }

  const visualizarIconoMapa = (marker) => {
    if(conductorSeleccionado?.current){
      if(marker.idusuario !== conductorSeleccionado?.current) return false
      return <div className='text-center'>
        <img src={`${url_images}/truck-icon.png`} style={{ height: 30 }} />
        <div><Badge variant="dark">{marker.patente}</Badge></div>
      </div>
      const size= 13
      return <Card className='p-2'>
        <Row>
          <Col xs={2}>
            
          </Col>
          <Col xs={10}>
            {ultimaConexion(marker.fecha)}
            <h5 className='mb-0' style={{ textTransform: "uppercase", fontSize: 12 }}>{marker.identificador_conductor} <a target='_blank' href={`/${rutas.vehiculos.slug}/edit/${marker._id}`}><RxOpenInNewWindow /></a> </h5>
            <h6 className='mb-0' style={{ fontSize: size }}><b>VEHÍCULO</b> {marker.modelo} {marker.ano}</h6>
            <h6 className='mb-0' style={{ fontSize: size }}><b>MÓVIL</b> {marker.phone}</h6>

              <Button size="sm" style={{ padding: "1px 5px", fontSize: 10 }} onClick={() => {
              // setConductorSeleccinoado(false)
              ajustarCentro(marcadores)
              }}><AiOutlineClose  size={12} /> CERRAR</Button>
          </Col>
        </Row>
      </Card>
    }

    return marcadorSimple(marker)
  }

  const pantallaVaciaPanelInformativo = () => {
    return <div>
      <RxAvatar size={50} color='gray' className='mb-2' />
      <h6 className='mb-0' style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>Aquí verás la información del conductor</h6>
      <hr />
      <p><BsFillCursorFill /> Selecciona un conductor para visualizar los detalles</p>
    </div>
  }

  const panelInformativo = () => {
    if(!conductorSeleccionado?.current) return pantallaVaciaPanelInformativo()
    const pos = marcadores.findIndex(ma => ma.idusuario === conductorSeleccionado?.current)
    const mark = marcadores[pos]
    const concedido = <b><FaCheckCircle color={"green"} /> CONCEDIDO</b>
    return <div>
      <Button size="sm" style={{ padding: "1px 5px", fontSize: 10 }} onClick={() => {
              // setConductorSeleccinoado(false)
              conductorSeleccionado.current = false
              ajustarCentro(marcadores)
              setTimeout(() => {
                adaptarTamanoMapa()
              }, 200);
              }}><AiOutlineClose  size={12} /> CERRAR</Button>
            <Card className='p-2 mt-2 mb-2' key={`driver-${mark.idusuario}`} >
            {ultimaConexion(mark.fecha)}
            <Row>
              <Col xs={2}>
                {mostrarFotoPerfil(mark.image_profile)}
              </Col>
              <Col xs={10}>
                <h5 className='mb-0' style={{ textTransform: "uppercase", fontSize: 12 }}>{mark.identificador_conductor} <a target='_blank' href={`/${rutas.vehiculos.slug}/edit/${mark._id}`}><RxOpenInNewWindow /></a> </h5>
                <h6 className='mb-0' style={{ textTransform: "uppercase", fontSize: 12  }} >PATENTE: <b>{mark.patente}</b></h6>
              </Col>
              <Col xs={12}>
              <hr className='mt-2 mb-2' />
              <h6 className='mb-1'><b>Datos del conductor</b></h6>
              <p className='mb-0' style={{ fontSize: 12 }} ><b style={{ fontWeight: "bold" }}><HiIdentification /> RUT</b> {mark.rut}</p>
              <p className='mb-0' style={{ fontSize: 12 }} ><b style={{ fontWeight: "bold" }}><IoPhonePortraitOutline /> TELÉFONO</b> {mark.phone}</p>
              <hr className='mt-2 mb-2' />
              <h6 className='mb-1'><b><IoPhonePortraitOutline /> Accesos del APP</b></h6>
              <p className='mb-0' style={{ fontSize: 12 }} ><b style={{ fontWeight: "bold" }}>UBICACIÓN PRIMER PLANO</b> {mark.foreground ? concedido : "NO CONCEDIDO"}</p>
              <p className='mb-0' style={{ fontSize: 12 }} ><b style={{ fontWeight: "bold" }}>UBICACIÓN SEGUNDO PLANO</b> {mark.background ? concedido : "NO CONCEDIDO"}</p>
              <hr className='mt-2 mb-2' />
              <Row>
                <Col xs={1}>
                <img src={`${url_images}/truck-icon.png`} style={{ height: 30 }} />
                </Col>
                <Col xs={10}>
                <p className='mb-0' style={{ fontSize: 12 }} ><b style={{ fontWeight: "bold" }}>MODELO</b> {mark.modelo}</p>
                <p className='mb-0' style={{ fontSize: 12 }} ><b style={{ fontWeight: "bold" }}>AÑO</b> {mark.ano ? mark.ano : "NO DISPONIBLE"}</p>
                <p className='mb-0' style={{ fontSize: 12, textTransform: "uppercase" }} ><b style={{ fontWeight: "bold" }}>COMBUSTIBLE</b> {mark.status}</p>
                </Col>
              </Row>
              </Col>
            </Row>
          </Card>
      <GraficoVehiculo tipoVistaOtif="barras_collapse" condicion_default={condicionGrafico} />
    </div>
  }

  return <Row >
  <Col md={2} xs={12} >
    <h3><b>Mi flota</b></h3>
    {visualizarListado()}
  </Col>
  <Col >
    <div style={{ height: "45vh" }}>
    <Row><Col md={4}><h5 className='mb-0'><b><RiRadioLine /> Fuente de datos {<ModalCourriers /> }</b></h5></Col><Col style={{ textAlign: "right" }}><FuentesDato onChange={(type) => refrescarCiclo(type)} /></Col></Row>
    <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: "100%"}}
    mapStyle="mapbox://styles/mapbox/light-v9" //"mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
    onLoad={handleLoadMap}
  >
    {
        marcadores.map(marker => {
            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                  <div>{visualizarIconoMapa(marker)}</div>
            </Marker>
        })
    }
  </Map>
    </div>
    <div style={{ height: "45vh", paddingTop: 60, paddingRight: 10, paddingLeft: 10, backgroundColor: "white" }}>
    {mostrarInformacionConductor()}
    </div>
  </Col>
  {
    !conductorSeleccionado?.current ? false : <Col md={3} style={{ backgroundColor: "white" }}>
      <div className='pt-3'>
        {panelInformativo()}
      </div>
    </Col>
  }
  
  </Row>
}

export default MapaMonitoreoFlota