import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Breadcrumb } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user, maps_key } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import Switch from "react-switch";
import { validateEmail } from '../../lib/helpers/helpers'
import ReactGoogleAutocomplete from 'react-google-autocomplete'

class EmpresasCrear extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            usuario: {
                activo: true
            },
            loadingUsuarios: false,
            creandoUsuario: false,
            comunas_descartar_creacion: [],
            show_password: false
        }
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
    }

    handleChangeUsuario(e){
        const { usuario } = this.state
        const { name, value } = e.target
        usuario[name] = value
        return this.setState({ usuario })
    }

    handleChangeChecked(checked){
        return this.setState({ show_password: checked })
    }

    crearUsuario(){
        const { usuario, user } = this.state
        const requeridos = [
            { value:'razon_social', label: 'Razón social' },
            { value:'rut', label: 'Rut' }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        const loading = 'creandoUsuario'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/empresas`,{
            method:'POST',
            body: JSON.stringify( usuario ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    return window.location = '/empresas'
                }, 500);
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    formularioUsuario(){
        const { loadingUsuarios, creandoUsuario, usuario } = this.state
        if(loadingUsuarios) return <Spinner animation='border' />

        return <div>
            <Row>
                <Col md={3} className="mb-3">
                    <label className='form-control-label' style={{fontWeight:700}}>Razón social</label>
                    <input name="razon_social" className='form-control' onChange={this.handleChangeUsuario} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label' style={{fontWeight:700}}>Rut</label>
                    <input name="rut" className='form-control' onChange={this.handleChangeUsuario} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label' style={{fontWeight:700}}>Dirección</label>
                    <ReactGoogleAutocomplete
                            defaultValue={usuario.direccion}
                            className="form-control"
                            options={{
                                types: ["address"],
                                // cokmponentRestrictions: { country: pais },
                            }}                    
                            apiKey={maps_key}
                            onPlaceSelected={(place) => {
                                const { usuario } = this.state
                                if(!place) return false
                                const lat = place.geometry.location.lat()
                                const lng = place.geometry.location.lng()
                                let pais = ''
                                let codigo_pais = ''

                                let region = ''
                                const buscar_region = place.address_components.findIndex(comp => {
                                    return comp.types.includes('administrative_area_level_1')
                                })
                                if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                                let comuna = ''
                                const buscar_comuna = place.address_components.findIndex(comp => {
                                    return comp.types.includes('administrative_area_level_2')
                                })
                                if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                                const buscar_pais = place.address_components.findIndex(comp => {
                                    return comp.types.includes('country')
                                })
                                if(buscar_pais > -1) {
                                    pais = place.address_components[buscar_pais].long_name
                                    codigo_pais = place.address_components[buscar_pais].short_name
                                }

                                const geo_data = {
                                    lat, 
                                    lng,
                                    direccion: place.formatted_address,
                                    region,
                                    comuna,
                                    pais,
                                    codigo_pais
                                }
                                usuario.direccion = place.formatted_address
                                usuario.geodata = geo_data
                                usuario.maps_data = place
                                usuario.location = {
                                    type: "Point",
                                    coordinates: [
                                        geo_data.lng, geo_data.lat
                                    ]
                                }
                                
                                return this.setState({ usuario })
                            }}
                            />
                </Col>
                <Col md={3} className="mb-3">
                    <label className='form-control-label' style={{fontWeight:700}}>Teléfono</label>
                    <input name="telefono" className='form-control' onChange={this.handleChangeUsuario} />
                </Col>
                <Col md={12} className='row justify-content-end'>
                    {
                        creandoUsuario === true ? <Spinner animation='border' /> : <Button size="sm" variant='success' className='w-25' onClick={()=>this.crearUsuario()}><b>CREAR</b></Button>
                    }
                    
                </Col>
            </Row>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }
    
    render(){
        return(
            <div className="fluid text-primary">
       {/* <Header/> */}
       <div className="container-fluid">
  <div className="row">
    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pb-2 mb-3">
      </div>
      <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/empresas">Empresas</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Crear</Breadcrumb.Item>
    </Breadcrumb>
    <h4 style={{fontWeight:900}}>Crear nueva empresa</h4>
      <Card >
          <Card.Body>
          {this.formularioUsuario()}
          </Card.Body>
      </Card>
    </main>
  </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EmpresasCrear);