import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import data from '../../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import { useDispatch, useSelector } from 'react-redux';
import VisualizarRangos from './visualizar_rangos';
import { AiOutlineDelete } from 'react-icons/ai';
import { cerrarSesion } from '../../../../redux/actions/session';

const RangosListadoCliente = (props) => {
    const {
        rut_cliente
    } = props
    const [ proveedores, setRegistros ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const dispatch = useDispatch()

    const obtenerRegistros = async (page, query)=>{
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/pricing/rangos-cliente/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            } else if(Array.isArray(res) !== false){
                setRegistros(res)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    useEffect(() => {
        obtenerRegistros(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/pricing/rangos?id=${id}&rut=${rut_cliente}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.deletedCount){
                toast.success("Eliminado")
                return setTimeout(() => {
                    window.location.reload()
                }, 1000);
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const tabla_registros = (registros) => {
        if(loadingProveedores===true) return cargandoScreen()

        const registros_validos = registros.filter(orden => {
            if(!orden.rangos) return false
            if(Array.isArray(orden.rangos) !== true) return false
            if(orden.rangos.length < 1) return false
            return true
        })

        if(registros_validos.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>

        return <div>
            {
                        registros_validos.map(orden => {

                            return <div key={orden._id} className="mb-3">
                                <Card className='p-3 mb-3'>
                                    <Row>
                                        <Col md={6}><h4 className='mb-3'>Servicio: <b>{orden.nombre}</b></h4></Col>
                                        {/* <Col className='text-right' md={6}><Button onClick={() => solicitarEliminar(orden._id)} disabled={removiendo} variant="link" className='text-danger' size='sm'><AiOutlineDelete size={18} /> ELIMINAR</Button></Col> */}
                                    </Row>
                                    <VisualizarRangos rangos={orden.rangos} /> 
                                </Card>
                            </div>
                        })
            }
        </div>
    }
    
    return <div>
        {modalFiltro()}
        <Row>
            <Col md={12}>
            <h4 style={{ fontSize: 23 }}>Rangos creados</h4>
                {tabla_registros(proveedores)}
            </Col>
        </Row>
    </div>

}

export default RangosListadoCliente