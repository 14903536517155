import React, { useEffect, useState } from 'react'
import { Accordion, Card, Col, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { urlapi } from '../../lib/backend/data'
import { configuracion_fechas, configuracion_horas } from '../../lib/helpers/dates'
import { useDispatch, useSelector } from 'react-redux'
import { cerrarSesion } from '../../redux/actions/session'
import { url_images } from '../../lib/global/data'
import MapaDetallePedidoTrackingPage from './mapa_tracking_page'
import { esNumero } from '../../lib/helpers/main';
import TablaProductos from '../../subComponents/ordenes/tabla_productos'
import { canalEstadosString } from '../../lib/helpers/pedidos/pedidos'
import { fechaATexto } from '../../lib/helpers/helpers'

const Tracking = (props) => {
  const {
    size
  } = props
  const [loadingPedido, setLoadingPedido] = useState(true)
  const [detalle_pedido, setDetallePedido] = useState(false)
  const [loadingEquipos, setLoadingEquipos] = useState(true)
  const [personalizacionTracking, setPersonalizacionTracking] = useState({})
  const [ marcadores, setMarcadores ] = useState([])
  const route = useParams()

  const obtenerPedido = async () => {
    const { id } = route
    return fetch(`${urlapi}/ordenes/tracking?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        if (!res) {
          toast.error('Sin datos')
          return setLoadingPedido(false)
        } else if (res.errorMessage) {
          toast.error(res.errorMessage)
          return setLoadingPedido(false)
        } else if (res.orden) {
          setDetallePedido(res)
          let puntos = []
          if(res.orden.location){
            if(Array.isArray(res.orden.location.coordinates)){
                if(res.orden.location.coordinates.length > 0){
                  puntos.push({
                    lat: res.orden.location.coordinates[1],
                    lng: res.orden.location.coordinates[0],
                    ref: res.orden.pedido
                  })
                  setMarcadores(puntos)
                }
            }
          }
          if(res.configuracion_interfaz) setPersonalizacionTracking(res.configuracion_interfaz)
        }
        return setLoadingPedido(false)
      })
      .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoadingPedido(false)
      })
  }

    

  useEffect(() => {
    obtenerPedido()
  }, [])

  const mostrarSubEstadoHTML = (sub_estado_entrega) => {
    if (sub_estado_entrega) {
      if (typeof sub_estado_entrega === 'object') {
        if (sub_estado_entrega.titulo) {
          return <span className="spanstatus mr-2" style={{ textTransform: "uppercase", backgroundColor: sub_estado_entrega.color ? sub_estado_entrega.color : '#a2a2a2', padding: "1px 10px", borderRadius: 5, color: "white" }}>{sub_estado_entrega.titulo}</span>
        }
      }
    }
    return false
  }

  const statusString = (status) => {

    let cadena = "";

    if (status) {
      if (status == 1) {
        cadena = "Preparado";
      } else if (status == 2) {
        cadena = "Leído en el móvil";
      } else if (status == 3) {
        cadena = "Retirado en origen";
      } else if (status == 4) {
        cadena = "En reparto";
      } else if (status == 5) {
        cadena = "Entregado";
      } else if (status == 6) {
        cadena = "Entrega Confirmada";
      } else if (status == 7) {
        cadena = "Entrega Confirmada";
      } else if (status == 9) {
        cadena = "Retiro de bodega";
      } else if (status == 18) {
        cadena = "En CD Delpa";
      } else if (status == 13) {
        cadena = "No entregado";
      } else {
        cadena = "Sin datos";
      }
    } else {
      cadena = "Sin datos";
    }
    return cadena;
  }

  const statusColor = (level) => {

    let color = "#dbdbde";

    if (level == 1) {
      color = "#cad004";
    } else if (level == 2) {
      color = "#d09d04";
    } else if (level == 3) {
      color = "#d03e04";
    } else if (level == 4) {
      color = "#5e72e4";
    } else if (level == 5) {
      color = "#64b612";
    } else if (level == 6) {
      color = "#64b612";
    } else if (level == 7) {
      color = "#64b612";
    } else if (level == 9) {
      color = "#ff71a6";
    } else if (level == 18) {
      color = "#a31be1";
    } else if (level == 13) {
      color = "#000";
    }

    return color;

  }

  const sinRegistrosHTML = (palabra, archivo) => {
    return <div>
      <img src={`/images/${archivo}`} style={{ width: 70 }} />
      <h3>No hay {palabra} registrados</h3>
    </div>
  }

  const sizeImage = () => {
    if(size==="sm") return 50
    return 100
  }

  const showComponentByTipoFormulario = (campo) => {
    const height = sizeImage()
    switch (campo.tipo_accion) {
      case 'campo-texto':
        if(!campo.valor) return false
        return <Row className="mt-2 mb-3 px-2">
          <Col>
            <Card className='d-block border-0'>
              <h6 className='mb-0 text-primary' style={{ fontWeight: 900 }}>{campo.titulo}</h6>
              <p className='mb-0 text-primary' >{campo.valor ? campo.valor : 'Sin información'}</p>
            </Card>
          </Col>
        </Row>
      case 'selector':
        if(!campo.valor) return false
        return <div className="mt-2 mb-3 px-2">
          <h6 className='mb-0 text-primary' style={{ fontWeight: 900 }}>{campo.titulo}</h6>
          <p className='mb-0 text-primary'>{campo.valor ? campo.valor : 'Sin información'}</p>
        </div>
      case 'carga-imagenes':
        if(campo.valores.length < 1) return false
        return <div className="mt-2 mb-3 px-2">
          <h6 className='mb-2' style={{ fontSize: 14 }}>{campo.titulo}</h6>
          {
              campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                if(campo.private === true) return false
                return <a href={foto.url} target="_blank" className='pr-2' key={`foto-estado-${i}`}>
                  <div style={{ width: 70, height: 70, marginRight: 5, boxShadow: "1px 4px 5px #878686", display:"inline-block", backgroundImage: `url(${foto.url})`, backgroundSize: "cover", borderRadius: 13, backgroundPosition: "center" }}></div>
                  </a>
              }) : <div className='col-md-12'><p>Sin información</p></div>
            }
        </div>
      case 'firma-digital':
        if(!campo.valor) return false
        const isNumero = esNumero(campo.valor)
        if(isNumero === true) return false

        return <div className="mt-2 mb-3 px-2 text-primary">
            <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
            <p className='mb-0'>
                {campo.valor 
                    ? <img src={campo.valor} alt="Firma digital" style={{maxWidth: '25%'}} /> 
                    : 'Sin información'
                }
            </p>                  
        </div>
      default:
        break;
    }
  }

  const mostrarMetaDatosSubEstado = (metadatos) => {
    if (!metadatos) return false
    if (Array.isArray(metadatos) !== true) return false
    if (metadatos.length < 1) return false

    return metadatos.map((meta, i) => {
      return <div key={`meta-${i}`}>
        {showComponentByTipoFormulario(meta)}
      </div>
    })
  }

  const mostrarEstados = (estados, typeView) => {
      if (!estados) return sinRegistrosHTML('estados', 'tracking-estados.png')
      if (Array.isArray(estados) !== true) return sinRegistrosHTML('estados', 'tracking-estados.png')
      if (estados.length < 1) return sinRegistrosHTML('estados', 'tracking-estados.png')


        return <div className=''>
        {typeView === 'accordion' ? (<>
          <Card className='p-2 shadow-sm'>
            <div className='d-flex mt-2 align-items-center justify-content-between'>
            <h5 className='text-primary' style={{ fontWeight: 900, fontSize: 20 }} ><i className="fa-solid fa-map-pin"></i> Estados de carga</h5>
            <h6 className='text-secondary' style={{fontSize:13}}><i className="fa-regular fa-hand-pointer"></i> Haz click en el Estado para ver mas detalles.</h6>
            </div>
            <Accordion defaultActiveKey={''} className="mb-2">
            {
                estados.map((estado,i) => {

                  let lat = 0
                  let lng = 0

                  if(estado.location){
                    if(Array.isArray(estado.location.coordinates) !== false){
                      if(estado.location.coordinates.length > 1){
                        lat = estado.location.coordinates[0]
                        lng = estado.location.coordinates[1]
                      }
                    }
                  }

                    return <Card className="p-0 mx-1 shadow-sm text-left">
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'rgba(27, 54, 101, 0.1)' }} eventKey={`estado-${i+1}`}>
                        <div className="pl-2 pb-0">
                        <span style={{ fontWeight: 700 }}>{fechaATexto(estado.fecha)} · <a href={`https://google.com/maps?q=${lat},${lng}`} target="_blank"><i className="fas text-primary fa-map-marker-alt"></i></a></span>
                        <p className="mb-0" style={{fontWeight:900}}><i className="fa-solid fa-circle" style={{ color: estado.estado_color }}></i> <b>{estado.estado}</b> <b>{estado.subestado}</b></p>
                        </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`estado-${i+1}`}>
                        <Card.Body className='text-primary' style={{fontWeight:700}}>
                          <p className="mb-0"><b>Canal:</b> {canalEstadosString(estado.canal)}</p>
                          <p className="mb-0"><b>Por :</b> {estado.identificador_creador}</p>
                            {
                                mostrarMetaDatosSubEstado(estado.metadatos)
                            }
                            {/* {
                              (i+1) === estados.length ? <div>
                                {
                                  eliminandoEstado ? <div className="loadingbox">
                                    <Spinner animation='border' />
                                  </div> : <button className='btn btn-sm mt-3 btn-outline-danger' onClick={() => solicitarEliminarEstado(estado._id)}>ELIMINAR ESTADO</button>
                                }
                                
                              </div> : false
                            } */}
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                })
            }
            </Accordion>
          </Card>
          </>) : (
              <div>
                <Card className='p-2 pb-3 shadow-sm'>
                <h5 className='mb-2 text-primary' style={{ fontWeight: 900, fontSize: 20 }} ><i className="fa-solid fa-map-pin"></i> Estados de carga</h5>
                  {estados.map((estado, index) => {
                      const fecha = new Date(estado.fecha);
                      return (
                          <div key={`estado-${index}`} className='border shadow p-2 bg-white'>
                              <div className='d-flex text-primary p-0 align-items-center py-3 pl-1 border bg-light'>
                                  <div>
                                      <h6 className='text-white bg-primary px-2 py-1 rounded-circle' style={{
                                        fontWeight: 700,
                                        padding: "3px 11px"
                                      }}>{index + 1}</h6>
                                    </div>
                                    <div className=''>
                                      <h6 className='mb-0 ml-2' style={{ textTransform: "uppercase", fontSize: 12 }} >{fecha.toLocaleDateString('es-ES', configuracion_fechas)} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b></h6>
                                      <span className="spanstatus mx-2" style={{ fontWeight:700, fontSize:12, textTransform: "uppercase", backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb', padding: "1px 10px", borderRadius: 5, color: "white" }}>{estado.estado}</span>
                                      {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color, })}
                                    </div>
                                </div>
                                {/* <hr className='hr' /> */}
                                {mostrarMetaDatosSubEstado(estado.metadatos)}                        
                          </div>
                      );
                  })}
          </Card>
              </div>
          )}
      </div>
    


    // return <div className='row'
    // style={{ backgroundColor: '#eee', paddingTop: 15, marginBottom: 20 }}
    // >
    //   {
    //     estados.map((estado, im) => {
    //       const fecha = new Date(estado.fecha)
    //       return <Card className='shadow-sm mx-2 mt-2 w-100' key={`estado-${im}`}>
    //         <div className='p-3 text-primary'>
    //           <h4 className='bubleestado text-white shadow'>{im + 1}</h4>
    //           <h6 className='mb-2' style={{ textTransform: "uppercase", fontSize: 13 }} >{fecha.toLocaleDateString('es-ES', configuracion_fechas)} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b></h6>
    //           <span className="spanstatus mr-2" style={{ textTransform: "uppercase", backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb', padding: "1px 10px", borderRadius: 5, color: "white" }}>{estado.estado}</span>
    //           {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color, })}
    //           <hr className='hr' />
    //           {mostrarMetaDatosSubEstado(estado.metadatos)}
    //         </div>
    //       </Card>
    //     })
    //   }
    // </div>
  }


  const shoyEstadoEntregaHTML = (estadoentrega, estado_entrega, sub_estado_entrega) => {
    if (estado_entrega) {
      if (typeof estado_entrega === 'object') {
        if (estado_entrega.titulo) {
          return <div>
            <span className="spanstatus mr-2 mt-0" style={{ backgroundColor: estado_entrega.color ? estado_entrega.color : '#babbbb', padding: "1px 10px", textTransform: "uppercase", borderRadius: 5, color: "white" }}>{estado_entrega.titulo}</span>
            {mostrarSubEstadoHTML(sub_estado_entrega)}
          </div>
        }
      }
    }
    return <span className="spanstatus" style={{ backgroundColor: statusColor(estadoentrega) }}>{statusString(estadoentrega)}</span>
  }


  const Header = () => {
    return <div className='mb-3'>
      <img src={`${url_images}/logoColor.svg`} style={{ width: 200 }} />
    </div>
  }

  const mostrarDatosPersonalizados = () => {
    if (loadingPedido) return <div className="loadingbox text-center mb-3">
      <Header />
      <Spinner animation='border' />
      <h4 className='my-2' style={{fontWeight:900}}>Cargando información...</h4>
    </div>

    if (!detalle_pedido) return <div className="loadingbox text-center">
      <Header />
      <h2 className='mb-2' style={{fontWeight:900}}>Sin información</h2>
    </div>

    const orden = typeof detalle_pedido.orden.orden === 'object' ? detalle_pedido.orden.orden : {}
    const conductor = typeof detalle_pedido.orden.conductor === 'object' ? detalle_pedido.orden.conductor : {}
    const facturacion = typeof orden.billing === 'object' ? orden.billing : {}

    return <div>

      <h3 className='text-primary mb-3' style={{ fontWeight: 900 }}><i className="fa-solid fa-location-dot text-primary"></i> Tracking</h3>

      <Row className=''>
        <Col md={12} className=''>
          {mostrarReferencia(orden, conductor)}
          {mostrarMasInfo(orden, facturacion)}
        {/* <hr />    */}
        {mostrarMapa()} 
        {mostrarEstadosCarga()} 
        </Col>
      </Row>
    </div >
  }

    const mostrarMapa = () => {
        let show_module = getConfigFromModuleSlug('mapa')
        const configuracion = getConfigModuleFromSlug('mapa')
        if(show_module === false) return false
      
      return <div className='mt-2'>
        <MapaDetallePedidoTrackingPage markers={marcadores} height={300} />
        {configuracion.extra}
      </div> 
      } 

    const mostrarEstadosCarga = () => {
        let show_module = getConfigFromModuleSlug('estados_carga')
        const configuracion = getConfigModuleFromSlug('estados_carga')
        if(show_module === false) return false

        return <div>
            <div className='mt-2'>{mostrarEstados(detalle_pedido.estados, 'accordion')}</div>
            {configuracion.extra}
          </div> 
    }

    const mostrarReferencia = (orden, conductor) => {
      
        let show_module = getConfigFromModuleSlug('resumen')
        const configuracion = getConfigModuleFromSlug('resumen')
        if(show_module === false) return false

        return  <div className='shadow-sm border pt-2 px-3 w-100 bg-white'>
        {/* <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Referencia</label> */}
        <div className='d-flex justify-content-between align-items-center mt-2'>
        <div className='d-flex'>
          <h4 className=' text-primary' style={{ fontWeight: 900 }}><i className="fa-solid fa-tag text-primary"></i> Orden:</h4>
          <h4 className=' ml-2 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.pedido}</h4>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Orden de transporte</label>
          <p className=' text-primary' style={{ fontWeight: 700 }}>{detalle_pedido.orden.ot ? detalle_pedido.orden.ot : <p><i style={{ fontSize: 14, fontWeight: 400 }}>- sin Informacion -</i></p>} </p>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Fecha Creación</label>
          <p className=' text-primary'>{orden.createdAt ? orden.createdAt : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
          {configuracion.extra}        
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Estado entrega</label>
          <p className='' style={{fontWeight:700}}>{shoyEstadoEntregaHTML(detalle_pedido.orden.estadoentrega, detalle_pedido.orden.estado_entrega, detalle_pedido.orden.sub_estado_entrega)}</p>
        </div>
        </div>

        <hr className='mt-0' />
        <h5 className='mb-2 d-block text-primary' style={{ fontWeight: 900 }}><i className="fa-regular fa-id-card"></i> Conductor:</h5>
        <div className='d-flex mb-2 mx-4 justify-content-between'>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Nombre:</label>
          <p className='mb-0 text-primary'>{conductor?.nombres ? conductor?.nombres : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Apellido:</label>
          <p className='mb-0 text-primary'>{conductor?.apellidos ? conductor?.apellidos : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Teléfono:</label>
          <p className='mb-0 text-primary'>{conductor?.phone ? conductor?.phone : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Vehículo:</label>
          <div className='d-flex justify-content-between'>
          <p className='mb-0 text-primary'>{conductor?.modelo ? conductor?.modelo : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
          <p className='mb-0 ml-2 text-primary'>{conductor?.patente ? conductor?.patente : false}</p>
          </div>
        </div>
        <div>
          <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Año:</label>
          <p className='mb-0 text-primary'>{conductor?.ano ? conductor?.ano : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
        </div>
      </div>
      </div>

      } 

      const mostrarExtra = (obj) => {
        if(!obj?.extra) return false 
        return <p className="form-control-label text-primary mb-1" style={{ fontSize: 16, fontWeight: "bold" }}>{obj.extra}</p>
        }

        const showField = (show, element) => {
            if(show === false) return false
            if(typeof show === "undefined" ) return element
            return element
        }

        const getConfigFromFieldSlug = (slug) => {
            if(!personalizacionTracking) return true
            if(typeof personalizacionTracking !== "object") return true
            if(!personalizacionTracking.detalles) return true
            if(!personalizacionTracking.detalles?.campos) return true
            const i = personalizacionTracking.detalles.campos.findIndex(c => c.slug === slug)
            if( i < 0 ) return true
            const config = personalizacionTracking.detalles.campos[i]
            if(typeof config !== "object") return true
            return config.activo === true
        }
        const getConfigFromModuleSlug = (slug) => {
            if(!personalizacionTracking) return true
            if(typeof personalizacionTracking !== "object") return true
            if(!personalizacionTracking.detalles) return true
            if(!personalizacionTracking.detalles?.modulos) return true
            const i = personalizacionTracking.detalles.modulos.findIndex(c => c.slug === slug)
            if( i < 0 ) return true
            const config = personalizacionTracking.detalles.modulos[i]
            if(typeof config !== "object") return true
            return config.activo === true
        }
        const getConfigModuleFromSlug = (slug) => {
            if(!personalizacionTracking) return {}
            if(typeof personalizacionTracking !== "object") return {}
            if(!personalizacionTracking.detalles) return {}
            if(!personalizacionTracking.detalles?.modulos) return {}
            const i = personalizacionTracking.detalles.modulos.findIndex(c => c.slug === slug)
            if( i < 0 ) return {}
            const config = personalizacionTracking.detalles.modulos[i]
            if(typeof config !== "object") return {}
            return config
        }

      const mostrarMasInfo = (orden, facturacion) => {

        let show_module = getConfigFromModuleSlug('mas_info')
        const configuracion = getConfigModuleFromSlug('mas_info')
        if(show_module === false) return false
        
        const elemento_notas = <Col md={12} className='mb-1'>
            <hr className='mt-0' />
            <h6 className='mb-0 d-block text-primary' style={{ fontWeight: 900 }}><i class="fa-regular fa-clipboard"></i> Notas:</h6>
            {configuracion.extra}
        </Col>


        const elemento_refrigerados = <div className='d-flex align-items-center'>
        <label className="form-control-label text-primary" style={{ fontWeight: 700 }}>Refrigerados:</label>
        <h6 className='ml-1 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.refrigerado}</h6>
        </div>


        const elemento_bultos = <div className='d-flex align-items-center'>
        <label className="form-control-label text-primary" style={{ fontWeight: 700 }}>Secos:</label>
        <h6 className='ml-1 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.bultos}</h6>
        </div>


        const bultos_congelados = <div className='d-flex align-items-center'>
        <label className="form-control-label text-primary" style={{ fontWeight: 700 }}>Congelados:</label>
        <h6 className='ml-1 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.congelado}</h6>
        </div >


        const elemento_direccion = <Col md={4}>
        <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Detalles dirección</label>
        <p className='text-primary'>{facturacion.address_1}</p>
        <p className='text-primary'>{facturacion.address_2}</p>
        </Col>




        const elemento_nombre = <Col md={4}>
        <label className='form-control-label text-primary mb-0' style={{ fontWeight: 700, fontSize: 14 }}>Nombre</label>
        <p className='text-primary'>{facturacion.first_name}</p>
        </Col>



        const elemento_email = <Col md={4}>
        <label className='form-control-label mb-0 d-block text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Email</label>
        <p className='text-primary'>{facturacion.email}</p>
        </Col>


        const elemento_movil = <Col md={4}>
        <label className='form-control-label mb-0 d-block text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Móvil</label>
        <p className='text-primary'>{facturacion.phone}</p>
        </Col>


        // <div className='shadow-sm border pt-2 px-3 w-100 bg-white' >
        // <Row className='px-3 mb-2 justify-content-between'>
        //   <div>
        //   <h5 className='mb-0 text-primary' style={{ fontWeight: 900, fontSize: 20 }} ><i className="fa-solid fa-truck-ramp-box"></i> Productos</h5>
        //   </div>
        //   <div className='d-flex' style={{ gap: '2rem' }}>

          

          
        //     </div>
        // </Row>
        //   <TablaProductos productos={detalle_pedido.orden.productos} viewType='simple' />
        // </div>

        const show_notas                  = showField( getConfigFromFieldSlug("notas"), elemento_notas  )
        const show_refrigerados           = showField( getConfigFromFieldSlug("bultos_refrigerados"), elemento_refrigerados  )
        const show_bultos                 = showField( getConfigFromFieldSlug("bultos"), elemento_bultos  )
        const show_congelados             = showField( getConfigFromFieldSlug("bultos_congelados"), bultos_congelados  )
        const show_direccion              = showField( getConfigFromFieldSlug("direccion"), elemento_direccion  )
        const show_nombre                 = showField( getConfigFromFieldSlug("destinatario"), elemento_nombre  )
        const show_email                  = showField( getConfigFromFieldSlug("email"), elemento_email  )
        const show_movil                  = showField( getConfigFromFieldSlug("movil"), elemento_movil  )
        
        return <div className='shadow-sm border pt-2 px-3 w-100 bg-white'>
          { !show_nombre && !show_email && !show_movil && !show_direccion ? false : <h5 className='mb-0 d-block text-primary' style={{ fontWeight: 900 }} ><i className="fa-solid fa-street-view text-primary"></i> Destinatario</h5> }
          <div className='d-flex pt-2 mx-2 w-100' >
          {show_nombre}
          {show_email}
          {show_movil}     
          {show_direccion}
          </div>

          <hr className='mt-0' />
          <div className='d-flex justify-content-between mb-2 mr-4' style={{ gap: '2rem' }}>
          { !show_bultos && !show_congelados && ! show_refrigerados ? false : <h5 className='mb-0 d-block text-primary' style={{ fontWeight: 900}} ><i className="fa-solid fa-truck-ramp-box"></i> Productos</h5> }
          {show_bultos}
          {show_congelados}
          {show_refrigerados}
          </div>
          <TablaProductos productos={detalle_pedido.orden.productos} viewType='simple' />
          
          {show_notas}
        </div>
      }

        const mostrarFooter = () => {
          let show_module = getConfigFromModuleSlug('footer')
          const configuracion = getConfigModuleFromSlug('footer')
          if(show_module === false) return false
          return <div>
            <h6>Powered By</h6>
            <img src='https://clientes.tulogistica.cl/img/logo-delpa-gris.png' style={{ width: 140 }} alt="logo-delpa" />
            {configuracion.extra}
          </div>
          } 

          
      return (
        <div className="p-3">      
          {mostrarDatosPersonalizados()} 
          <div className='mt-3 text-center'>
          {mostrarFooter()}
          </div>
        </div>
      )
}

export default Tracking
