import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Form, Modal } from 'react-bootstrap'
import data, { urlapi } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import * as XLSX from 'xlsx'
import { corregirTelefono, sliceIntoChunks, validateEmail } from '../../../lib/helpers/helpers';
import { host, url_images } from '../../../lib/global/data';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy } from '../../../lib/helpers/dates';
import CargandoFlotante from '../../general/cargando_flotante'
import BuscadorExcel from '../../excel-formats/selector';
import { esquema_destinatarios } from '../../../lib/esquemas';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidChevronRight } from 'react-icons/bi';
import { cerrarSesion } from '../../../redux/actions/session';
import { AiOutlineFileExcel } from 'react-icons/ai';
import InformacionEsquema from '../../general/descarga_modal_excel_esquema';
import CamposPersonalizadosEsquema from '../../general/campos_personalizados_esquema';
import { rutas } from '../../../lib/routes/routes';

const DestinatariosImportador = (props) => {
    const dispatch = useDispatch()
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const esquema = [
        { value: 'razon_social', label: 'Razon social' },
        { value: 'tipo', label: 'Tipo', info:"Solo de acepta: empresa o persona" },
        { value: 'rut', label: 'Rut' },
        { value: 'direccion_principal', label: 'Direccion', optionalUpload: true },
        { value: 'telefono_fijo', label: 'Telefono fijo', optionalUpload: true },
        { value: 'telefono_movil', label: 'Telefono movil', optionalUpload: true },
        // { value: 'destinos', label: 'Destinos', info: "Utiliza la barra (/) para separar varios puntos de entrega" },
        // { value: 'unidad_medida', label: 'Unidad medida', producto: true, optionalUpload: true, info: "Solo usar: MTR, INCH, CM, SQFT, SQM" , validation: { type: "string", key: "", values: unidades_medida.map(e => e.code) } },
        // { value: "rutcliente", label: "Rut cliente", optionalUpload: true },
        // { value: "productos", label: "Productos", denieUpload: true },
    ]
    const [ columnasrequeridas, setColumnasRequeridas ] = useState(esquema.map(e => e.value))
    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ camposPersonalizados, setCamposPersonalizados ] = useState([])
    const [ operacionesListas, setOperacionesListas] = useState(0)
    const session = useSelector(state => state.miusuario)
    const [ formatoSeleccionado, setFormatoSeleccionado ] = useState(false)
    const token = session.tokenSession
    const [duplicados, setDuplicados] = useState([])
    const modulo = "destinatarios"

 
    const cargandoScreen = () => {
        return <Row>
            <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
            </Row>
    }


    const crearDestinatario = async (registros) => {
        return fetch(`${data.urlapi}/${modulo}/batch`,{
            method:'POST',
            body: JSON.stringify({destinatarios: registros}),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(res){
                const buscarErrores = res.filter(e => e.errorMessage)
                let arrayErrores = []
                if(buscarErrores){ 
                    for(const error of buscarErrores){
                        arrayErrores.push(error)}}
                        setErrores(prev => [...prev, ...arrayErrores])
                return res
            }
            return false
        })
        .catch(error => {
            toast.error(error.message)
            return false
        })
    }

    const importacionExitosa = () => {
        if(props.onDatosImportados) return props.onDatosImportados()
        return true
    }

    const subirRegistros = async () => {
        if(registros.length < 1) return toast.error("No hay registros para importar")
        setErrores([])
            setLoadingCarga(true)
            setMensajeLoading('Validando Nombres')
            const validar_referencias = await validarReferencias(registros)
    
            if(Array.isArray(validar_referencias) !== true ){
                setLoadingCarga(false)
                return
            }
            
            if(validar_referencias.length > 0){
                    setRegistros(prev => [...[], ...registros])
                    setLoadingCarga(false)
                    return  
            } 

            setMensajeLoading('Validando Ruts')
            const validar_rut = await validarRut(registros)
    
            if(Array.isArray(validar_rut) !== true ){
                setLoadingCarga(false)
                return
            }
            
            if(validar_rut.length > 0){
                    setRegistros(prev => [...[], ...registros])
                    setLoadingCarga(false)
                    return  
            } 

        setMensajeLoading('Creando Destinatarios')
        setLoadingMaster(true)
        setLoadingOperaciones(registros.length)
        setOperacionesListas(0)
        let total_procesadas = 0
      
            const dividir = sliceIntoChunks(registros, 50)
    
            for( const array of dividir ){           
                await crearDestinatario(array)
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
            }
    
        setLoadingMaster(false)
        setLoadingCarga(false)

  
        toast.success("Progreso finalizado")
        importacionExitosa()
        setTimeout(() => {
            window.location = `/${rutas.destinatarios.slug}`
        }, 500);       
    setRegistros([])
    }

    const end_point_validar_referencia = async (referencias) => {
        const data_default = referencias.map(pa => ({ razon_social: pa, valido: false }))
        return fetch(`${data.urlapi}/${modulo}/validar/razon_social`,{
            method:'POST',
            body: JSON.stringify({
                referencias
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(Array.isArray(res) !== false){
                return res
            }
            return data_default
        })
        .catch(error => {
            toast.error(error.message)
            return data_default
        })
    }

    const end_point_validar_rut = async (referencias) => {
        const data_default = referencias.map(pa => ({ rut: pa, valido: false }))
        return fetch(`${data.urlapi}/${modulo}/validar/rut`,{
            method:'POST',
            body: JSON.stringify({
                referencias
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(Array.isArray(res) !== false){
                return res
            }
            return data_default
        })
        .catch(error => {
            toast.error(error.message)
            return data_default
        })
    }

    
    const validarReferencias = async (pedidos) => {
        let referencias = []
        pedidos.map(pedido => referencias.push(pedido.razon_social))
    
        setLoadingMaster(true)
        setLoadingOperaciones(registros.length)
        setOperacionesListas(0)
        setErrores([])
        const arraydividido = sliceIntoChunks(referencias, 50)
        let cantidad_procesada = 0
        let registros_duplicados = []
        for( const datos of arraydividido){
            const response = await end_point_validar_referencia(datos)
            console.log("response", response)
            const duplicados = response.filter(da => da.valido === false)
            console.log("duplicados", duplicados)
            if(duplicados.length > 0) duplicados.map(ped => errores.push(`Razon Social "${ped.razon_social}" ya existe`))
            cantidad_procesada = cantidad_procesada + datos.length
            setOperacionesListas(cantidad_procesada)
            setErrores(prev => [...[], ...errores])
            setDuplicados(prev => [...prev, ...duplicados])
            registros_duplicados = [...registros_duplicados, ...duplicados]
            console.log("registros_duplicados", registros_duplicados)
        }

        setLoadingMaster(false)
        setLoadingOperaciones(0)
        setOperacionesListas(0)
        return registros_duplicados
    }

    const validarRut = async (pedidos) => {
        let referencias = []
        pedidos.map(pedido => referencias.push(pedido.rut))
    
        setLoadingMaster(true)
        setLoadingOperaciones(registros.length)
        setOperacionesListas(0)
        setErrores([])
        const arraydividido = sliceIntoChunks(referencias, 50)
        let cantidad_procesada = 0
        let registros_duplicados = []
        for( const datos of arraydividido){
            const response = await end_point_validar_rut(datos)
            console.log("response", response)
            const duplicados = response.filter(da => da.valido === false)
            console.log("duplicados", duplicados)
            if(duplicados.length > 0) duplicados.map(ped => errores.push(`Rut "${ped.rut}" ya existe`))
            cantidad_procesada = cantidad_procesada + datos.length
            setOperacionesListas(cantidad_procesada)
            setErrores(prev => [...[], ...errores])
            setDuplicados(prev => [...prev, ...duplicados])
            registros_duplicados = [...registros_duplicados, ...duplicados]
            console.log("registros_duplicados", registros_duplicados)
        }

        setLoadingMaster(false)
        setLoadingOperaciones(0)
        setOperacionesListas(0)
        return registros_duplicados
    }

    const handleCloseErrores = () => {
        return setShowModalErrores(false)
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3 mb-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><b><BiSolidChevronRight size={20} />{error}</b></p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }

    const tablaRegistros = () => {
        if(registros.length < 1) return false

        return <div>
            <Table>
                <thead>
                    <tr>
                        <th>NOMBRE O RAZON SOCIAL</th>
                        <th>TIPO</th>
                        <th>RUT</th>
                        <th>DIRECCIÓN PRINCIPAL</th>
                        <th>TELEFONO FIJO</th>
                        <th>TELEFONO MOVIL</th>
                        {camposPersonalizados.map((campo,ic) => <th key={`campo-${ic}`}>{campo.key}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.map((reg,ire) => {
                            return <tr key={`tabla-${ire}`}>
                            <th>{reg.razon_social}</th>
                            <th>{reg.tipo}</th>
                            <th>{reg.rut}</th>
                            <th>{reg.direccion}</th>
                            <th>{reg.telefono_fijo}</th>
                            <th>{reg.telefono_movil}</th>
                            {
                                camposPersonalizados.map((campo,ic) => {
                                    const i = reg.meta_data.findIndex(e => e.key === campo.key)
                                    if(i < 0) return <td key={`campo-${ire}-${ic}`}></td>
                                    return <td key={`campo-${ire}-${ic}`}>{reg.meta_data[i].value}</td>
                                })
                            }
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
    }
  

    const accionesCarga = () => {
        if(registros.length < 1) return false
        let vehiculos = []
        registros.map(re => {
            const i = vehiculos.filter(v => v === re.vehiculo)
            if(i.length > 0) return 
            vehiculos.push(re.vehiculo)
            return
        })


        return <div className='mb-3'>
            {
                loadingCarga === true ? <div>
                    <Spinner animation='border' />
                    <h5>Espere un momento</h5>
                    </div> : <div>
                    
                    <Row>
                       <Col md={12}>
                        <h5>{registros.length} Registros cargados</h5>
                        <p className='mb-1'>Has click para crear <b className='text-primary'>{registros.length} destinatario</b></p>
                        <Button size="sm" variant="success" className="mt-3" onClick={()=>subirRegistros()} >CREAR {registros.length} DESTINATARIO</Button>
                        </Col>
                    </Row>
                    </div>
            }
        </div>
    }


    const mostrarRegistros = () => {
        if(loadingExcel === true ) return cargandoScreen()
        return <div>
            {mostrarErrores()}
            {accionesCarga()}
            {tablaRegistros()}
        </div>
    }

    const reemplazarLlave = (key) => {
        if(!formatoSeleccionado) return key
        const i = formatoSeleccionado.columnas.findIndex(e => e.target === key)
        if(i > -1) return formatoSeleccionado.columnas[i].key
        return key
    }

    const handleInputChange = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            if(!hojas[0][0]) return toast.error('No se encontraron datos')
            
            let errores = []
            let registros = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER

            let columnas_buscar = columnasrequeridas

            if(formatoSeleccionado){
                if(formatoSeleccionado.columnas){
                    if( Array.isArray(formatoSeleccionado.columnas) !== false ){
                        for( const col of formatoSeleccionado.columnas ){
                            const { target } = col
                            const pos = columnas_buscar.findIndex(c => c === target)
                            if(pos > -1) columnas_buscar[pos] = col.key
                        }
                    }
                }
            }

            hojas[0].map((hoja,pos) => {
                
                let objeto = {
                    meta_data: []
                }
                
                for( const key of esquema.filter(e => !e.producto) ){
                    if(key.denieUpload === true ) continue
                    const llaves_obtenidas = Object.keys(hoja)
                    const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.label.toLocaleLowerCase())
                    if(i < 0){
                        if(key.optionalUpload === true) continue
                        errores.push(`${key.label} no encontrado en la columna ${pos}`)
                        continue
                    } else {
                        objeto[key.value] = hoja[ llaves_obtenidas[i] ]
                    }
                }

                for( const campo of camposPersonalizados ){
                    const { key, value } = campo
                    const llaves_obtenidas = Object.keys(hoja)
                    const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === value.toLocaleLowerCase())
                    if(i < 0) continue
                    objeto.meta_data.push({ key, value: hoja[ llaves_obtenidas[i] ] })
                
                }
                console.log(objeto)
                registros.push(objeto)
            })

            const buscarTipos = registros.filter(t => t.tipo !== "empresa" && t.tipo !== "persona" )
            console.log("bt",buscarTipos)
            if(buscarTipos.length > 0){ 
                for(let element of buscarTipos){
                    errores.push(`El campo (tipo) del excel debe contener la palabra "persona" o "empresa" no "${element.tipo}"`)
                setErrores(prev => [...[], ...errores])}
            setLoadingExcel(false)
            return}
            
            console.log(registros)
            if(errores.length > 0){
                setErrores(errores)
                setLoadingExcel(false)
                return toast.error(`Hay errores en el archivo, corrijelos e intenta de nuevo`)
            }
            setLoadingExcel(false)
            return setRegistros(registros)
          }
        }
    }
  

    const onChangeValueExcel = (e) => {
        setFormatoSeleccionado(e)
    }

    const campos_extra = <CamposPersonalizadosEsquema condicion_default={{ module: modulo }} onFetched={(data) => setCamposPersonalizados(data.map((e) => ({ key: e.key, value: e.nombre })))} />

    return <div>
        {/* <h4><i className="far fa-file-excel"></i> Importar datos excel</h4> */}
        <p>Puedes importar un archivo excel para crear destinatarios</p>
        
            <Card className='p-3'>
        <Row>

            <Col md={6}>
            <h4 className='d-block'><AiOutlineFileExcel size={30} color='#2d9d57' /> Subir archivo excel</h4>
            <Row>
                <Col>
                <div className='mb-3'><InformacionEsquema esquema={esquema} extra_content={campos_extra} /></div>
                
                <label className='form-control-label'>Carga aquí tu archivo excel</label>
                {
                    loadingExcel ? <Spinner animation='border' /> : <input
                    type="file" 
                    name="file" 
                    id="file" 
                    className="form-control mb-3"
                    onChange={handleInputChange} 
                    placeholder="Archivo de excel" 
                />
                }
                </Col>
            </Row>
            
            </Col>
            <Col md={6}>
            {campos_extra}
            </Col>
        </Row>
            </Card>
        
        
        <CargandoFlotante visualizar={loadingMaster} mensaje={mensajeLoading} procesando={loadingOperaciones} procesado={operacionesListas}  />
        {mostrarRegistros()}
    </div>

}

export default DestinatariosImportador