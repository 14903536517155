import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, Spinner, Form } from 'react-bootstrap'
import data, { configs3 } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import S3FileUpload from "react-s3/lib/ReactS3"
import { fechaATexto } from '../../../lib/helpers/helpers';
import { cerrarSesion } from '../../../redux/actions/session';
import { useDispatch } from 'react-redux';
import LoadingIntegrations from '../../general/loadingAnimations/loadingIntegrations';

const WidgetsListado = (props) => {
    const [ usuarios, setUsuarios ] = useState([{ value:'all_access', label:'Todos los permisos'}])
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ creando, setCreando ] = useState(false)
    const [ loadingUsuarios, setLoadingUsuarios ] = useState(true)
    const [ nuevaKey, setNuevaKey ] = useState({})
    const [ widgetSeleccionado, setWidgetSeleccionado ] = useState({})
    const [ openModal, setOpenModal ] = useState(false)
    const [ idActualizando, setIdActualizando ] = useState(false)
    const [ openModalKC, setOpenModalKC ] = useState(false)
    const [ keyCreada, setKeyCreada ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ subiendoLogo, setSubiendoLogo ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const token = props.token ? props.token : false
    const trash = props.trash ? props.trash : false
    const dispatch = useDispatch()

    const handleChangeImagen = (e) => {
  
        if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')
        const archivo = e.target.files[0]
  
        setSubiendoLogo(true)
        return S3FileUpload.uploadFile(archivo, configs3)
        .then(data => {
        if(!data.location) {
            toast.error('No pudimos cargar tu imagen')
            return this.setState({ subiendofoto: false })
        }
  
        nuevaKey.logo = data.location
        setNuevaKey({...{}, ...nuevaKey})
        return setSubiendoLogo(false)
        })
        .catch(err => {
            setSubiendoLogo(false)
            return toast.error('No pudimos cargar tu imagen')
        })
      }

    const crearNuevaKey = async () => {
        const requeridos = [
            { value: "nombre", label: "Nombre" },
            { value: "titulo", label: "Título" },
            { value: "descripcion", label: "Descripción" },
            { value: "ayuda", label: "Texto de ayuda" },
            { value: "sin_datos_mensaje", label: "Mensaje cuando no hay datos" },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevaKey[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setCreando(true)
        return fetch(`${data.urlapi}/integraciones/widget`,{
            method:'POST',
            body: JSON.stringify(nuevaKey),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            setCreando(false)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(res._id){
                setKeyCreada(res)
                conductores.unshift(res)
                setOpenModal(false)
                setConductores(prev => [...[], ...conductores])
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            setCreando(false)
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })

    }

    const obtenerUsuarios = async () => {
        return fetch(`${data.urlapi}/usuarios/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingUsuarios(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingUsuarios(false)
            } else if(Array.isArray(res) !== false){
                const opciones = res.map(u => ({ value: u._id, label: `${u.email} · ${u.nombres}` }))
                setUsuarios(prev => [...prev, ...opciones])
            }
            return setLoadingUsuarios(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingUsuarios(false)
        })
    }

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/integraciones/widgets/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerConductores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
        setOpenModalKC(false)
    }

    const handleChangeNuevaKey = (e) => {
        const { name, value } = e.target
        nuevaKey[name] = value
        return setNuevaKey(nuevaKey)
    }

    const onChangeUsuario = (e) => {
        if(!e.value) return
        nuevaKey.id_user = e.value
        return setNuevaKey(nuevaKey)
    }

    const modalFiltro = () => {
        
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={3} className="mb-2">
                    <label className='d-block form-control-label'>Nombre</label>
                    <input className='form-control' name="nombre" onChange={handleChangeNuevaKey} />
                </Col>
                <Col xs={3} className="mb-2">
                    <label className='d-block form-control-label'>Título en la web</label>
                    <input className='form-control' name="titulo" onChange={handleChangeNuevaKey} />
                </Col>
                <Col xs={3} className="mb-2">
                    <label className='d-block form-control-label'>Descripción</label>
                    <input className='form-control' name="descripcion" onChange={handleChangeNuevaKey} />
                </Col>
                <Col xs={3} className="mb-2">
                    <label className='d-block form-control-label'>Texto de ayuda</label>
                    <input className='form-control' name="ayuda" onChange={handleChangeNuevaKey} />
                </Col>
                <Col xs={6} className="mb-2">
                    <label className='d-block form-control-label'>Mensaje al no encontrar datos</label>
                    <input className='form-control' name="sin_datos_mensaje" onChange={handleChangeNuevaKey} />
                </Col>
                <Col xs={3} className="mb-2">
                    <label className='d-block form-control-label'>Color</label>
                    <input className='form-control' type="color" name="color_boton" onChange={handleChangeNuevaKey} />
                </Col>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Logotipo</label>
                    {
                        subiendoLogo ? <Spinner animation='border' /> : <input  
                        type="file" 
                        name="file" 
                        id="file_cambio"
                        className="form-control"
                        onChange={handleChangeImagen} 
                        placeholder="LOGOTIPO" 
                        />
                    }
                    {nuevaKey.logo ? <img className='mt-2' style={{ width: 100 }} src={nuevaKey.logo} /> : false }
                    
                </Col>
                <Col xs={12} className="mb-2">
                    <hr />
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="switch" name="mostrar_mapa" label="Mostrar mapa" />
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="switch" name="mostrar_estados" label="Mostrar estados" />
                    </Form.Group>
                </Col>
                <Col xs={6} className="mb-2">

                </Col>
                <Col md={12}>
                    { creando === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={() => crearNuevaKey()}>CREAR</Button> }
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const copiarTexto = (id) => {
        toast.info("Copiado")
        return navigator.clipboard.writeText(id)
    }
    
    const modalKC = () => {


        const url = `https://ultimamilla.delpasystem.com/widget/${widgetSeleccionado._id}?timestamp=`
        const texto = `<div id="lastmile-delpa-widget-container"></div>
        <script type="text/javascript">
            const timestamp = new Date().toLocaleTimeString()
            const url = "${url}" + timestamp
            const delpa_id_iframe = document.getElementById("lastmile-delpa-widget-container")
            if(delpa_id_iframe){
                delpa_id_iframe.innerHTML = '<iframe name="lastmile-delpa-widget" id="lastmile-delpa-widget" frameBorder="0" width="100%" height="600px" src="' + url + '" />'
            } 
        </script>`
        
        return <Modal show={openModalKC} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Body>
            <h2 className='mb-0'>Este es el código de tu widget</h2>
            <p>Copia este código y pégalo en tu sitio web.</p>
            <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>Has click para copiar</Tooltip>
          }
        >
            
            <div>
            <code >
            <p onClick={() => copiarTexto(texto)}>{texto}</p>
            </code>
            </div>
            </OverlayTrigger>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        obtenerConductores(1, false)
        obtenerUsuarios()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <LoadingIntegrations />
    }

    const confirmarEliminado = async (id) => {
        setLoadingConductores(true)
        return fetch(`${data.urlapi}/integraciones/apikeys?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = '/configuracion'
                }, 1000);
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const solicitarEliminarKey = (id) => {
            return confirmAlert({
                title: `¿Estás seguro?`,
                message: `Estás a punto de eliminar este registro, esta acción no se puede deshacer`,
                buttons: [
                  {
                    label: 'CONFIRMAR',
                    onClick: () => confirmarEliminado(id)
                  },
                  {
                    label: 'CANCELAR',
                    onClick: () => false
                  }
                ]
              })
    }

    const actualizarApiKey = async (objeto) => {
        return fetch(`${data.urlapi}/integraciones/widget`,{
            method:'PUT',
            body: JSON.stringify(objeto),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setIdActualizando(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setIdActualizando(false)
            } else if(res._id){
                toast.success("Actualizado exitosamente")
            }
            return setIdActualizando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setIdActualizando(false)
        })
    }

    const handleChangeSelect = (e,_id) => {
        setIdActualizando(_id)
        return actualizarApiKey({
            _id,
            id_user: e.value
        })
    }

    const cambiarStatus = ( _id, active ) => {
        setIdActualizando(_id)
        const i = conductores.findIndex(u => u._id === _id)
        conductores[i].active = active
        setConductores(prev => [...[], ...conductores])
        return actualizarApiKey({
            _id,
            active
        })
    }

    const verCodigo = (info) => {
        setWidgetSeleccionado(info)
        setOpenModalKC(true)
    }

    const tabla_registros = (registros) => {
        if(loadingConductores===true) return cargandoScreen()
        if(registros.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            {
                        registros.map(usuario => {

                            let item_default = false
                            const i_default = usuarios.findIndex(u => u.value === usuario.id_user)
                            if(i_default > -1 ){
                                item_default = { value: usuarios[i_default].value, label: usuarios[i_default].label }
                            }

                            return <Card key={`user-${usuario._id}`} className="p-3 mb-3">
                                <Row>
                                    <Col md={4}>
                                        <span style={{ fontSize: 12 }}>Creada: { fechaATexto(usuario.createdAt) }</span>
                                        <h4>{usuario.titulo}</h4>
                                        <p>{usuario.descripcion ? usuario.descripcion : "Sin descripción"}</p>
                                            { idActualizando === usuario._id ? <Spinner animation="border" /> : false }
                                    <h6 className='hover' style={{ marginTop: 14 }} onClick={() => cambiarStatus(usuario._id, usuario.active === true ? false : true )}>{ usuario.active === true ? 
                                    <i className="fas text-success fa-check-square"></i> :
                                    <i className="far fa-square"></i> } Activo</h6>

                                    <Button onClick={() => verCodigo(usuario)} size='sm' variant="outline-primary" >VER CÓDIGO</Button>
                                    </Col>
                                    <Col md={4}>
                                        
                                    
                                    </Col>
                                    <Col md={4} className="text-right">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Eliminar</Tooltip>
                                            }
                                            >
                                        <Button variant="danger" onClick={() => solicitarEliminarKey(usuario._id)} ><i className="far fa-trash-alt"></i></Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            </Card>
                        })
            }
        </div>
    }

    return <div>
        {modalFiltro()}
        <Row>
            <Col md={12}>
            {modalKC()}
            <h4>Tracking embedido <i className="fa-solid fa-circle-plus text-primary hover" onClick={() => setOpenModal(true)}></i></h4>
            {tabla_registros(conductores)}
            </Col>
        </Row>
    </div>

}

export default WidgetsListado