import { useEffect, useState } from "react"
import data, { maps_key, url_front_produccion, urlapi } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Link, useParams } from "react-router-dom";
import { Button, Card, Col, Modal, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { url_images } from "../../../lib/global/data";
import MapaRutaAsignacion from "../../../components/Mapas/mapa_ruta_preview";
import { getNombreDestinatario } from "../../../lib/helpers/pedidos/pedidos";
import { obtenerZonasDePedidos } from "../../../lib/helpers/rutas";
import { duracionTiempoString, fechaATextoSimple, fechaATextoSimpleCorto, fechaUTCATiempo } from "../../../lib/helpers/helpers";
import { BsBoxArrowInUpRight, BsBoxSeam, BsCalendarCheck, BsFillCircleFill, BsFillSignStopLightsFill, BsFillStopCircleFill } from "react-icons/bs";
import { FiAlertCircle, FiMapPin } from "react-icons/fi";
import BoxList from "../../ordenes/box-list";
import { AiOutlineCar } from "react-icons/ai";
import MapaInformeTracking from "../../../components/Mapas/mapa_informe_tracking";
import { useSelector } from "react-redux";
import { procesarInformacionParadas } from "../../../lib/helpers/informes_tracking";
import { formatoMoneda } from "../../../lib/helpers/main";
import { estilo_last_mile } from "../../../lib/global/styles";

const InformeRastreoOrden = (props) => {
    //Modals
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [modal4, setModal4] = useState(false);
    const [modal5, setModal5] = useState(false);

    const handleClose1 = () => setModal1(false);
    const handleClose2 = () => setModal2(false);
    const handleClose3 = () => setModal3(false);
    const handleClose4 = () => setModal4(false);
    const handleClose5 = () => setModal5(false);

    const handlemodal1 = () => setModal1(true);
    const handlemodal2 = () => setModal2(true);
    const handlemodal3 = () => setModal3(true);
    const handlemodal4 = () => setModal4(true);
    const handlemodal5 = () => setModal5(true);


    const [empresa, setEmpresa] = useState(false)
    const [enlace, setEnlace] = useState(false)
    const [ruta, setRuta] = useState(false)
    const [paradas, setParadas] = useState([])
    const [rutas, setRutas] = useState([])
    const [poligonos, setPoligonos] = useState([])
    const [loading, setLoading] = useState(true)
    const [marcadores, setMarcadores] = useState([])
    const [error, setError] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const { id } = useParams()

    useEffect(() => {
        obtenerDetalles()
    }, [])

    const validarPuntos = (route) => {

        let markers = []
        if (route.pedidos) {
            if (Array.isArray(route.pedidos) !== false) {
                for (const orden of route.pedidos) {
                    if (orden.location) {
                        if (Array.isArray(orden.location.coordinates)) {
                            if (orden.location.coordinates.length > 0) {
                                markers.push({
                                    lat: orden.location.coordinates[1],
                                    lng: orden.location.coordinates[0],
                                    ref: orden.pedido,
                                    // destinatario: getNombreDestinatario(pedido)
                                })
                            }
                        }
                    }
                }
                return setMarcadores(markers)
            }
        }
    }

    const obtenerDetalles = async () => {
        if (!id) return setLoading(false)
        return fetch(`${data.urlapi}/ordenes/tracking/detail?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                if (!pros) {
                    toast.error('Hubo un error de servidor')
                    setError(true)
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage)
                    setError(true)
                } else if (typeof pros === "object") {
                    if (pros._id) setRuta(pros)
                    if (pros.rutas) if (Array.isArray(pros.rutas) !== false) setRutas(pros.rutas)
                    if (pros.poligonos) if (Array.isArray(pros.poligonos) !== false) setPoligonos(pros.poligonos)
                    if (pros.paradas) if (Array.isArray(pros.paradas) !== false) setParadas(procesarInformacionParadas(pros.paradas))
                }
                return setLoading(false)
            })
            .catch(async error => {
                toast.error('Error al obtener los datos')
                setError(true)
                return setLoading(false)
            })
    }

    const cargandoScreen = () => {
        return <div style={{ width: "100%", height: "100vh", alignItems: "center", display: "flex", justifyContent: "center" }}>
            <div className="text-center mb-3">
                <div className="mb-3"><img style={{ width: "100%", maxWidth: 250 }} src={`${url_images}/logoColor.svg`} /></div>
                <Spinner animation='border' />
            </div>
        </div>
    }

    const pantallaVacia = () => {
        return <div style={{ width: "100%", height: "100vh", alignItems: "center", display: "flex", justifyContent: "center" }}>
            <div className="text-center mb-3">
                <div className="mb-3"><img style={{ width: "100%", maxWidth: 250 }} src={`${url_images}/logoColor.svg`} /></div>
                <h4>No hay datos disponibles para mostrar</h4>
            </div>
        </div>
    }

    const mostrarFotoPerfil = (driver) => {

        const default_object = <div style={{ display: "inline-block", verticalAlign: "middle" }}><AiOutlineCar size={35} /></div>
        if (!driver.image_profile) return default_object

        return <div className='p-1' style={{ display: "inline-block", width: 40, height: 40, borderRadius: "50%", verticalAlign: "middle", backgroundPosition: "center center", backgroundImage: `url(${driver.image_profile})`, backgroundSize: 'cover' }}></div>
    }

    const mostrarVehiculo = (driver) => {
        if (!driver) return false

        if (enlace) if (enlace.hide_driver === true) return false

        return <div style={{ alignItems: "center" }}>
            <div style={{ display: "inline-block", verticalAlign: "middle" }} className="vertical-align-center px-3">
                {mostrarFotoPerfil(driver)}
            </div>
            <div style={{ display: "inline-block", verticalAlign: "middle" }} className="vertical-align-center">
                <h6 className="mb-0" style={{}}><b>{driver.nombres}</b> {driver.apellidos}</h6>
                <h6 className="mb-0" style={{}}><b>{driver.modelo}</b> {driver.ano}</h6>
                <h6 className="mb-0" style={{ fontSize: 13 }}>{driver.phone}</h6>
                <h6 className="mb-0" style={{}}>PATENTE: <b>{driver.patente ? driver.patente.toUpperCase() : "SIN INFORMACIÓN"}</b></h6>
            </div>
            <hr className="hr" />
        </div>
    }

    const mostrarLogotipo = () => {
        const logo_last_mile = <img style={{ width: "100%", maxWidth: 100 }} src={`${url_images}/logoColor.svg`} />
        if (!empresa) return logo_last_mile
        if (typeof empresa !== "object") return logo_last_mile
        if (!empresa.logotipo) return logo_last_mile

        return <img style={{ width: "100%", maxWidth: 100 }} src={empresa.logotipo} />
    }

    const mostrarTracking = () => {
        if (enlace) if (enlace.hide_statuses) return false
        return <Tab eventKey="estados" title={<div><FiAlertCircle /> Eventos</div>} className="mt-3">

        </Tab>
    }

    const verInformacionGeografica = (punto) => {
        if (!punto) return false
        if (!punto.geo_datos) return false
        if (typeof punto.geo_datos !== "object") return false
        return <div>
            <hr className="mt-2 mb-2" />
            <Row>
                <Col md={12}><p className="mb-0"><i className="fa-solid fa-location-crosshairs"></i> {punto.geo_datos.formatted_address}</p></Col>
                <Col md={3}><p className="mb-0">{punto.geo_datos.pais}</p></Col>
                <Col md={3}><p className="mb-0">{punto.geo_datos.level1}</p></Col>
                <Col md={3}><p className="mb-0">{punto.geo_datos.level2}</p></Col>
            </Row>
            <hr className="mt-2 mb-2" />
        </div>
    }

    const mostrarFotos = (iz, photos) => {
        if(!photos) return false
        if(Array.isArray(photos) !== true) return false
        if(photos.length < 1) return false
        return <div>
            {
                                                photos.map((foto,i) => {
                                                    const url = `data:image/jpeg;base64,${foto.src}`
                                                    return <div key={`p-${iz}-${i}`} style={{ backgroundImage: `url(${url})`, width: 80, height: 80, backgroundSize: "cover", borderRadius: 10, boxShadow:"0px 0px 10px -4px black", margin: 5, display: "inline-block" }} ></div>
                                                })
            }
        </div>
    }

    const View = () => {
        if (loading) return cargandoScreen()
        if (!ruta) return pantallaVacia()

        let style_small = { fontSize: 13, textTransform: "uppercase", fontWeight: 700 }
        let zonas = []
        let km = ruta.distancia_km_recorrida
        // if (!isNaN(parseFloat(km))) km = parseFloat(km).toFixed(2)
        let validar_distancia = km ? km : 0
        if (validar_distancia < 1 && validar_distancia > 0) validar_distancia = "Menos de 1"
        const divisor = 12
        const estilo_col = 'mb-3'
        const estilo_card = 'p-2 shadow'

        return <div className="text-primary">
            <h4 style={{ fontWeight: 900 }}>Informe de rastreo</h4>
            <Row>
                <Col md={4} style={{ height: "100vh", overflowY: "scroll" }}>
                    <div className="">
                        {/* <div className="mb-3">{mostrarLogotipo()}</div> */}
                        <Row>
                                <Col md={divisor} className={estilo_col}>
                                <Card className={estilo_card}>
                                    <Link id='inicio' onClick={handlemodal1}>
                                        <p style={{ fontSize: 12, fontWeight: 700 }} className="mb-0"><i className="fa-solid fa-stopwatch"></i> Inicio de rastreo</p>
                                        <h6 className="mb-0">{fechaATextoSimpleCorto(ruta.inicio_rastreo)}</h6>
                                    </Link>
                                </Card>
                                <Modal id='inicio' show={modal1} onHide={handleClose1} className='text-primary'>
                                    <Modal.Header closeButton className="border-0">
                                        <Modal.Title><i className="fa-solid fa-stopwatch"></i> Inicio de Rastreo</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-block text-center pt-0">
                                        <img style={{ maxWidth: 250 }} src={`${url_images}/animations/Our place of business.gif`} />
                                        <p className="px-2">Este se refiere al comienzo de la transmisión de datos geograficos al iniciar la ruta.</p>
                                    </Modal.Body>
                                </Modal>
                                </Col>
                                <Col md={divisor} className={estilo_col}>
                                <Card className={estilo_card}>
                                    <Link onClick={handlemodal2}>
                                        <p style={{ fontSize: 12, fontWeight: 700 }} className="mb-0"><i className="fa-solid fa-flag-checkered"></i> Final de rastreo</p>
                                        <h6 className="mb-0">{fechaATextoSimpleCorto(ruta.final_rastreo)}</h6>
                                    </Link>
                                </Card>
                                <Modal show={modal2} onHide={handleClose2} className='text-primary'>
                                    <Modal.Header closeButton className="border-0">
                                        <Modal.Title><i className="fa-solid fa-flag-checkered"></i> Final del Rastreo</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-block text-center pt-0">
                                        <img style={{ maxWidth: 250 }} src={`${url_images}/animations/Online Delivery Service.gif`} />
                                        <p className="px-2">Este se refiere al final de la transmisión de datos geograficos al completar la ruta.</p>
                                    </Modal.Body>
                                </Modal>
                                
                                </Col>
                                <Col md={divisor} className={estilo_col}>
                                <Card className={estilo_card}>
                                    <Link onClick={handlemodal3}>
                                        <p className="mb-0" style={{ fontSize: 12, fontWeight: 700 }}><i className="fa-solid fa-location-crosshairs"></i> Coordenadas analizadas</p>
                                        <h6 className="mb-0">{formatoMoneda(ruta.coordenadas_analizadas)}</h6>
                                    </Link>
                                </Card>
                                <Modal show={modal3} onHide={handleClose3} className='text-primary'>
                                    <Modal.Header closeButton className="border-0">
                                        <Modal.Title><i className="fa-solid fa-location-crosshairs"></i> Coordenadas analizadas</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-block text-center pt-0">
                                        <img style={{ maxWidth: 250 }} src={`${url_images}/animations/Online Scooter Delivery System.gif`} />
                                        <p className="px-2">Este se refiere a la cantidad de coordenadas que se analizaron durante la ruta para emitir el reporte.</p>
                                    </Modal.Body>
                                </Modal>
                                </Col>
                                <Col md={divisor} className={estilo_col}>
                                <Card className={estilo_card}>
                                    <Link onClick={handlemodal4}>
                                        <p style={{ fontSize: 12, fontWeight: 700 }} className="mb-0"><i className="fa-regular fa-clock"></i> Tiempo conectado</p>
                                        <h6 className="mb-0">{duracionTiempoString(ruta.minutos_conexion)}</h6>
                                    </Link>
                                </Card>
                                <Modal show={modal4} onHide={handleClose4} className='text-primary'>
                                    <Modal.Header closeButton className="border-0">
                                        <Modal.Title><i className="fa-regular fa-clock"></i> Tiempo conectado</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-block text-center pt-0">
                                        <img style={{ maxWidth: 250 }} src={`${url_images}/animations/Student completing assignment on deadline.gif`} />
                                        <p className="px-2">Se refiere al tiempo total de rastreo de la orden al momento del reporte.</p>
                                    </Modal.Body>
                                </Modal>
                                </Col>
                                <Col md={divisor} className={estilo_col}>
                                <Card className={estilo_card}>
                                    <Link onClick={handlemodal5}>
                                        <p style={{ fontSize: 12, fontWeight: 700 }} className="mb-0"><i className="fa-solid fa-road"></i> Distancia en KM recorridos</p>
                                        <h6 className="mb-0">{validar_distancia === 0 ? "Sin datos" : `${formatoMoneda(validar_distancia)} Km.`}</h6>
                                    </Link>
                                </Card>
                                <Modal show={modal5} onHide={handleClose5} className='text-primary'>
                                    <Modal.Header closeButton className="border-0">
                                        <Modal.Title><i className="fa-solid fa-road"></i> Distancia en KM recorridos</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-block text-center pt-0">
                                        <img style={{ maxWidth: 250 }} src={`${url_images}/animations/Delivery Man Calling Customer.gif`} />
                                        <p className="px-2">Se refiere a la distancia de rastreo total medida en kilometros al momento del reporte.</p>
                                    </Modal.Body>
                                </Modal>
                                </Col>
                        </Row>
                        <Col md={divisor} className={estilo_col}>

                        <h4 style={{ fontWeight: 700 }}>Conductores designados</h4>
                        <Row>
                            <Card className="p-3 shadow-sm w-100">
                                <h5 className='mb-0' style={{ fontWeight: 900 }}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Nada por aquí</h5>
                                <p className="mb-0">No hay registros en esta sección</p>
                            </Card>
                        </Row>
                        </Col>
                    </div>
                </Col>
                <Col md={8}>
                    <Tabs defaultActiveKey="rutas" id="uncontrolled-tab-example" className="border-0">
                        <Tab eventKey="rutas" title="Rutas" className="shadow-sm border">
                            <Row className="mt-2 text-center">
                                <Col><h5 style={{ fontWeight: 900 }}><BsFillCircleFill color={estilo_last_mile.color_primary} /> RUTA SUGERIDA</h5></Col>
                                <Col><h5 style={{ fontWeight: 900 }}><BsFillCircleFill color={estilo_last_mile.color_secondary} /> RUTA UTILIZADA</h5></Col>
                                <Col><h5 style={{ fontWeight: 900 }}><BsFillStopCircleFill /> PARADA</h5></Col>
                            </Row>
                            <MapaInformeTracking rutas={rutas} poligonos={poligonos} paradas={paradas} markers={marcadores} height={600} pinType="uber" />
                        </Tab>
                        <Tab eventKey="paradas" title="Paradas" className="border-top">
                            {/* <hr /> */}
                            <h4 className='m-2' style={{ fontWeight: 700 }}><BsFillStopCircleFill /> {paradas.length} Paradas</h4>
                                {paradas.map((marker, iz) => {
                                    return <div key={`parada-${iz}`}>
                                        <Card className="p-2 mb-3 shadow">
                                            <p className="mb-0" style={{ textTransform: "uppercase", fontSize: 13 }}><b>{fechaATextoSimple(marker.desde)}</b></p>
                                            <Row>
                                                <Col><p style={style_small} className='mb-0'><i className="fa-solid fa-stopwatch"></i> {`Desde ${fechaUTCATiempo(marker.desde)}`}</p></Col>
                                                <Col><p style={style_small} className='mb-0'><i className="fa-solid fa-flag-checkered"></i> {`Hasta ${fechaUTCATiempo(marker.hasta)}`}</p></Col>
                                            </Row>
                                            {verInformacionGeografica(marker)}
                                            <a href={`https://www.google.com/maps?q=${marker.coordinates[1]},${marker.coordinates[0]}`} target='_blank' style={{ fontWeight: 700 }}><i className="fa-solid fa-map-location-dot"></i> VER EN GOOGLE <BsBoxArrowInUpRight /></a>
                                            {mostrarFotos(iz, marker.photos)}
                                        </Card>
                                    </div>
                                })}
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </div>
    }

    return View()
}

export default InformeRastreoOrden
