import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Breadcrumb } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import Select from 'react-select'
import { host } from '../../lib/global/data'
import GeocercaDrawer from '../../subComponents/geocercas/drawer'
import withRouter from '../RoutesApp/wrapped_component'
import { confirmAlert } from 'react-confirm-alert'
import { rutas } from '../../lib/routes/routes'
import { cerrarSesion } from '../../redux/actions/session'
import CamposPersonalizadosRelacionador from '../../subComponents/general/campos_personalizados_relacionador'
import { estilo_last_mile } from '../../lib/global/styles'
import RastreoCargaVehiculo from '../../subComponents/conductores/rastreo_carga'
import EtiquetasRelaciones from '../../subComponents/etiquetas-relaciones/etiquetas_relaciones'

class VehiculosEditar extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: this.props.params.id,
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            nuevoconductor: {
                activo: true
            },
            creandoVehiculo: true,
            loadingProveedores: true,
            proveedores: [],
            geocercas: [],
        }
        this.handleChangeVehiculo = this.handleChangeVehiculo.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
        this.handleChangeProveedor = this.handleChangeProveedor.bind(this)
    }

    componentDidMount(){
        const { id } = this.state
        if(!id) return this.setState({ loadingUsuario: true })
        this.getVehiculo(id)
        this.obtenerProveedores({})
    }

    handleChangeProveedor(e){
        const { nuevoconductor } = this.state 
        nuevoconductor.id_proveedor = e.value
        return this.setState(nuevoconductor)
    }

    async obtenerProveedores(condicion){
        const { user } = this.state
        const loading = 'loadingProveedores'
        return fetch(`${data.urlapi}/proveedores/list-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            } else if(Array.isArray(res) !== false){
                let proveedores = res.map(p => ({ value: p._id, label: p.titulo }))
                proveedores.unshift({ value:'', label: "Ninguno" })
                this.setState({ proveedores })
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return this.setState({ [loading]: false })
        })
    }

    async getVehiculo(id){
        const { user } = this.state
        let loading = 'creandoVehiculo'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/conductores?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            return this.setState({ nuevoconductor: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleChangeVehiculo(e){
        const { name, value } = e.target
        const { nuevoconductor } = this.state
        if(name === 'rut'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else if(name === 'frecuencytracking'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else {
            nuevoconductor[name] = value
        }
        return this.setState({ nuevoconductor })
    }

    handleChangeChecked(checked){
        return this.setState({ show_password: checked })
    }

    editarVehiculo(){
        const { nuevoconductor, user } = this.state
        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'patente', label: 'Patente' },
            { value:'phone', label: 'Teléfono' }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoconductor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

        const loading = 'creandoVehiculo'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/conductores`,{
            method:'PUT',
            body: JSON.stringify( nuevoconductor ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    return window.location = '/vehiculos'
                }, 500);
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    sinResultados() {
        return <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img src={`${host}/images/pedidos.png`} style={{ width: "50%" }} />
        <h4>No hay datos suficientes para visualizar esta sección</h4>
        </Col>
        </Row>
    }

    accionesGeocerca(){
        const { geocercas } = this.state
        if(geocercas.length < 1) return false

        return <div>
            <Button variant="success" size="sm" className='mb-3 shadow-sm'><b>GUARDAR CAMBIOS</b></Button>
        </div>
    }

    mostrarGeocercas(p){
        const { geocercas } = this.state
        
        return <div>
            <h6>{geocercas.length} Geocercas</h6>
            {this.accionesGeocerca()}
            {
                geocercas.map((geo,igeo) => {
                    return <div key={`geo-${igeo}`}>
                        <Row>
                        <Col md={6} className='mb-3'>
                            <label className='form-control-label'>Título</label>
                            <input name="titulo" className='form-control'/>
                        </Col >
                        <Col md={6} className='mb-3'>
                            <label className='form-control-label'>Tipo</label>
                            <select className="form-control" name="status" >
                                <option value=''>Seleccione</option>
                                <option value='disponible'>Zona disponibilidad</option>
                                <option value='restringido'>Zona restringida</option>
                            </select>
                        </Col >
                        </Row>
                        </div>
                })
            }
        </div>
    }

    onPolygonComplete(p){
        const { geocercas } = this.state
        geocercas.unshift(p)
        return this.setState({geocercas})
    }
    formularioVehiculo(){
        const { nuevoconductor, creandoVehiculo, proveedores, loadingProveedores } = this.state
        if(creandoVehiculo === true) return <Spinner animation='border' />
        if(Object.keys(nuevoconductor).length < 2) return this.sinResultados()


        let default_proveedor = false
        const i = proveedores.findIndex(p => p.value === nuevoconductor.id_proveedor)
        if(i > -1) default_proveedor = proveedores[i]
        return <div>
            <Row>
            <Col md={12}>
                <h5 style={{fontWeight:700}}><i className="fa-solid fa-id-card"></i> Datos del conductor</h5>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Conductor Nombres</label>
                <input name="nombres" className='form-control shadow-sm' value={nuevoconductor.nombres}  onChange={this.handleChangeVehiculo}/>
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Conductor Apelidos</label>
                <input name="apellidos" className='form-control shadow-sm' value={nuevoconductor.apellidos} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Conductor Rut</label>
                <input name="rut" className='form-control shadow-sm' value={nuevoconductor.rut} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mbd-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Contraseña APP</label>
                <input name="password" className='form-control shadow-sm' value={nuevoconductor.password} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={4} className='mb-4'>
                <label className='form-control-label' style={{fontWeight:700}}>Móvil Whatsapp</label>
                {/* <input name="phone" className='form-control' value={nuevoconductor.phone} placeholder='+5612341234' onChange={this.handleChangeVehiculo} /> */}
                <div className='row'>
                <div className='col-md-4 pr-1'>
                                                <select className='form-control shadow-sm' defaultValue="" name="pais_code" value={nuevoconductor.pais_code} onChange={this.handleChangeVehiculo}>
                                                    <option value="">Código Pais</option>
                                                    <option data-countrycode="CL" value="56">Chile (+56)</option>
                                                    <option data-countrycode="BR" value="55">Brazil (+55)</option>
                                                    <optgroup label="Otros países">
                                                        <option data-countrycode="DZ" value="213">Algeria (+213)</option>
                                                        <option data-countrycode="AD" value="376">Andorra (+376)</option>
                                                        <option data-countrycode="AO" value="244">Angola (+244)</option>
                                                        <option data-countrycode="AI" value="1264">Anguilla (+1264)</option>
                                                        <option data-countrycode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                                                        <option data-countrycode="AR" value="54">Argentina (+54)</option>
                                                        <option data-countrycode="AM" value="374">Armenia (+374)</option>
                                                        <option data-countrycode="AW" value="297">Aruba (+297)</option>
                                                        <option data-countrycode="AU" value="61">Australia (+61)</option>
                                                        <option data-countrycode="AT" value="43">Austria (+43)</option>
                                                        <option data-countrycode="AZ" value="994">Azerbaijan (+994)</option>
                                                        <option data-countrycode="BS" value="1242">Bahamas (+1242)</option>
                                                        <option data-countrycode="BH" value="973">Bahrain (+973)</option>
                                                        <option data-countrycode="BD" value="880">Bangladesh (+880)</option>
                                                        <option data-countrycode="BB" value="1246">Barbados (+1246)</option>
                                                        <option data-countrycode="BY" value="375">Belarus (+375)</option>
                                                        <option data-countrycode="BE" value="32">Belgium (+32)</option>
                                                        <option data-countrycode="BZ" value="501">Belize (+501)</option>
                                                        <option data-countrycode="BJ" value="229">Benin (+229)</option>
                                                        <option data-countrycode="BM" value="1441">Bermuda (+1441)</option>
                                                        <option data-countrycode="BT" value="975">Bhutan (+975)</option>
                                                        <option data-countrycode="BO" value="591">Bolivia (+591)</option>
                                                        <option data-countrycode="BA" value="387">Bosnia Herzegovina (+387)</option>
                                                        <option data-countrycode="BW" value="267">Botswana (+267)</option>
                                                        <option data-countrycode="BN" value="673">Brunei (+673)</option>
                                                        <option data-countrycode="BG" value="359">Bulgaria (+359)</option>
                                                        <option data-countrycode="BF" value="226">Burkina Faso (+226)</option>
                                                        <option data-countrycode="BI" value="257">Burundi (+257)</option>
                                                        <option data-countrycode="KH" value="855">Cambodia (+855)</option>
                                                        <option data-countrycode="CM" value="237">Cameroon (+237)</option>
                                                        <option data-countrycode="CA" value="1">Canada (+1)</option>
                                                        <option data-countrycode="CV" value="238">Cape Verde Islands (+238)</option>
                                                        <option data-countrycode="KY" value="1345">Cayman Islands (+1345)</option>
                                                        <option data-countrycode="CF" value="236">Central African Republic (+236)</option>
                                                        <option data-countrycode="CN" value="86">China (+86)</option>
                                                        <option data-countrycode="CO" value="57">Colombia (+57)</option>
                                                        <option data-countrycode="KM" value="269">Comoros (+269)</option>
                                                        <option data-countrycode="CG" value="242">Congo (+242)</option>
                                                        <option data-countrycode="CK" value="682">Cook Islands (+682)</option>
                                                        <option data-countrycode="CR" value="506">Costa Rica (+506)</option>
                                                        <option data-countrycode="HR" value="385">Croatia (+385)</option>
                                                        <option data-countrycode="CU" value="53">Cuba (+53)</option>
                                                        <option data-countrycode="CY" value="90392">Cyprus North (+90392)</option>
                                                        <option data-countrycode="CY" value="357">Cyprus South (+357)</option>
                                                        <option data-countrycode="CZ" value="42">Czech Republic (+42)</option>
                                                        <option data-countrycode="DK" value="45">Denmark (+45)</option>
                                                        <option data-countrycode="DJ" value="253">Djibouti (+253)</option>
                                                        <option data-countrycode="DM" value="1809">Dominica (+1809)</option>
                                                        <option data-countrycode="DO" value="1809">Dominican Republic (+1809)</option>
                                                        <option data-countrycode="EC" value="593">Ecuador (+593)</option>
                                                        <option data-countrycode="EG" value="20">Egypt (+20)</option>
                                                        <option data-countrycode="SV" value="503">El Salvador (+503)</option>
                                                        <option data-countrycode="GQ" value="240">Equatorial Guinea (+240)</option>
                                                        <option data-countrycode="ER" value="291">Eritrea (+291)</option>
                                                        <option data-countrycode="EE" value="372">Estonia (+372)</option>
                                                        <option data-countrycode="ET" value="251">Ethiopia (+251)</option>
                                                        <option data-countrycode="FK" value="500">Falkland Islands (+500)</option>
                                                        <option data-countrycode="FO" value="298">Faroe Islands (+298)</option>
                                                        <option data-countrycode="FJ" value="679">Fiji (+679)</option>
                                                        <option data-countrycode="FI" value="358">Finland (+358)</option>
                                                        <option data-countrycode="FR" value="33">France (+33)</option>
                                                        <option data-countrycode="GF" value="594">French Guiana (+594)</option>
                                                        <option data-countrycode="PF" value="689">French Polynesia (+689)</option>
                                                        <option data-countrycode="GA" value="241">Gabon (+241)</option>
                                                        <option data-countrycode="GM" value="220">Gambia (+220)</option>
                                                        <option data-countrycode="GE" value="7880">Georgia (+7880)</option>
                                                        <option data-countrycode="DE" value="49">Germany (+49)</option>
                                                        <option data-countrycode="GH" value="233">Ghana (+233)</option>
                                                        <option data-countrycode="GI" value="350">Gibraltar (+350)</option>
                                                        <option data-countrycode="GR" value="30">Greece (+30)</option>
                                                        <option data-countrycode="GL" value="299">Greenland (+299)</option>
                                                        <option data-countrycode="GD" value="1473">Grenada (+1473)</option>
                                                        <option data-countrycode="GP" value="590">Guadeloupe (+590)</option>
                                                        <option data-countrycode="GU" value="671">Guam (+671)</option>
                                                        <option data-countrycode="GT" value="502">Guatemala (+502)</option>
                                                        <option data-countrycode="GN" value="224">Guinea (+224)</option>
                                                        <option data-countrycode="GW" value="245">Guinea - Bissau (+245)</option>
                                                        <option data-countrycode="GY" value="592">Guyana (+592)</option>
                                                        <option data-countrycode="HT" value="509">Haiti (+509)</option>
                                                        <option data-countrycode="HN" value="504">Honduras (+504)</option>
                                                        <option data-countrycode="HK" value="852">Hong Kong (+852)</option>
                                                        <option data-countrycode="HU" value="36">Hungary (+36)</option>
                                                        <option data-countrycode="IS" value="354">Iceland (+354)</option>
                                                        <option data-countrycode="IN" value="91">India (+91)</option>
                                                        <option data-countrycode="ID" value="62">Indonesia (+62)</option>
                                                        <option data-countrycode="IR" value="98">Iran (+98)</option>
                                                        <option data-countrycode="IQ" value="964">Iraq (+964)</option>
                                                        <option data-countrycode="IE" value="353">Ireland (+353)</option>
                                                        <option data-countrycode="IL" value="972">Israel (+972)</option>
                                                        <option data-countrycode="IT" value="39">Italy (+39)</option>
                                                        <option data-countrycode="JM" value="1876">Jamaica (+1876)</option>
                                                        <option data-countrycode="JP" value="81">Japan (+81)</option>
                                                        <option data-countrycode="JO" value="962">Jordan (+962)</option>
                                                        <option data-countrycode="KZ" value="7">Kazakhstan (+7)</option>
                                                        <option data-countrycode="KE" value="254">Kenya (+254)</option>
                                                        <option data-countrycode="KI" value="686">Kiribati (+686)</option>
                                                        <option data-countrycode="KP" value="850">Korea North (+850)</option>
                                                        <option data-countrycode="KR" value="82">Korea South (+82)</option>
                                                        <option data-countrycode="KW" value="965">Kuwait (+965)</option>
                                                        <option data-countrycode="KG" value="996">Kyrgyzstan (+996)</option>
                                                        <option data-countrycode="LA" value="856">Laos (+856)</option>
                                                        <option data-countrycode="LV" value="371">Latvia (+371)</option>
                                                        <option data-countrycode="LB" value="961">Lebanon (+961)</option>
                                                        <option data-countrycode="LS" value="266">Lesotho (+266)</option>
                                                        <option data-countrycode="LR" value="231">Liberia (+231)</option>
                                                        <option data-countrycode="LY" value="218">Libya (+218)</option>
                                                        <option data-countrycode="LI" value="417">Liechtenstein (+417)</option>
                                                        <option data-countrycode="LT" value="370">Lithuania (+370)</option>
                                                        <option data-countrycode="LU" value="352">Luxembourg (+352)</option>
                                                        <option data-countrycode="MO" value="853">Macao (+853)</option>
                                                        <option data-countrycode="MK" value="389">Macedonia (+389)</option>
                                                        <option data-countrycode="MG" value="261">Madagascar (+261)</option>
                                                        <option data-countrycode="MW" value="265">Malawi (+265)</option>
                                                        <option data-countrycode="MY" value="60">Malaysia (+60)</option>
                                                        <option data-countrycode="MV" value="960">Maldives (+960)</option>
                                                        <option data-countrycode="ML" value="223">Mali (+223)</option>
                                                        <option data-countrycode="MT" value="356">Malta (+356)</option>
                                                        <option data-countrycode="MH" value="692">Marshall Islands (+692)</option>
                                                        <option data-countrycode="MQ" value="596">Martinique (+596)</option>
                                                        <option data-countrycode="MR" value="222">Mauritania (+222)</option>
                                                        <option data-countrycode="YT" value="269">Mayotte (+269)</option>
                                                        <option data-countrycode="MX" value="52">Mexico (+52)</option>
                                                        <option data-countrycode="FM" value="691">Micronesia (+691)</option>
                                                        <option data-countrycode="MD" value="373">Moldova (+373)</option>
                                                        <option data-countrycode="MC" value="377">Monaco (+377)</option>
                                                        <option data-countrycode="MN" value="976">Mongolia (+976)</option>
                                                        <option data-countrycode="MS" value="1664">Montserrat (+1664)</option>
                                                        <option data-countrycode="MA" value="212">Morocco (+212)</option>
                                                        <option data-countrycode="MZ" value="258">Mozambique (+258)</option>
                                                        <option data-countrycode="MN" value="95">Myanmar (+95)</option>
                                                        <option data-countrycode="NA" value="264">Namibia (+264)</option>
                                                        <option data-countrycode="NR" value="674">Nauru (+674)</option>
                                                        <option data-countrycode="NP" value="977">Nepal (+977)</option>
                                                        <option data-countrycode="NL" value="31">Netherlands (+31)</option>
                                                        <option data-countrycode="NC" value="687">New Caledonia (+687)</option>
                                                        <option data-countrycode="NZ" value="64">New Zealand (+64)</option>
                                                        <option data-countrycode="NI" value="505">Nicaragua (+505)</option>
                                                        <option data-countrycode="NE" value="227">Niger (+227)</option>
                                                        <option data-countrycode="NG" value="234">Nigeria (+234)</option>
                                                        <option data-countrycode="NU" value="683">Niue (+683)</option>
                                                        <option data-countrycode="NF" value="672">Norfolk Islands (+672)</option>
                                                        <option data-countrycode="NP" value="670">Northern Marianas (+670)</option>
                                                        <option data-countrycode="NO" value="47">Norway (+47)</option>
                                                        <option data-countrycode="OM" value="968">Oman (+968)</option>
                                                        <option data-countrycode="PW" value="680">Palau (+680)</option>
                                                        <option data-countrycode="PA" value="507">Panama (+507)</option>
                                                        <option data-countrycode="PG" value="675">Papua New Guinea (+675)</option>
                                                        <option data-countrycode="PY" value="595">Paraguay (+595)</option>
                                                        <option data-countrycode="PE" value="51">Peru (+51)</option>
                                                        <option data-countrycode="PH" value="63">Philippines (+63)</option>
                                                        <option data-countrycode="PL" value="48">Poland (+48)</option>
                                                        <option data-countrycode="PT" value="351">Portugal (+351)</option>
                                                        <option data-countrycode="PR" value="1787">Puerto Rico (+1787)</option>
                                                        <option data-countrycode="QA" value="974">Qatar (+974)</option>
                                                        <option data-countrycode="RE" value="262">Reunion (+262)</option>
                                                        <option data-countrycode="RO" value="40">Romania (+40)</option>
                                                        <option data-countrycode="RU" value="7">Russia (+7)</option>
                                                        <option data-countrycode="RW" value="250">Rwanda (+250)</option>
                                                        <option data-countrycode="SM" value="378">San Marino (+378)</option>
                                                        <option data-countrycode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                                                        <option data-countrycode="SA" value="966">Saudi Arabia (+966)</option>
                                                        <option data-countrycode="SN" value="221">Senegal (+221)</option>
                                                        <option data-countrycode="CS" value="381">Serbia (+381)</option>
                                                        <option data-countrycode="SC" value="248">Seychelles (+248)</option>
                                                        <option data-countrycode="SL" value="232">Sierra Leone (+232)</option>
                                                        <option data-countrycode="SG" value="65">Singapore (+65)</option>
                                                        <option data-countrycode="SK" value="421">Slovak Republic (+421)</option>
                                                        <option data-countrycode="SI" value="386">Slovenia (+386)</option>
                                                        <option data-countrycode="SB" value="677">Solomon Islands (+677)</option>
                                                        <option data-countrycode="SO" value="252">Somalia (+252)</option>
                                                        <option data-countrycode="ZA" value="27">South Africa (+27)</option>
                                                        <option data-countrycode="ES" value="34">Spain (+34)</option>
                                                        <option data-countrycode="LK" value="94">Sri Lanka (+94)</option>
                                                        <option data-countrycode="SH" value="290">St. Helena (+290)</option>
                                                        <option data-countrycode="KN" value="1869">St. Kitts (+1869)</option>
                                                        <option data-countrycode="SC" value="1758">St. Lucia (+1758)</option>
                                                        <option data-countrycode="SD" value="249">Sudan (+249)</option>
                                                        <option data-countrycode="SR" value="597">Suriname (+597)</option>
                                                        <option data-countrycode="SZ" value="268">Swaziland (+268)</option>
                                                        <option data-countrycode="SE" value="46">Sweden (+46)</option>
                                                        <option data-countrycode="CH" value="41">Switzerland (+41)</option>
                                                        <option data-countrycode="SI" value="963">Syria (+963)</option>
                                                        <option data-countrycode="TW" value="886">Taiwan (+886)</option>
                                                        <option data-countrycode="TJ" value="7">Tajikstan (+7)</option>
                                                        <option data-countrycode="TH" value="66">Thailand (+66)</option>
                                                        <option data-countrycode="TG" value="228">Togo (+228)</option>
                                                        <option data-countrycode="TO" value="676">Tonga (+676)</option>
                                                        <option data-countrycode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                                                        <option data-countrycode="TN" value="216">Tunisia (+216)</option>
                                                        <option data-countrycode="TR" value="90">Turkey (+90)</option>
                                                        <option data-countrycode="TM" value="7">Turkmenistan (+7)</option>
                                                        <option data-countrycode="TM" value="993">Turkmenistan (+993)</option>
                                                        <option data-countrycode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                                                        <option data-countrycode="TV" value="688">Tuvalu (+688)</option>
                                                        <option data-countrycode="UG" value="256">Uganda (+256)</option>
                                                        <option data-countrycode="UA" value="380">Ukraine (+380)</option>
                                                        <option data-countrycode="AE" value="971">United Arab Emirates (+971)</option>
                                                        <option data-countrycode="UY" value="598">Uruguay (+598)</option>
                                                        <option data-countrycode="UZ" value="7">Uzbekistan (+7)</option>
                                                        <option data-countrycode="VU" value="678">Vanuatu (+678)</option>
                                                        <option data-countrycode="VA" value="379">Vatican City (+379)</option>
                                                        <option data-countrycode="VE" value="58">Venezuela (+58)</option>
                                                        <option data-countrycode="VN" value="84">Vietnam (+84)</option>
                                                        <option data-countrycode="VG" value="84">Virgin Islands - British (+1284)</option>
                                                        <option data-countrycode="VI" value="84">Virgin Islands - US (+1340)</option>
                                                        <option data-countrycode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                                                        <option data-countrycode="YE" value="969">Yemen (North)(+969)</option>
                                                        <option data-countrycode="YE" value="967">Yemen (South)(+967)</option>
                                                        <option data-countrycode="ZM" value="260">Zambia (+260)</option>
                                                        <option data-countrycode="ZW" value="263">Zimbabwe (+263)</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                            <div className='col-md-8 pl-0'>
                                                <input
                                                    placeholder="Ingresa el número"
                                                    className='form-control shadow-sm'
                                                    type="tel"
                                                    name="phone"
                                                    value={nuevoconductor.phone}
                                                    onChange={this.handleChangeVehiculo} />
                                            </div>
                                        </div>
            </Col>
            <Col md={12}>
                <h5 style={{fontWeight:700}}><i className="fa-solid fa-car"></i> Datos del vehículo</h5>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Modelo Vehículo</label>
                <input name="modelo" className='form-control shadow-sm' value={nuevoconductor.modelo} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'style={{fontWeight:700}}>Año Vehículo</label>
                <input name="ano" type="number" className='form-control shadow-sm' value={nuevoconductor.ano} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Patente Vehículo</label>
                <input name="patente" className='form-control shadow-sm' value={nuevoconductor.patente} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={4} className='mb-3'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Categoría</label>
                <Select
                                    isLoading={loadingProveedores} 
                                    loadingMessage={()=>'Cargando información'}
                                    placeholder="Selecciona una opción"
                                    noOptionsMessage={()=>'Sin opciones'}
                                    onChange={this.handleChangeProveedor}
                                    defaultValue={default_proveedor}
                                    options={proveedores}
                                    className='shadow-sm'
                                />
            </Col>
            
            <Col md={2} className='mb-4'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Estado</label>
                <select className="form-control shadow-sm" name="status" value={nuevoconductor.status} onChange={this.handleChangeVehiculo}>
                    <option value='active'>Activo</option>
                    <option value='inactive'>Inactivo</option>
                </select>
            </Col>

            <Col md={12}>
                <h5 style={{fontWeight:700}}><i className="fa-solid fa-gas-pump"></i> Rendimiento</h5>
            </Col>
            <Col md={3} className='mb-3'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Combustible</label>
                <select className="form-control shadow-sm" name="status" value={nuevoconductor.status} onChange={this.handleChangeVehiculo}>
                    <option value=''>Seleccione</option>
                    <option value='gasolina'>Gasolina</option>
                    <option value='diesel'>Diesel</option>
                    <option value='electrico'>Eléctrico</option>
                    <option value='gas'>Gas</option>
                </select>
            </Col>
            <Col md={3} className='mb-3'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Unidad de medida combustible</label>
                <select className="form-control shadow-sm" name="unidad_combustible" value={nuevoconductor.unidad_combustible} onChange={this.handleChangeVehiculo}>
                    <option value=''>Seleccione</option>
                    <option value='litro'>Litro</option>
                    <option value='porcentaje'>Porcentaje {"(Bateria)"}</option>
                    <option value='metros_cubicos'>Metros cúbicos</option>
                    <option value='gas'>Gas</option>
                </select>
            </Col>
            <Col md={3} className='mb-3'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Múltiplo por u.m. combustible</label>
                <input name="multiplo_um_combustible" className='form-control shadow-sm' type="number" value={nuevoconductor.multiplo_um_combustible} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={3} className='mb-4'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Kilómetros por u.m. combustible</label>
                <input name="peso" className='form-control shadow-sm' type="number" value={nuevoconductor.peso} onChange={this.handleChangeVehiculo} />
            </Col>
            
            {/* <Col md={12} className='mb-3'>
                <h3>Capacidad de carga / traslado por ruta</h3>
                <p>Esta capacidad está determinada para el modelo de planes delimitados por capacidad de carga, en vez de por ejemplo: por tiempo en ruta.</p>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Peso en Kg.</label>
                <input name="peso" className='form-control' type="number" value={nuevoconductor.peso} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Cantidad de puntos.</label>
                <input name="cap_puntos" className='form-control' type="number" value={nuevoconductor.cap_puntos} onChange={this.handleChangeVehiculo} />
            </Col>

            <Col md={12} className='mb-3'>
                <h3>Horario de trabajo</h3>
                <p>Este horario está determinado para que al crear planes, si seleccionas la opción de respetar horarios individuales de trabajo, en caso de tener; se limina la ruta.</p>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Desde.</label>
                <input name="horario_desde" className='form-control' type="time" value={nuevoconductor.horario_desde} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Hasta.</label>
                <input name="horario_hasta" className='form-control' type="time" value={nuevoconductor.horario_hasta} onChange={this.handleChangeVehiculo} />
            </Col> */}

            </Row>
                <div className='d-flex justify-content-end align-items-center'>
                    <Button variant='danger' className='text-white shadow-sm mr-2' size='sm' onClick={() => this.solicitarEliminar()}><b>ELIMINAR</b></Button>
                    <Button className='shadow-sm' size="sm" variant='success' onClick={()=>this.editarVehiculo()}><b>GUARDAR CAMBIOS</b></Button>
                </div>
            
            <hr />
            <h3 style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }}>Campos personalizados</h3>
            <CamposPersonalizadosRelacionador id_target={nuevoconductor._id} condicion_default={{ module: "vehiculo" }}  />
            <hr />
            <Row>
                <Col md={8}><RastreoCargaVehiculo condicion_default={{ id_target: nuevoconductor._id }} id_target={nuevoconductor._id} /> </Col>
                <Col md={4}><EtiquetasRelaciones id_target={nuevoconductor._id} tipo_destino="vehiculo" tipo_origen="etiquetas"/></Col>
            </Row>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }

    async confirmarEliminado(){
        const { user } = this.state
        const loading = 'creandoVehiculo'
        this.setState({ [loading]: false })
        return fetch(`${data.urlapi}/conductores?id=${this.state.id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = `/${rutas.vehiculos.slug}`
                }, 1000);
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return this.setState({ [loading]: false })
        })
    }

    solicitarEliminar() {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar este recurso, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.confirmarEliminado()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }
    
    render(){
        return(
            <div className="fluid text-primary">
                <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/vehiculos">Vehículos</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col md={6}><h4 style={{fontWeight:700}}><i className="fa-solid fa-pen-to-square"></i> Editar vehículo </h4></Col>
            {/* <Col md={6} className='text-right'><Button variant='ghost' className='text-danger' size='sm' onClick={() => this.solicitarEliminar()}>ELIMINAR</Button> </Col> */}
        </Row>
        <Card >
            <Card.Body>
            {this.formularioVehiculo()}
            </Card.Body>
        </Card>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VehiculosEditar));