import { useState } from "react"
import { Accordion, Button, Card, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap"
import { toast } from "react-toastify"
import { urlapi } from "../../lib/backend/data"
import { getDireccion, getDireccion1, getDireccion2 } from "../../lib/helpers/pedidos/pedidos"
import MostrarGeoDatos from "./box_geo_datos"
import { cerrarSesion } from "../../redux/actions/session"
import { useDispatch } from "react-redux"

const BoxEditarGeoDatosOrden = (props) => {
    const [ pedidos, setPedidos ] = useState(props.pedidos ? props.pedidos : [])
    const sub_titulo = props.sub_titulo ? props.sub_titulo : "Órdenes sin coordenadas válidas, estas órdenes no se podrán reasignar por comuna"
    const token = props.token ? props.token : false
    const [ idLoading, setIdLoading ] = useState(false)
    const dispatch = useDispatch()

    if(!pedidos) return false
    if(Array.isArray(pedidos) !== true) return false
    if(pedidos.length < 1) return false

    const submitChanges = (e,id) => {
        e.preventDefault()
        geolocalizar(id)
    }

    const geolocalizar = async (id) => {
        if(!token) return false
        setIdLoading(id)
        const posicion = pedidos.findIndex(p => p._id === id)
        return fetch(`${urlapi}/ordenes/orden/re-geolocalizar`,{
            method:'POST',
            body: JSON.stringify({
                id,
                direccion: pedidos[posicion].orden.billing.address_1,
                direccion2: pedidos[posicion].orden.billing.address_2,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
          console.log(res)
          if(!res){
            toast.error("Sin respuesta")
          } else if(res.errorMessage){
            toast.error(res.errorMessage)
          } else if(res._id){
            const i = pedidos.findIndex(p => p._id === id)
            if(i > -1){
                if(res.orden){
                    if(typeof res.orden === "object"){
                        if(res.orden.geo_datos){
                            if(typeof res.orden.geo_datos === "object"){
                                if(!res.orden.geo_datos.level2){
                                    toast.warn("Se obtuvieron datos geográficos parciales,")
                                } else {
                                    toast.success("Datos geográficos obtenidos")
                                }
                                pedidos[i].orden.geo_datos = res.orden.geo_datos
                                setPedidos(prev => [...[], ...pedidos])
                            }
                        } else {
                            toast.error("No se obtuvieron datos geográficos, revisa que estén correctos")
                        }
                    }
                }
            }
          }
          return setIdLoading(false)
        })
        .catch(error => {
          toast.error(error.message)
          return setIdLoading(false)
        })
    }

    const handleChange = (e,id) => {
        const { value } = e.target
        const posicion = pedidos.findIndex(p => p._id === id)
        pedidos[posicion].orden.billing.address_1 = value
        return setPedidos(prev => [...[], ...pedidos])
    }
    
    const handleChange2 = (e,id) => {
        const { value } = e.target
        const posicion = pedidos.findIndex(p => p._id === id)
        pedidos[posicion].orden.billing.address_2 = value
        return setPedidos(prev => [...[], ...pedidos])
    }
    
    return <div>
        <Accordion>
                        <Card className="p-0 mb-3 text-dark" shadow >
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={`estado`}>
                            <div className='p-3'>
                            <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>
                                Toca para ver
                              </Tooltip>
                            }
                            >
                            <h5 className='text-danger hover'>{pedidos.length} {sub_titulo}</h5>
                            </OverlayTrigger>
                            </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`estado`}>
                            <Card.Body>
                            {
                                pedidos.map((p,ipedido) => {
                                    let direccion = getDireccion1(p)
                                    let direccion2 = getDireccion2(p)
                                    return <div>
                                        { p.pedido ? <h5 className="mb-0">ORDEN {p.pedido}</h5> : false }
                                        <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                        <Tooltip>
                                            Edita la dirección y presiona enter para geolocalizar
                                        </Tooltip>
                                        }
                                        >
                                            {
                                                idLoading === p._id ? <Spinner animation="border" /> : <form onSubmit={(e) => submitChanges(e,p._id)}>
                                                    <label className='form-control-label'>Dirección</label>
                                                    <input name="direccion1" className='form-control mb-2' value={direccion} onChange={(e) => handleChange(e,p._id)} />
                                                    <label className='form-control-label'>Observaciones de la dirección</label>
                                                    <input name="direccion2" className='form-control mb-2' value={direccion2} onChange={(e) => handleChange2(e,p._id)} />
                                                    <Button size="sm" variant="success" type="submit" style={{ width: "100%" }}>GUARDAR</Button>
                                                </form>
                                            }
                                        </OverlayTrigger>
                                        <MostrarGeoDatos pedido={p} />
                                        { pedidos.length === (ipedido+1) ? false : <hr /> }
                                    </div>
                                })
                            }
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
        </Accordion>
    </div>
}

export default BoxEditarGeoDatosOrden