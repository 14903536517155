import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Tab, Tabs, Navbar, Nav } from 'react-bootstrap'
import Header from '../Header'
import { key_local_storage_user, urlapi } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import OrdenesListado from '../../subComponents/ordenes/listado'
import OrdenesListadoV2 from '../../subComponents/ordenes/listadov2'
import { cerrarSesion } from '../../redux/actions/session'
import Envios from '../Envios'
import TituloTab from '../../subComponents/general/titulo_tab'
import { FaBoxArchive, FaBoxesPacking, FaClockRotateLeft, FaPeopleCarryBox } from 'react-icons/fa6'
import { RiTruckLine } from 'react-icons/ri'
import { FaFileExcel, FaPlusCircle } from 'react-icons/fa'
import { rutas } from '../../lib/routes/routes'
import { toast } from 'react-toastify'
import PantallaCargando from '../../subComponents/general/pantalla_cargando'
import OrdenesGestionadaListado from '../../subComponents/ordenes/listado_gestionadas'
import { FaTruckRampBox } from "react-icons/fa6";

class Ordenes extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loading_conductores: true,
            conductores: []
        }
    }

    componentDidMount(){
        this.obtenerProveedores()
    }

    async obtenerProveedores(){
        const loading = "loading_conductores"
        this.setState({ [loading]: true })
        return fetch(`${urlapi}/conductores/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                // toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                this.setState({ conductores: res })
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return this.setState({ [loading]: false })
        })
    }
    render(){
        const { user, loading_conductores, conductores } = this.state
        if(loading_conductores) return <PantallaCargando />

        const condicion_pendientes = { 
            label_courrier_generated: { $nin: [true] }, 
            label_courrier_printed: { $nin: [true] }, 
            manifest: { $nin: [true] }, 
            idconductor: { $in: [ false, "", null] } 
        }
        const condicion_flota = {
            idconductor: { $in: conductores.map(co => co._id).filter(id => id) }
        }
        
        const condicion_gestionadas = {
            idconductor: { $nin: [ ...conductores.map(co => co._id).filter(id => id), ...['', null]] }
        }
        const condicion_todos = {

        }

        const estilo_icon           = { verticalAlign: "middle" }
        const iconSize              = 20
        const show_menu_top = <div>
            <h4>Órdenes</h4>
        <Navbar bg="light" variant="light" className='mb-3'>
                {/* <Navbar.Brand >Órdenes</Navbar.Brand> */}
                <Nav className="mr-auto">
                    <Nav.Link ><Link to={`/${rutas.ordenes_crear.slug}`}><FaPlusCircle /> CREAR NUEVO</Link> </Nav.Link>
                    <Nav.Link ><Link to={`/${rutas.rutas_importar_excel.slug}`}><FaFileExcel /> IMPORTAR EXCEL</Link> </Nav.Link>
                    <Nav.Link ><Link to={`/${rutas.rutas_importar_avanzado.slug}`}><FaFileExcel /> IMPORTADOR AVANZADO</Link> </Nav.Link>
                </Nav>
                {/* <Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                    <Button variant="outline-info">Search</Button>
                </Form> */}
                </Navbar>
        </div>
        
        return <div>
            {show_menu_top}
            <Tabs defaultActiveKey="todos" >
                <Tab eventKey="todos" className='mt-3' title={<TituloTab icon={<FaBoxArchive size={iconSize} style={estilo_icon} />} titulo="Todas" /> }>
                    <OrdenesListadoV2 showAddButton={false} hideHeader={true} token={user.tokenSession} showExporter={true} condicion_default={condicion_todos} />
                </Tab>
                <Tab eventKey="pendientes" className='mt-3' title={<TituloTab icon={<FaClockRotateLeft size={iconSize} style={estilo_icon} />} titulo="Pendientes" /> }>
                    <OrdenesListadoV2 showAddButton={false} hideHeader={true} token={user.tokenSession} showExporter={true} condicion_default={condicion_pendientes} />
                </Tab>
                <Tab eventKey="gestionadas" className='mt-3' title={<TituloTab icon={<RiTruckLine size={iconSize} style={estilo_icon} />} titulo="De mi flota" /> }>
                    <OrdenesListadoV2 showAddButton={false} hideHeader={true} token={user.tokenSession} showExporter={true} condicion_default={condicion_flota} />
                </Tab>
                <Tab eventKey="proveedores" className='mt-3' title={<TituloTab icon={<FaPeopleCarryBox size={iconSize} style={estilo_icon} />} titulo="De proveedores" /> }>
                    <OrdenesListadoV2 showAddButton={false} hideHeader={true} token={user.tokenSession} showExporter={true} gestionadas={true} condicion_default={condicion_gestionadas} />
                </Tab>
                <Tab eventKey="courriers" className='mt-3' title={<TituloTab icon={<FaBoxesPacking size={iconSize} style={estilo_icon} />} titulo="De courriers" /> }>
                    <Envios />
                </Tab>
                <Tab eventKey="gestiones" className='mt-3' title={<TituloTab icon={<FaTruckRampBox size={iconSize} style={estilo_icon} />} titulo="Mis gestiones" /> }>
                    <OrdenesGestionadaListado showFilter={false} showExporter={false} gestionadas={true} />
                </Tab>
            </Tabs>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Ordenes);