import { useState } from 'react'
import { Badge, Button, ButtonGroup, Card, ListGroup, ListGroupItem, Modal } from 'react-bootstrap'
import { MdArrowForwardIos } from 'react-icons/md'
import { RiFileExcelLine } from 'react-icons/ri'
import * as XLSX from 'xlsx'

const InformacionEsquema = (props) => {
    const {
        titulo,
        esquema,
        extra_content,
        module
    } = props
    const titulo_doc = typeof titulo === "string" ? titulo : "Modelo"
    const [ showModal, setShowModal ] = useState(false)

    const handleDownload = () => {
        // Crear el archivo de Excel
        const wb = XLSX.utils.book_new();
        const wsData = [ esquema.filter(e => !e.denieUpload).map(e => e.label) ]
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
    
        // Convertir el libro de trabajo a un archivo binario
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        // Crear un Blob con los datos binarios
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    
        // Generar un objeto URL del Blob
        const url = URL.createObjectURL(blob);
    
        // Crear un enlace de descarga invisible y hacer clic en él
        const a = document.createElement('a');
        a.href = url;
        a.download = `${titulo_doc}.xlsx`;
        a.click();
    
        // Liberar el objeto URL
        URL.revokeObjectURL(url);
      };
    
      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };

      const mostrarInformacion = () => {
        setShowModal(true)
      }

      const mostrarModal = () => {
        return <Modal  show={showModal} onHide={() => setShowModal(false)} >
            <Modal.Header closeButton><h4 as="h2" className="mt-0 mb-0">Información del modelo excel</h4></Modal.Header>
            <div className='p-3'>
            <p className="mt-0 mb-3">Estos son las columnas que tu documento excel debe tener</p>
            {
                esquema.filter(e => !e.denieUpload).map(item => {
                    return <Card className="p-2 mb-2">
                      <p className='mb-0'>{item.label} {item.optionalUpload ? <Badge variant='light'>OPCIONAL</Badge> : <Badge variant="danger">OBLIGATORIO</Badge> }</p>
                      {item.info ? <div><p className='mb-0' style={{ fontWeight: "bold" }}>{item.info}</p> </div> : false}
                      </Card>
                })
            }
            {extra_content ? extra_content : false}
            </div>
        </Modal>
    }

      if(!esquema) return false

    return <div>
        {mostrarModal()}
        <ButtonGroup>
            <Button size="sm" variant='light' leftIcon={<RiFileExcelLine /> } onClick={() => handleDownload()} >DESCARGAR MODELO EXCEL</Button>
            <Button size="sm" variant='light' onClick={() => mostrarInformacion()}>INFORMACIÓN DEL MODELO</Button>
        </ButtonGroup>
    </div>
}

export default InformacionEsquema