import Map, { Layer, Marker, Popup, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import Pin from '../../subComponents/mapas/pin';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import { BsFillStopCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';

const MapaRutaAsignacion = (props) => {
    const [selectedMarker, setSelectedMarker] = useState(null);

    const {
        height,
        markers,
        pinType,
        resize,
        typeView
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const [ coleccion_ordenes, setColeccionOrdenes ] = useState({ type: 'FeatureCollection', features: []})
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const ajustarCentro = (puntos) => {
        if(zoomInit) return false
        if(puntos.length < 1) return false
        setZoomInit(true)
        const points = puntos.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }

    const armarGeoDatos = () => {
        const puntos = markers.map(ord => {
            return {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: [
                    ord.lng,
                    ord.lat
                ]
              }
            }
          }).filter(p => p)

          const newFeatures = {
            type: 'FeatureCollection',
            features: puntos
          }
          setColeccionOrdenes({ ...{}, ...newFeatures })
    }

      useEffect(() => {
          if(mapRef) if(mapRef.current) mapRef.current.resize()
            setTimeout(() => {
                ajustarCentro(marcadores.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
            }, 500)

            armarGeoDatos()
      }, [ resize ])


    const showPin = (color) => {
        const tipo = typeof pinType !== "undefined" ? pinType : false
        switch (tipo) {
            case "uber":
                return <FaCircle color={color} size={5} />
            default:
                return <Pin color={color} />
        }
    }

    if(typeView === "heatmap") {
        return <div>
            <Map
                ref={mapRef}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{width: "100%", height: height ? height : 600}}
                dragPan={false}
                scrollZoom={false}
                mapStyle="mapbox://styles/mapbox/dark-v9"
                mapboxAccessToken={mapbox_token}
            >
                <Source
                    key={"ajsfkn2"}
                    id={"geocerca._id2"}
                    type="geojson"
                    data={coleccion_ordenes}
                >
                    <Layer
                    id="heatmap-layer"
                    type="heatmap"
                    paint={{
                        'heatmap-weight': 1,
                        'heatmap-intensity': 0.5,
                        'heatmap-radius': 20,
                        'heatmap-color': [
                        'interpolate',
                        ['linear'],
                        ['heatmap-density'],
                        0,
                        'rgba(0, 0, 255, 0)',
                        0.2,
                        'royalblue',
                        0.4,
                        'cyan',
                        0.6,
                        'lime',
                        0.8,
                        'yellow',
                        1,
                        'red',
                        ],
                        'heatmap-opacity': 0.8,
                    }}
                    />

                </Source>
            </Map>
        </div>
    }

    return <div>
        <Map
            ref={mapRef}
            {...viewState}
            onMove={evt => setViewState(evt.viewState)}
            style={{width: "100%", height: height ? height : 600}}
            dragPan={false}
            scrollZoom={false}
            mapStyle="mapbox://styles/mapbox/light-v9"
            // mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={mapbox_token}
        >
            {
            marcadores.map(marker => {
                let color = ''

                if(!marker) return false
                if(marker.detalles?.estado_entrega?.color) color = marker.detalles?.estado_entrega?.color
                return <Marker
                key={marker._id}
                longitude={marker.lng}
                latitude={marker.lat}
                >
                    <div onClick={e => {
                        e.preventDefault();
                        setSelectedMarker(marker);
                    }}>
                        {showPin(color)}
                    </div>
                </Marker>
            })
            }

            {selectedMarker ? (
                <Popup
                latitude={selectedMarker.lat}
                longitude={selectedMarker.lng}
                closeButton={true}
                closeOnClick={false}
                onClose={() => setSelectedMarker(null)}
                anchor="right"
                className='p-1' 
                >
                    <div className='pt-2'>
                        <Link to={`/ordenes/${selectedMarker._id}`}><b>{selectedMarker.ref}</b></Link>
                    </div>
                </Popup>
            ) : null}
        </Map>
    </div>
}

export default MapaRutaAsignacion