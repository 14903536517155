import { useEffect, useState } from "react"
import { Alert, Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import data from '../../lib/backend/data'
import S3FileUpload from 'react-s3';
import { formatDate } from "../../lib/helpers/dates";
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";

const EmitirEstadoModalMasivoRuta = (props) => {
    const [ openModal, setOpenModal ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ estados, setEstados ] = useState([])
    const [ protocolos, setProtocolos ] = useState([])
    const [ subEstados, setSubEstados ] = useState([])
    const [ emitirestado, setEmitirEstado ] = useState(false)
    const [ subiendofoto, setSubiendofoto] = useState(false)
    const [ actualizando, setActualizando] = useState(false)
    const [ emitirsubestado, setSubEstado ] = useState(false)
    const token = props.token ? props.token : false
    const ruta = props.ruta ? props.ruta : false
    const estilo_boton = props.buttonStyle ? props.buttonStyle : "outline-primary"
    const dispatch = useDispatch()
    const configs3 = {
        dirName: "movil/",
        bucketName: "urrapp",
        region: "us-east-1",
        accessKeyId: 'AKIAW3FR56LCTXHZCW5T',
        secretAccessKey: '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',
    }

    useEffect(() => {
        obtenerEsquemaEstados()
    },[])

    const estadoEmitido = (data) => {
        if(props.onStatusEmited) props.onStatusEmited(data)
        return setOpenModal(false)
    }

    const obtenerEsquemaEstados = async () => {
        return fetch(`${data.urlapi}/ordenes/config-estados-dinamico?ruta=${ruta ? ruta._id : ''}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setProtocolos(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            setLoading(false)
            return toast.error(error.message)
        })
    }

    if(!ruta) return false

    const openModalEstado = () => {
        setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const cambiarStatusDespacho = (emitirestado) => {
        return setEmitirEstado(emitirestado)
    }

    const handleChangeImagen = (e) => {
        const i = e.target.getAttribute('posicion')
        if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')
        const archivo = e.target.files[0]
  
        setSubiendofoto(true)
        return S3FileUpload.uploadFile(archivo, configs3)
        .then(data => {
        if(!data.location) {
            toast.error('No pudimos cargar tu imagen')
            setSubiendofoto(false)
        }
        emitirsubestado.acciones[i].valores.push(({
          tipo: emitirsubestado.acciones[i].titulo,
          url: data.location,
          fechacreacion: formatDate(new Date())
      }))
        setSubEstado(emitirsubestado)
        return setSubiendofoto(false)
        })
        .catch(err => {
            setSubiendofoto(false)
            return toast.error('No pudimos cargar tu imagen')
        })
    }

    const onChangeAccionText = (e) => {
        const { value } = e.target
        const i = e.target.getAttribute('posicion');
        emitirsubestado.acciones[i].valor = value
        return setSubEstado(emitirsubestado)
    }

    const showComponentByAccion = (accion,i) => {

        if(accion.tipo_accion === 'campo-texto'){

            return <div className='mb-2'>
                    <label className="form-control-label d-block">{accion.titulo} {accion.required === true ? '* Obligatorio' : false} </label>
                    <input className='form-control' posicion={i} onChange={onChangeAccionText}  />
                </div>

        } else if(accion.tipo_accion === 'carga-imagenes'){

            return <div className='mb-2'>
                    <label className="form-control-label d-block">{accion.titulo} {accion.required === true ? '* Obligatorio' : false}</label>
                    <input  
                    type="file" 
                    name="file" 
                    id="file" 
                    className="form-control"
                    posicion={i}
                    onChange={handleChangeImagen} 
                    placeholder="Archivo de imagen" 
                    />
                    {
                            accion.valores.map((foto,i) => {
                                return <img className='mb-3 mr-3' key={foto.url} style={{ height: 70,}} src={foto.url} />
                            })
                    }
                </div>
        } else if(accion.tipo_accion === 'selector'){
            const items = accion.opciones.map(val => ({ label: val, value: val }))
            return <div className='mb-2'>
                    <label className="form-control-label d-block">{accion.titulo} {accion.required === true ? '* Obligatorio' : false}</label>
                    <select className="form-control" posicion={i} onChange={onChangeAccionText}>
                      <option value="">Seleccione</option>
                      {items.map((item,i) => <option key={`item-${i}`} value={item.val}>{item.label}</option> )}
                    </select>
                    </div>
        }
        return false

    }

    const confirmarEstado = async () => {
        // VERIFICAR CAMPOS DE FORMULARIO
        const verificar_formulario_completo = emitirsubestado.acciones.map(accion => {
            if(accion.tipo_accion === 'campo-texto' || accion.tipo_accion === 'selector' ){
                if(accion.required === true){
                    return accion.valor ? false : accion.titulo
                } else {
                    return false
                }
            } else {
                // IMAGENES
                if(accion.required === true){
                    return accion.valores.length > 0 ? false : accion.titulo
                } else {
                    return false
                }
            }
            return false
        })
  
        const campos_faltantes = verificar_formulario_completo.filter(camp => camp !== false ? camp : false)
        if(campos_faltantes.length > 0) return toast.error(`Los siguientes campos son obligatorios: ${campos_faltantes.join(', ')}`)
  
        let lat = '-33.458531'
        let lng = '-70.653753'
  
        const payload = {
            idpedido: "",
            lat, 
            lng, 
            idestado: emitirestado._id, 
            estado: emitirestado.titulo, 
            codigo_estado: emitirestado.codigo_estado,
            canal: 'sistema-web',
            tipo_creador: 'usuario-sistema',
            id_creador: '', 
            notificar_integraciones: emitirsubestado.notificar_integraciones ? emitirsubestado.notificar_integraciones : [],
            id_subestado: emitirsubestado._id, 
            subestado: emitirsubestado.titulo,
            codigo_subestado: emitirsubestado.codigo_estado,
            estado_color: emitirestado.color,
            publico: emitirsubestado.publico === true ? true : false,
            notificar_planel_flota: emitirsubestado.notificar_planel_flota === true ? true : false,
            finalizar: emitirsubestado.finalizar === true ? true : false,
            subestado_color: emitirsubestado.color,
            metadatos: emitirsubestado.acciones,
            whatsapp: emitirsubestado.whatsapp,
            email: emitirsubestado.email,
            }
        setActualizando(true)
        return fetch(`${data.urlapi}/flota/emitir-estado-masivo`, {
              method: 'POST',
              body: JSON.stringify({
                id_ruta: ruta._id,
                payload_estado: payload,
                propietario_pedidos: emitirestado.propietario
              }),
              headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
                }
            })
            .then(datos => datos.json())
            .then(pros => {
                console.log(pros)
                if(!pros){
                    toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                    return setActualizando(false)
                } else if(pros.errorMessage){
                    toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
                    return setActualizando(false)
                } else if(pros.success === true){
                    toast.success(`Operación Exitosa, Hemos confirmado la información exitosamente`)
                    setTimeout(() => {
                        estadoEmitido(pros)
                    }, 1000);
                }
                setEmitirEstado(false)
                setSubEstado(false)
                return setActualizando(false)
            })
            .catch(error => {
                toast.error('Error inesperado','Lo sentimos, no es posible cargar fotos en este momento, intenta de nuevo entrar aquí')
                return setActualizando(false)
            })
    }
    
    const mostrarSubEstados = (subestados) => {
        if(subestados.length < 1) return <h6>No hay sub estados disponibles para web</h6>

        if(!emitirsubestado) return subestados.map((subestado,i) => {
            return <Button key={`sub-${i}`} style={{ border: 'none', backgroundColor: subestado.color, color:'white' }} className="mr-2"  onClick={() => setSubEstado(subestado)} >{subestado.titulo}</Button>
        })
        
        return <div>
            { emitirsubestado.acciones.map((accion,i) => {
                    return <div key={`accion-${i}`}>
                        {showComponentByAccion(accion,i)}
                    </div>
                }) }
            { actualizando === true || subiendofoto === true ? <Spinner animation="border" /> : 
                <Button onClick={() => confirmarEstado()} variant="success" size="sm" >CONFIRMAR ESTADO</Button> }
        </div>
    }

    const subFormularioEstado = () => {

        const isMulti = protocolos.length > 1 ? true : false
        if(!emitirestado) return <div >
            <h6>Selecciona un estado</h6>
            <Alert variant="warning" style={{ fontSize: 12 }}>Cada empresa tiene sus propios protocolos, si ves una empresa diferente debajo que no es la tuya, significa que esta ruta posee órdenes que estás gestionando, si deseas emitir un estado para estas órdenes podrás hacerlo solo si seleccionas uno de los protocolos de esa empresa, solo afectará a dicha empresa.</Alert>

            {
                protocolos.map(empresa => {
                    return <div key={empresa._id}>
                        <Card className="p-3">
                        {empresa.logotipo ? <img style={{ maxWidth: 100, display: "block" }} src={empresa.logotipo} />: false }
                        <h4 className="mb-0">{empresa.razon_social}</h4>
                        <p className="mb-2">Los siguientes son protocolos que aplican solo para {empresa.razon_social}</p>
                        { empresa.estados.map((estado,i) => <Button onClick={() => cambiarStatusDespacho(estado)} style={{ border: 'none', backgroundColor: estado.color, color:'white' }} className="mr-2" key={`boton-estado-${estado._id}`}>{estado.titulo}</Button>) }
                        </Card>
                    </div>
                })
            }
        </div>

        if(emitirestado) return <div>
          <Button size="sm" variant="outline-primary" onClick={()=>{
              setEmitirEstado(false)
              return setSubEstado(false)
          }}>Atrás</Button>
            {mostrarEmpresaEmitir()}
          { emitirsubestado ? <div className="mt-3">
            <Card className="p-2 mb-3 text-white" bg="dark" >
            <div>
            <p className="mb-0">Estás a punto de emitir el siguiente subestado</p>
            <h4><i className="fas fa-circle" style={{ color: emitirsubestado.color }}></i> {emitirsubestado.titulo}</h4>
            </div>
            </Card>
            <p><b><i className="far fa-check-circle"></i> Completa las siguientes tareas</b></p>
        </div> : <h6 className="mt-3">Selecciona una opción</h6> }
          {mostrarSubEstados(emitirestado.subestados)}
        </div>

      }

      const mostrarEmpresaEmitir = () => {
        if(!emitirestado) return false
        const { propietario } = emitirestado
        const i = protocolos.findIndex(p => p.rut === propietario)
        if(i < 0) return false
        const empresa = protocolos[i]
        return <div className="mt-3">
            {empresa.logotipo ? <img style={{ maxWidth: 100, display: "block" }} src={empresa.logotipo} />: false }
            <h4 className="mb-0">{empresa.razon_social}</h4>
        </div>
      }

    const mostrarFormulario = () => {
        if(loading === true) return <div>
            <Spinner animation="border" />
            <h6>Cargando información...</h6>
        </div>

        return <div>
            {subFormularioEstado()}
        </div>
    }

    return <div>
        <Button size="sm" className='w-100 my-2' variant={estilo_boton} style={{ fontSize: 11 }} onClick={()=>openModalEstado()} ><b>EMITIR NUEVO ESTADO</b></Button>
        <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Emitir nuevo estado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {mostrarFormulario()}
        </Modal.Body>
        
      </Modal>
    </div>
}

export default EmitirEstadoModalMasivoRuta