import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Skeleton from "react-loading-skeleton"
import { cerrarSesion } from "../../../redux/actions/session"
import { Button, Col, Row, Spinner, Table } from "react-bootstrap"

const MetaDatosOrden = (props) => {
    const {
        viewType
    } = props
    const { id_orden } = props
    const [ campos, setCampos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)

    useEffect(() => {
        obtenerDatos()
    }, [])
    const obtenerDatos = async () => {
        console.log("ID_orden:", id_orden)
        if(!id_orden) return setLoading(false)
        setLoading(true)
        return fetch(`${urlapi}/ordenes/campos-personalizados/orden`,{
            method:'POST',
            body: JSON.stringify({
                condicion: { },
                id_orden
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setCampos(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = campos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        campos[i].value = value
        console.log(campos)
        return setCampos([...[], ...campos])
    }
    const eliminar = (pos) => {
        if(props.onDelete) props.onDelete(pos)
    }

    const guardarCambios = async () => {
        setLoadingGuardando(true)
        const enviar = campos.filter(c => c.value).map(campo => {
            campo.id_orden = id_orden
            return campo
        })
        return fetch(`${urlapi}/ordenes/campos-personalizados/orden`,{
            method:'PUT',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.success){
                toast.success(res.success)
            }
            return setLoadingGuardando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingGuardando(false)
        })
    }

    const mostrarCampos = () => {
        if(loading) return <Skeleton height={50} />

        if(viewType === "simple") return <div className="table-responsive">
            <Table bordered >
                <thead>
                    <tr>
                        <th>NOMBRE</th>
                        <th>VALOR</th>
                    </tr>
                </thead>
                <tbody>
                {
                    campos.map(campo => {
                        if(!campo.value) return false
                        return <tr md={3} key={campo._id}>
                            <th>{campo.nombre}</th>
                            <th>{campo.value}</th>
                        </tr>
                    })
                }
                </tbody>
            </Table>
        </div>
        return <div>
            <Row className="mb-3">
            {
                campos.map(campo => {
                    return <Col md={3} key={campo._id}>
                        <label className="form-control-label d-block mb-0 text-uppercase" style={{fontWeight:700, fontSize:13}}><i className="fa-solid fa-tag"></i> {campo.nombre}</label>
                        <input className="form-control shadow-sm mb-2" name={campo._id} onChange={handleChange} defaultValue={campo.value}  />
                    </Col>
                })
            }
            </Row>
            { loadingGuardando ? <Spinner animation="border" /> : <Button size="sm" className='shadow-sm w-100' variant="primary" onClick={() => guardarCambios()} ><b>GUARDAR CAMBIOS</b></Button> }
        </div>
    }

    return <div>
        {mostrarCampos()}
    </div>
}

export default MetaDatosOrden