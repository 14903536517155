import { Accordion, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { fechaATexto } from "../../../../lib/helpers/helpers"
import JsonView from '@uiw/react-json-view';
import { GoCodeReview } from "react-icons/go";
import { BsFileEarmarkCode } from "react-icons/bs";

const BoxList = (props) => {
    const registro = props.registro ? props.registro : false

    if(!registro) return false
    let estado = 'desconocido'
    if(registro.respuesta){
        if(typeof registro.respuesta === 'object'){
            if(registro.respuesta.status){
                estado = registro.respuesta.status
            }
        }
    }
    const color = estado !== 200 ? '#a60000' : 'green'
    const border = estado !== 200 ? 'danger' : 'success'

    return <Accordion>
    <Card className="p-2" >
    <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Toca para ver detalles</Tooltip>
                            }
                            >
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 8px', border:'none', fontSize: 12, backgroundColor:'white' }} eventKey={registro._id}>
                        <p className="mb-0" ><b style={{ fontWeight: "bold" }}>Evento recibido</b> {fechaATexto(registro.createdAt)}</p>
                        </Accordion.Toggle>
                        </OverlayTrigger>
                        <Accordion.Collapse eventKey={registro._id}>
                        <Card.Body className="p-0">
                            <h5><BsFileEarmarkCode /> Detalles de la solicitud</h5>
                            <Row>
                                <Col>
                                    <h5 style={{ fontWeight: "bold" }}><GoCodeReview /> Cabeceras recibidas:</h5>
                                    <hr className="mb-1 mt-1" />
                                    <JsonView value={registro.headers} />
                                </Col>
                                <Col>
                                    <h5 style={{ fontWeight: "bold" }}><GoCodeReview /> Payload recibido:</h5>
                                    <hr className="mb-1 mt-1" />
                                    <JsonView value={registro.body} />
                                </Col>
                            </Row>
                            
                            

                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
    </Accordion>
}

export default BoxList