import { useEffect, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import Select from 'react-select';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const SelectorClientes = (props) => {
    const {
        extraOptions,
        allLabel
    } = props
    const opciones_default = extraOptions ? extraOptions : []
    const [ estados, setEstados ] = useState([])
    const [ estadosCarga, setEstadosCarga ] = useState([])
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const session = useSelector(state => state.miusuario)
    const isMulti = typeof props.isMulti !== "undefined" ? true : false
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const titulo = props.titulo ? props.titulo : 'Campo personalizado'
    const full_payload = typeof props.full_payload !== "undefined" ? true : false
    const default_values = [{ value: "", label: allLabel ? allLabel : "Todos" }]

    const handleChangeSelectEstado = (e) => {
        if(isMulti === true){
            let opciones = []
            if(e){
                e.map(valor => {
                    opciones.push(valor.value)
                })
            }
            if(props.onChange) props.onChange(opciones)
            return setFiltroEstado(opciones)
        } else {
            let data = e
            if(full_payload === true){
                console.log(e.value)
                const i = estadosCarga.findIndex(es => es._id === e.value)
                if(i > -1) data = estadosCarga[i]
            }
            if(props.onChange) props.onChange(data)
            return e
        }
    }

    const obtenerEstados = () => {
        return fetch(`${urlapi}/clientes/list`,{
            method: "POST",
            body: JSON.stringify({
                condicion: {},
                page: 1
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){

            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if(Array.isArray(data.datos) !== false){
                setEstadosCarga(data.datos)
                setEstados([...default_values, ...data.datos.map(empresa => ({ value: empresa._id, label: empresa.razon_social }))])
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoadingEstados(false)
        })
    }

    useEffect(() => {
        obtenerEstados()
    }, [])

    return <div className="mb-3">
        <label className='d-block form-control-label' style={{fontWeight:700}}>{titulo}</label>
                    <Select 
                        className="shadow-sm"
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelectEstado}
                        isLoading={loadingEstados}
                        loadingMessage={() => "Cargando datos"}
                        isMulti={isMulti}
                        options={estados}
                        defaultValue={''}
                        placeholder="Buscar cliente"
                        noOptionsMessage={()=>'Sin opciones'}
                        />
    </div>
}

export default SelectorClientes