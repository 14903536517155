import { useEffect, useState } from 'react'
import { Row, Col, Spinner, Tabs, Tab, Card, Modal, Button } from 'react-bootstrap'
import data, { urlapi } from '../../../../lib/backend/data'
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../../../lib/helpers/dates';
import { useParams } from 'react-router-dom';
import { cerrarSesion } from '../../../../redux/actions/session';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx'
import { FaAngleLeft, FaFileUpload } from 'react-icons/fa';
import { BiSolidChevronRight } from 'react-icons/bi';
import { v4 as uuid } from "uuid";
import { TbCloudDataConnection, TbRoute } from 'react-icons/tb';
import { MdOutlineBorderOuter } from 'react-icons/md';
import PantallaCargando from '../../../general/pantalla_cargando';
import ApiTarifas from './api_tarifas';
import { url_images } from '../../../../lib/global/data';

const CreacionApiTarifa = (props) => {
    const {
        id_lote
    } = props
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    
    const imgExcel = `${url_images}/animations/Man transfer data from cloud.gif`
    const dispatch = useDispatch()
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ ruta, setRuta ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ welcome, setWelcome ] = useState(true)
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const esquema = [
        { value: 'nombre', label: 'Nombre', optionalUpload: true },
        { value: 'direccion', label: 'direccion', info:"Ejemplo: Avenida manuel antonio matta 747, santiago" },
        { value: 'comuna', label: 'comuna', optionalUpload: true },
        { value: 'ciudad', label: 'ciudad', optionalUpload: true },
        { value: 'region', label: 'Región', optionalUpload: true },
        { value: 'pais', label: 'País', optionalUpload: true },
        // { value: 'destinos', label: 'Destinos', info: "Utiliza la barra (/) para separar varios puntos de entrega" },
        // { value: 'unidad_medida', label: 'Unidad medida', producto: true, optionalUpload: true, info: "Solo usar: MTR, INCH, CM, SQFT, SQM" , validation: { type: "string", key: "", values: unidades_medida.map(e => e.code) } },
        // { value: "rutcliente", label: "Rut cliente", optionalUpload: true },
        // { value: "productos", label: "Productos", denieUpload: true },
    ]
    const [ columnasrequeridas, setColumnasRequeridas ] = useState(esquema.map(e => e.value))
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ capacidad, setCapacidad ] = useState(30)
    const [ resize, setResize ] = useState(1)
    const [ pedidos, setPedidos ] = useState([])
    const [ comunascoordenadas, setComunasCoordenadas ] = useState([])
    const [ checlBoxIds, setCheckBoxIds ] = useState([])
    const [ loadingZonas, setLoadingZonas ] = useState([])
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [ propietarioBusqueda, setPropietarioBusqueda ] = useState(session.data.empresa)
    const {
        id
    } = useParams()
    const idruta = id

    const onCreate = data => {
        if(!data?._id) return false
        return setRegistros(prev => {
            const actual = [...prev]
            actual.unshift(data)
            return [...[], ...actual]
        })
    }

    const onChangeRegistros = (data) => {
        return setRegistros([...[], ...data])
    }

    const geolocalizarDireccion = async (direccion) => {
        return fetch(`${urlapi}/ordenes/geolocalizar-direccion`,{
            method:'POST',
            body: JSON.stringify({
                direccion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.geometry){
                return res
            }
            return false
        })
        .catch(error => false)
    }

    const handleCloseErrores = () => {
        return setShowModalErrores(false)
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }


    const onConfirm = (data) => {
        if(!data) return
        if(!data.routes) return
        if(!Array.isArray(data.routes)) return
        if(data.routes.length < 1) return toast.error('Sin rutas')
        

        const rutasDividir = data.routes.map(route => {
            const entregas = route.stops.filter(pa => pa.type === "dropoff")
            const enviar = {
                id_conductor: route.vehicle,
                pedidos: entregas.map(o => o.location),
                stops: route.stops,
                geometrias: route.geometrias
            }
            return enviar
        })

        setLoadingCreacion(true)
        return fetch(`${urlapi}/rutas/reasignar`,{
            method:'POST',
            body: JSON.stringify({
                id_ruta: ruta._id,
                rutas: rutasDividir
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res.success === true){
                toast.success('Proceso realizado exitosamente')
                return setTimeout(() => {
                    window.location = '/rutas'
                }, 1000);
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    

    useEffect(() => {
        obtenerDatos()
    },[])

    const obtenerDatos = async () => {
        if(!id_lote) return
        setLoading(true)
        return fetch(`${data.urlapi}/pricing/lotes/grupos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion: { id_lote }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos de zonas')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setRegistros(res)
                if(res.length > 0) setWelcome(false)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    } 
    
    const pantallaBienvenida = () => {
        const iconSize = 30
        return <Card >
            <div className='p-4'>
            <Row className="justify-content-md-center">
                    <Col md={6} className="text-center">
                        <h4 className="mb-0">Presentamos</h4>
                        <h1><TbCloudDataConnection /> <b>Mi API de tarifas</b></h1>
                        <Row className="justify-content-md-center">
                            <Col md={3}>
                                <MdOutlineBorderOuter size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Genera tu propia API</h5>
                            </Col>
                            <Col md={3}>
                                <MdOutlineBorderOuter size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Genera tus plugins</h5>
                            </Col>
                            <Col md={3}>
                                <MdOutlineBorderOuter size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Múltiples integraciones</h5>
                            </Col>
                            {/* <Col md={3}>
                                <TbPresentationAnalytics size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Dashboard Analítico</h5>
                            </Col>
                            <Col md={3}>
                                <MdOutlineModeEditOutline size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Reglas de negocio a medida</h5>
                            </Col>
                            <Col md={3}>
                                <AiOutlineAudit size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Auditoria automatizada</h5>
                            </Col> */}
                        </Row>
                        <img src={`${url_images}/animations/Holding Map Paper And Finding Navigation.gif`} style={{ width: "50%" }} />
                        <p>En esta opción puedes personalizar el acceso, visualización y cotización usando las tarifas aquí incluidas</p>
                        <Button size="sm" onClick={() => setWelcome(false)}>COMENZAR</Button>
                    </Col>
                </Row>
            </div>
        </Card>
    }

    const boxExcel = () => {
        if(loading) return <PantallaCargando />
        if(welcome) return pantallaBienvenida()
        return <div>
            <ApiTarifas id_lote={id_lote} datos={registros} onCreate={(data) => onCreate(data)} onChange={(data) => onChangeRegistros(data)} /> 
        </div>
    }
      
    return <div>
        {/* <h2 className='mb-0' style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>Planificador de rutas</h2> */}
        {boxExcel()}
        {mostrarErrores()}
        
</div>
}

export default CreacionApiTarifa