import { useState } from "react"
import { Accordion, Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";
import { components, modulo, modulos_contenido, modulos_datos } from "./data";
import { estilo_last_mile } from "../../lib/global/styles"
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import Select from 'react-select'
import SelectorCamposPersonalizados from "../campos-personalizados/selector";
import CargaImagenes from "../general/carga_imagenes";
import ManejadorContenido from "./manejador_contenido";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import DetallesDocumento from "./document";


const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const requeridos = [
        { value:'nombre', label: 'Título' },
        { value:'module', label: 'Tipo' },
    ]
    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

        setLoading(true)
        return fetch(`${data.urlapi}/${modulo}`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        let faltantes = []
        requeridos.map(campo => {
            if(!proveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setLoading(true)
        return fetch(`${data.urlapi}/${modulo}`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        return setNuevoProveedor(prev => {
            let actual = {...prev}
            actual[name] = value
            return actual
        })
    }

    const agregarModulo = (payload) => {
        return setNuevoProveedor(prev => {
            let actual = {...prev}
            if(!actual.esquema) actual.esquema = []
            actual.esquema.push(payload)
            return actual
        })
    }

    const mostrarMantenedorOrden = () => {
        let key = "mantenedor-ordenes"
        return <div>

            <Accordion className="mb-3">
            <Card className="p-0">
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={key}>
                        <div className="pl-3 pr-3 pb-0 hover">
                                    <h6 className="mb-0" style={{ ...estilo_last_mile.bold, fontSize: 13, textTransform: "uppercase"}}>Elementos de contenido</h6>
                        </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={key}>
                        <Card className="p-3" style={{ backgroundColor:'#f1f1f1' }}>
                            <div className="p-2" >
                                <Row>
                                {
                                    modulos_contenido.map((modulo, i) => {
                                        return <Col md={12} key={`${modulo.title}-${i}`}>
                                                <h6 style={{ fontWeight: "bold" }}>{modulo.title}</h6>
                                                <Row>
                                                {
                                                    modulo.options.map((opcion, i) => {
                                                        const data = {
                                                            module_name: modulo.name,
                                                            module_title: modulo.title,
                                                            ...opcion,
                                                            content: []
                                                        }
                                                        return <Col md={4}>
                                                            <Card className="p-3 hover" onClick={() => agregarModulo(data)}>
                                                                <h6>{opcion.name}</h6>
                                                            </Card>
                                                        </Col>
                                                    })
                                                }
                                                </Row>
                                        </Col>
                                    })
                                }
                                </Row>
                            </div>
                        </Card>
                        </Accordion.Collapse>
                    </Card>
            </Accordion>

        </div>
    }

    const mostrarOpcionesAvanzadas = (tipo) => {
        if(tipo === 'ordenes') return <div>
            {mostrarMantenedorOrden()}
        </div>
    }

    const agregarItem = (item, i_esquema, posicion) => {
        const setterFunction = proveedor ? setProveedor : setNuevoProveedor
        return setterFunction(prev => {
            let actual = {...prev}
            if(!actual.esquema[i_esquema].info) actual.esquema[i_esquema].info = []
            // if(!actual.esquema[i_esquema].info[posicion]) actual.esquema[i_esquema].info[posicion] = []
            actual.esquema[i_esquema].info.push({ ...item, posicion })
            return {...actual}
        })
    }

    const removerSegmento = (i) => {

        const setterFunction = proveedor ? setProveedor : setNuevoProveedor

        setterFunction(prev => {
            let actual = {...prev}
            actual.esquema.splice(i, 1)
            return actual
        })
    }

    const defaultByConstTypeOf = (type) => {
        if(Array.isArray(type)) return []
        switch(type){
            case 'string':
                return ''
            case 'number':
                return 0
            case 'boolean':
                return false
            default:
                return ''
        }
    }

    const modificarItem = (data, i_segmento) => {
        const setterFunction = proveedor ? setProveedor : setNuevoProveedor
        setterFunction(prev => {
            let actual = {...prev}
            let default_value = defaultByConstTypeOf(data.value)
            if(Array.isArray(default_value) !== false ){
                if( !actual.esquema[i_segmento].info[data.i].value ) actual.esquema[i_segmento].info[data.i].value = default_value
            } else {
                
            }
            actual.esquema[i_segmento].info[data.i].value = data.value

            return actual
        })
    }

    const removeContent = (i_info, i_esquema) => {
        const setterFunction = proveedor ? setProveedor : setNuevoProveedor
        setterFunction(prev => {
            let actual = {...prev}
            actual.esquema[i_esquema].info.splice(i_info, 1)
            return actual
        })
    }

    const mover = (action, pos, i) => {

        const setterFunction = proveedor ? setProveedor : setNuevoProveedor

        setterFunction(prev => {
            let actual = {...prev}
            console.log({ action, actual })
            if( action === "up" ){
                if(pos === 0) return actual
                let temp = actual.esquema[i].info[pos]
                console.log({ temp, action, pos, i })
                actual.esquema[i].info[pos] = actual.esquema[i].info[pos - 1]
                actual.esquema[i].info[pos - 1] = temp
            } else if( action === "down" ){
                if(pos === actual.esquema[i].info.length - 1) return actual
                let temp = actual.esquema[i].info[pos]
                actual.esquema[i].info[pos] = actual.esquema[i].info[pos + 1]
                actual.esquema[i].info[pos + 1] = temp
            }
            return actual
        })
    }

    const mostrarContenidoModulos = (esquema, tipo) => {
        if(!esquema) return false
        if(!Array.isArray(esquema)) return false
        if(esquema.length < 1) return false

        return <div className="mb-3">
            <h5 style={{ fontWeight: "bold" }}>Contenido de tu documento</h5>
            <Card className="p-2" style={{ minHeight: 700 }}>
            {
                esquema.map((campo, i) => {
                    const opciones_adicionales = modulos_datos[tipo]
                    return <div key={`esl-${i}`}>
                            <Row>
                                <Col className="text-right"><Button size="sm" style={{ fontSize: 11 }} onClick={() => removerSegmento(i)} variant="link" ><FaRegTrashAlt style={estilo_last_mile.icon} /></Button></Col>
                            </Row>
                            <Row>
                            {
                                Array.from(Array(campo.meta_value).keys()).map(number => {

                                    return <Col className="">
                                        <Card className="p-3" style={{ background: "#e7e6e6" }}>
                                            <ManejadorContenido
                                                onMove={(action, pos) => mover(action, pos, i)}
                                                posicion={number} 
                                                onRemove={(po) => removeContent(po, i)} 
                                                onChange={(data) => modificarItem(data, i, number)}
                                                content={campo.info} 
                                                data={opciones_adicionales} 
                                                onAdd={(data) => agregarItem(data, i, number)} 
                                            />
                                        </Card>
                                    </Col>
                                })
                            }
                            </Row>
                    </div>
                })
            }
            </Card>
        </div>
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nuevo campo personalizado</h4>
                    <p>Los campos personalizados se utilizan para guardar información adicional a las órdenes.</p>
                    <div className="mt-2"><DetallesDocumento id="666b856c83d208f4ad3ffdf2" esquema={nuevoProveedor} /></div>
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Tipo</label>
                    <select className="form-control" name="module" defaultValue={nuevoProveedor.module} onChange={handleChangeNuevo} >
                        <option value="">Seleccione</option>
                        <option value="ordenes">Órdenes</option>
                    </select>
                </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Título</label>
                    <input className="form-control" name="nombre" value={nuevoProveedor.nombre} onChange={handleChangeNuevo} />
                </Col>
                <Col md={12}>
                    {mostrarOpcionesAvanzadas(nuevoProveedor.module)}
                    {mostrarContenidoModulos(nuevoProveedor.esquema, nuevoProveedor.module)}
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/${modulo}?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <Row>
        <Col md={12} className="mb-3">
            <h4 className="mb-0">{proveedor.nombre}</h4>
            {
                removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
            }
            <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Esta es la forma en que debes escribirlo en el documento excel de importación o API</Tooltip>
                            }
                            >
                                <h5 style={{ backgroundColor: '#c7c7c7', borderRadius: 5, color: 'white', fontSize: 13, display: 'inline', padding: '3px 5px' }}><b style={{ color:'black' }}>SLUG:</b> { proveedor.nombre ? proveedor.nombre.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'').normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '' }</h5>
                          </OverlayTrigger>
                          <div className="mt-2"><DetallesDocumento id="666b856c83d208f4ad3ffdf2" esquema={proveedor} /></div>
        </Col>
        <Col md={6} className="mb-3">
            <label className="form-control-label d-block">Tipo</label>
            <select className="form-control" name="module" defaultValue={proveedor.module} onChange={handleChange} >
                <option value="">Seleccione</option>
                <option value="ordenes">Órdenes</option>
            </select>
        </Col>
        <Col md={6} className="mb-3">
            <label className="form-control-label d-block">Título</label>
            <input className="form-control" name="nombre" defaultValue={proveedor.nombre} onChange={handleChange} />
        </Col>
        <Col md={12}>
                    {mostrarOpcionesAvanzadas(proveedor.module)}
                    {mostrarContenidoModulos(proveedor.esquema, proveedor.module)}
            <div className="d-flex justify-content-end">
            {/* <Button className='shadow-sm mr-2' variant="outline-secondary" size="sm" ><Link to={`/${rutas.documents.slug}`} target="_blank" ><b>VISTA PREVIA</b></Link></Button> */}
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
            </div>
        </Col>
    </Row>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull