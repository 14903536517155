import { useEffect, useRef, useState } from "react";
import SelectorBodega from "../bodega/bodegas/selector_bodega";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import { toast } from "react-toastify";
import { dateFromTimeForToday } from "../../lib/helpers/dates";
import { calcularPesoVolumetricoUnitario } from "../../lib/helpers/logistica";
import { colores, urlapi } from "../../lib/backend/data";
import { Accordion, Badge, Button, ButtonGroup, Card, Col, Form, Modal, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import MapaRuteo from "./mapa_ruteo";
import DetallePunto from "./detalle_punto";
import { getDireccion1 } from "../../lib/helpers/pedidos/pedidos";
import { PiCursorClickFill, PiTruckBold } from "react-icons/pi";
import { MdArrowBack, MdDeleteOutline, MdDragIndicator, MdOutlinePlace, MdOutlineRoute, MdPlace } from "react-icons/md";
import { HiCursorClick } from "react-icons/hi";
import DetalleVehiculo from "./detalle_vehiculo";
import ModalConductores from "../conductores/modal_selector";
import Select from 'react-select'
import { opciones_origenes, optiones_estricto_tiempo } from "./data";
import { fechaUTCATiempo } from "../../lib/helpers/helpers";
import { FaAngleLeft, FaArrowLeft, FaChevronDown, FaCircle } from "react-icons/fa";
import { estilo_last_mile } from "../../lib/global/styles";
import { IoMdSettings } from "react-icons/io";
import { url_images } from "../../lib/global/data";
import { obtenerZonaDeOrden, obtenerZonasDePedidos } from "../../lib/helpers/rutas";
import { RiRouteLine } from "react-icons/ri";
import { ReactSortable } from "react-sortablejs";
import { set } from "date-fns";


const RuteoCargas = (props) => {
    const {
        resize
    } = props
    const [ origen, seleccionarBodegaOrigen ] = useState(false)
    const [ showModalConfig, setShowModalConfig ] = useState(false)
    const [ vehiculos, setVehiculos ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ loadingAcciones, setLoadingAcciones ] = useState(false)
    const [ vehicleHover, setVehicleHover ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ pointHover, setPointHover ] = useState(false)
    const [ seleccionRuta, setSeleccionRuta] = useState(false)
    const [ marcadores, setMarcadores ] = useState([])
    const [ checlBoxIds, setCheckBoxIds ] = useState([])
    const [ loadingRecalculo, setLoadingRecalculo ] = useState(false)
    const [ resultados, setResultados ] = useState(false)
    const [ visualizationType, setVisualizationType ] = useState("routing")
    const [ puntos, setPuntos ] = useState(props.puntos)
    const [ recalculo, setRecalculo ] = useState([])
    const configuracionGeneralRef = useRef(null)
    const dispatch = useDispatch()
    const [ configRuteo, setConfigRuteo ] = useState({ 
        duration_pickup: 1, 
        duration_dropoff: 1, 
        estrict_default: "soft", 
        horario_desde: "07:00", 
        horario_hasta: "18:00",
        dropoff_earliest: "07:00",
        dropoff_latest: "18:00",
        dropoff_type: "soft",
        pickup_earliest: "07:00",
        pickup_latest: "18:00",
        pickup_type: "soft",
        break_duration: 30,
        activate_boxes: false,
        boxes: 30,
        activate_weight: false,
        weight: 600,
    }) // 15 minutos


    useEffect(() => {
        if(props.puntos){
            let markers = []
            for( const point of props.puntos ){
                if(!point.location) continue
                if( typeof point.location.latitude !== "number" || typeof point.location.longitude !== "number" ) continue
                markers.push({
                    id: point._id,
                    lat: point.location.latitude,
                    lng: point.location.longitude,
                })
            }
            setMarcadores(markers)
        }
    }, [ ])

    const handleCheckConfigRuteo = (e, name) => {
        setConfigRuteo(prev => {
            let actual = {...prev}
            actual[name] = e


            if(name === "activate_boxes") if(e === true){
                const inactivos = puntos.filter(p => {
                    let cantidad = 0
                    if(!isNaN( parseInt(p.bultos) )) cantidad = parseInt(p.bultos)
                    return cantidad < 1
                })

                if(inactivos.length > 0) toast.warn("Existen puntos sin bultos")
            }
            if( name === "activate_weight" ) if(e === true){
                const inactivos = puntos.filter(p => {
                    let cantidad = 0
                    if(!isNaN( parseFloat(p.peso) )) cantidad = parseFloat(p.peso)
                    return cantidad > 0 ? false : true
                })

                if(inactivos.length > 0) toast.warn("Existen puntos sin peso")
            }
            return actual
        })
    }

    const prepareLocationsPayload = (paradas) => {
        let points = []

        points.push({
            name: "warehouse",
            coordinates: [ origen.location.coordinates[0], origen.location.coordinates[1] ],
        })
        
        for( const punto of paradas ){

            if(typeof punto !== "object") continue
            if(!punto.location) continue
            const longitude = punto.location.longitude
            const latitude = punto.location.latitude
            if(typeof longitude !== "number") continue
            if(typeof latitude !== "number") continue
            points.push({
                name: punto._id,
                coordinates: [ longitude, latitude ],
            })
        }
        
        return points.filter(e => e)
    }

    const prepareVehiclePayload = (veh) => {

        const calculate         = calcularPesoVolumetricoUnitario(veh)
        const weight            = calculate.weight
        const volume            = calculate.vol
        const earliest_start    = veh.horario_desde ? dateFromTimeForToday(veh.horario_desde) : dateFromTimeForToday(configRuteo.horario_desde)
        const latest_end        = veh.horario_hasta ? dateFromTimeForToday(veh.horario_hasta) : dateFromTimeForToday(configRuteo.horario_hasta)


        let objeto_vehiculo = {
            name: veh._id,
            start_location: veh.start_location ? veh.start_location : "warehouse",
            earliest_start,
            latest_end
        }

        if(configRuteo.activate_weight === true){
            if(!objeto_vehiculo.capacities) objeto_vehiculo.capacities = {}
            objeto_vehiculo.capacities.weight = parseInt(veh.peso) ? parseInt(veh.peso) : parseInt(configRuteo.weight)
        }

        if(configRuteo.activate_boxes === true){
            if(!objeto_vehiculo.capacities) objeto_vehiculo.capacities = {}
            objeto_vehiculo.capacities.boxes = parseInt(veh.bultos) ? parseInt(veh.bultos) : parseInt(configRuteo.boxes)
        }

        if(veh.end_location) objeto_vehiculo.end_location = veh.end_location?.value
        if(veh.capabilities) objeto_vehiculo.capabilities = veh.capabilities.map(e => e.title)

        let break_times = []
        
        let duracion_por_defecto = configRuteo.break_duration ? configRuteo.break_duration : 30

        if(configRuteo.descanso === true){
            if(configRuteo.break_earliest_start && configRuteo.break_latest_end){
                break_times = [
                    {
                        earliest_start: dateFromTimeForToday(configRuteo.break_earliest_start),
                        latest_end: dateFromTimeForToday(configRuteo.break_latest_end),
                        duration: parseInt(duracion_por_defecto) 
                    }
                ]
            }
        }

        if(veh.descanso === true){
            if(veh.break_desde && veh.break_hasta){
                break_times = [
                    {
                        earliest_start: dateFromTimeForToday(veh.break_desde),
                        latest_end: dateFromTimeForToday(veh.break_hasta),
                        duration: veh.break_duration ? parseInt(veh.break_duration)  : parseInt(duracion_por_defecto) 
                    }
                ]
            }
        }

        if(break_times.length > 0) objeto_vehiculo.breaks = break_times
        return objeto_vehiculo
    }

    const handleCheckDescanso = (e) => {
        setConfigRuteo(prev => {
            let actual = {...prev}
            actual.descanso = e
            return actual
        })
    }

    const preparePointPayload = (punto) => {


        let pickup_duration = parseInt(configRuteo.duration_pickup) * 60
        let dropoff_duration = parseInt(configRuteo.duration_dropoff) * 60

        if(punto.duration_pickup ) pickup_duration = parseInt(punto.duration_pickup) * 60
        if(punto.duration_dropoff) dropoff_duration = parseInt(punto.duration_dropoff) * 60

        let pickup_earliest         = dateFromTimeForToday(configRuteo.pickup_earliest)
        let pickup_latest           = dateFromTimeForToday(configRuteo.pickup_latest)

        let dropoff_earliest        = dateFromTimeForToday(configRuteo.dropoff_earliest)
        let dropoff_latest          = dateFromTimeForToday(configRuteo.dropoff_latest)

        if(punto.pickup_earliest)   pickup_earliest     = dateFromTimeForToday(punto.pickup_earliest)
        if(punto.pickup_latest)     pickup_latest       = dateFromTimeForToday(punto.pickup_latest)

        if(punto.dropoff_earliest)  dropoff_earliest    = dateFromTimeForToday(punto.dropoff_earliest)
        if(punto.dropoff_latest)    dropoff_latest      = dateFromTimeForToday(punto.dropoff_latest)


        let objeto_punto = {
            name: punto._id,
            from: "warehouse",
            to: punto._id,
            pickup_duration,
            dropoff_duration,
            pickup_times: [
                {
                    earliest: pickup_earliest,
                    latest: pickup_latest,
                    type: punto.type ? punto.type : configRuteo.pickup_type
                }
            ],
            dropoff_times: [
                {
                    earliest: dropoff_earliest,
                    latest: dropoff_latest,
                    type: punto.type ? punto.type : configRuteo.dropoff_type
                }
            ]
        }

        if(punto.capabilities) objeto_punto.requirements = punto.capabilities.map(e => e.title)
        
        if(configRuteo.activate_weight === true){
            if(!objeto_punto.size) objeto_punto.size = {}
            objeto_punto.size.weight = parseInt(punto.peso) ? parseInt(punto.peso) : parseInt(configRuteo.weight)
        }

        if(configRuteo.activate_boxes === true){
            if(!objeto_punto.size) objeto_punto.size = {}
            objeto_punto.size.boxes = parseInt(punto.bultos) ? parseInt(punto.bultos) : parseInt(configRuteo.boxes)
        }
        
        return objeto_punto
    }

    const confirmarRutas = () => {
        if(props.onConfirm) props.onConfirm(resultados)
    }

    const crearConductorNuevo = async (nueva_data) => {
        if(!origen) return toast.error("Debes definir un origen")
        if(vehiculos.length < 1) return toast.error("Debes definir al menos un vehículo")
        if(!configRuteo.horario_desde || !configRuteo.horario_hasta ) return toast.error("Debes definir un horario de trabajo por defecto")

        if(configRuteo.activate_boxes === true){
            const inactivos = puntos.filter(p => {
                let cantidad = 0
                if(!isNaN( parseInt(p.bultos) )) cantidad = parseInt(p.bultos)
                return cantidad < 1
            })

            if(inactivos.length > 0) toast.error("Existen puntos sin bultos")
        }

        if( configRuteo.activate_weight === true){
            const inactivos = puntos.filter(p => {
                let cantidad = 0
                if(!isNaN( parseFloat(p.peso) )) cantidad = parseFloat(p.peso)
                return cantidad > 0 ? false : true
            })

            if(inactivos.length > 0) toast.error("Existen puntos sin peso")
        }

        const puntos_invalidos = puntos.filter(punto => {
            if(typeof punto !== "object") return true
            if(!punto.location) return true
            const longitude = punto.location.longitude
            const latitude = punto.location.latitude
            if(typeof longitude !== "number") return true
            if(typeof latitude !== "number") return true
            return false
        })

        if(puntos_invalidos.length > 0) return toast.error("Existen puntos sin coordenadas válidas")

        const payload_enviar = {
            version: 1,
            locations: prepareLocationsPayload(puntos),
            vehicles: vehiculos.map(v => prepareVehiclePayload(v) ),
            shipments: puntos.map(p => preparePointPayload(p)).filter((p,i) => i < 200 ? p : false).filter(i => i)
        }

        // return console.log(payload_enviar)
        setLoading(true)

        const url = `${urlapi}/ruteo/route-advance`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(payload_enviar),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.routes){
                setResultados(res)
                setTimeout(() => {
                    setVisualizationType("results")
                }, 200);
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }


    const reprocesarPuntos = async (ids) => {
        setLoadingAcciones(true)
        return fetch(`${urlapi}/rutas/geocode-points`,{
            method:'POST',
            body: JSON.stringify({ ids }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.success){
                toast.success("Realizado exitosamente")
                return setTimeout(() => {
                    return window.location.reload()
                }, 500);
            }
            return setLoadingAcciones(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingAcciones(false)
        })
    }

    const deseleccinoarTodos = (query) => {
        const list = document.querySelectorAll(`${query} .delpa-checkpedido-ruteo`);
            for (const checkbox of list) {
            checkbox.checked = false;
            }
            verificarCheckBoxes()
      }

      const seleccinoarTodos = (query) => {
        const list = document.querySelectorAll(`${query} .delpa-checkpedido-ruteo`);
            for (const checkbox of list) {
            checkbox.checked = true;
            }
            verificarCheckBoxes()
      }

    const verificarCheckBoxes = (e) => {
        setSeleccionRuta(false)
        return setTimeout(() => {
            const list = document.querySelectorAll('input[type=checkbox]:checked');
            let ids = []
            for (const checkbox of list) {
                if(checkbox.id.length < 24) continue
                ids.push(checkbox.id)
            }
            setCheckBoxIds(ids)
            return ids
        }, 100);
      }

    const desactivarCheckbox = () => {
        setTimeout(() => {
            const list = document.querySelectorAll(`input[type=checkbox]:checked`);
            for (const checkbox of list) {
                checkbox.checked = false;
            }
            setCheckBoxIds([])
        }, 100);
      }


    const reprocesoCoordenadas = () => {
        const puntos_invalidos = puntos.filter(punto => {
            if(typeof punto !== "object") return true
            if(!punto.location) return true
            const longitude = punto.location.longitude
            const latitude = punto.location.latitude
            if(typeof longitude !== "number") return true
            if(typeof latitude !== "number") return true
            return false
        })

        if(puntos_invalidos.length < 1) return false

        return <div>
            <Card size="small" className="mb-3">
            <p type="danger" className="mb-0">Existen {puntos_invalidos.length} puntos sin coordenadas válidas</p>
            <Button type="default" loading={loadingAcciones} onClick={() => reprocesarPuntos(puntos_invalidos.map(p => p._id))} >REINTENTAR GEOLOCALIZACIÓN MASIVA</Button>
            </Card>
        </div>

    }

    const handleChangePunto = (new_data) => {
        return setPuntos(prev => {
            let data = [...prev]
            const i = data.findIndex(e => e._id === new_data._id)
            if(i < 0) return data
            data[i] = new_data
            return [...[], ...data]
        })
    }

    const eliminarPunto = id => {
        return setPuntos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(p => p._id === id)
            if(i > -1) actual.splice(i,1)
            return [...[], ...actual]
        })
    }

    const onDeleteVehicle = (veh) => {
        return setVehiculos(prev => {
            let data = [...prev]
            const i = data.findIndex(e => e._id === veh._id)
            if(i > -1) data.splice(i,1)
            return [...[], ...data]
        })
    }

    const onSelectVehiculo = (e) => {
        return setVehiculos(prev => {
            let actual = [...prev]

            if(Array.isArray(e)){
                
                for( const veh of e ){
                    const existe = actual.findIndex(v => v._id === veh._id)
                    if( existe > -1 ) continue
                    actual.unshift(veh)
                }
            } else if(typeof e === "object"){
                const i = actual.findIndex(v => v._id === e._id)
                if(i < 0) {
                    actual.unshift(e)
                }
            }
            return [...[], ...actual]
        })
    }

    const handleChangeVehiculo = (new_data) => {
        return setVehiculos(prev => {
            let data = [...prev]
            const i = data.findIndex(e => e._id === new_data._id)
            if(i < 0) return data
            data[i] = new_data
            return [...[], ...data]
        })
    }

    const onChangeConfigRuteo = (e) => {
        const { name, value } = e.target
        return setConfigRuteo(prev => {
            let actual = {...prev}
            actual[name] = value
            return actual
        })
    }

    const onChangeConfigRuteoSelect = (val, name) => {
        return setConfigRuteo(prev => {
            let actual = {...prev}
            actual[name] = val
            return actual
        })
    }

    const modalConfiguracion = () => {

        const componente_capacidad = <div>
            <Row gutter={15}>
                            {/* <Col xs={12}><h4 level={4} className="mt-0 mb-3">Capacidad general de vehículos</h4></Col> */}
                            <Col md={6}>
                                <Form.Group className="mb-3" key="activate_boxes" controlId="activate_boxes">
                                    <Form.Check type="switch" defaultChecked={configRuteo.activate_boxes === true} name="activate_boxes" label={"Tomar en cuenta la cantidad de bultos"} onChange={(val) => handleCheckConfigRuteo(val.target.checked, 'activate_boxes')} />
                                </Form.Group>
                                {/* <Switch className="mb-3" checkedChildren="RUTEAR POR BULTOS ACTIVADO" unCheckedChildren="TOCA PARA TOMAR EN CUENTA LOS BULTOS" onChange={(val) => handleCheckConfigRuteo(val, 'activate_boxes')} defaultChecked={configRuteo.activate_boxes} /> */}
                                <label className="form-control-label">Máximo de bultos por vehículo</label>
                                <input className="form-control" disabled={!configRuteo.activate_boxes} type="number" name="boxes" defaultValue={configRuteo.boxes} onChange={onChangeConfigRuteo} />
                               
                            </Col>
                            <Col md={6}>
                            <Form.Group className="mb-3" key="activate_weight" controlId="activate_weight">
                                    <Form.Check type="switch" defaultChecked={configRuteo.activate_weight === true} name="activate_weight" label={"Tomar en cuenta el peso de la carga"} onChange={(val) => handleCheckConfigRuteo(val.target.checked, 'activate_weight')} />
                                </Form.Group>
                                {/* <Switch className="mb-3" checkedChildren="RUTEAR POR PESO ACTIVADO" unCheckedChildren="TOCA PARA TOMAR EN CUENTA EL PESO" onChange={(val) => handleCheckConfigRuteo(val, 'activate_weight')} defaultChecked={configRuteo.activate_weight} /> */}
                                <label className="form-control-label">Máximo de peso por vehículo</label>
                                <input className="form-control" disabled={!configRuteo.activate_weight} type="number" name="weight" defaultValue={configRuteo.weight} onChange={onChangeConfigRuteo} />
                            </Col>
                        </Row>
        </div>

        const componente_horario = <div>
            <Row gutter={15}>
                            <Col md={12}>
                                <h4 level={4} className="mt-0 mb-0">Horario de trabajo de vehículos</h4>
                            </Col>
                            <Col md={6}>
                                <label className="form-control-label">Desde</label>
                                <input className="form-control" type="time" name="horario_desde" defaultValue={configRuteo.horario_desde} onChange={onChangeConfigRuteo} />
                               
                            </Col>
                            <Col md={6}>
                                <label className="form-control-label">Hasta</label>
                                <input className="form-control" type="time" name="horario_hasta" defaultValue={configRuteo.horario_hasta} onChange={onChangeConfigRuteo} />
                               
                            </Col>
                        </Row>
        </div>

        const componente_recogidas = <div>
            
            <Row gutter={15}>
                            {/* <Col md={12}>
                                <h4 level={4} className="mt-0 mb-0">Ventana horaria para recogidas</h4>
                            </Col> */}
                            <Col md={4}>
                                <label className="form-control-label">Desde</label>
                                <input className="form-control" type="time" name="pickup_earliest" defaultValue={configRuteo.pickup_earliest} onChange={onChangeConfigRuteo} />
                            </Col>
                            <Col md={4}>
                                <label className="form-control-label">Hasta</label>
                                <input className="form-control" type="time" name="pickup_latest" defaultValue={configRuteo.pickup_latest} onChange={onChangeConfigRuteo} />
                               
                            </Col>
                            <Col md={4}>
                                <label className="form-control-label">Tiempo</label>
                                    <input className="form-control" type="number" name="duration_pickup" defaultValue={configRuteo.duration_pickup} onChange={onChangeConfigRuteo} />
                               
                            </Col>
                            {/* <Col md={24}>
                            <label className="form-control-label">Es estricto el tiempo de llegada en relación a la duración de la parada?</label>
                                <Select defaultValue={configRuteo.pickup_type} options={optiones_estricto_tiempo} onChange={(val) => onChangeConfigRuteoSelect(val,'pickup_type')} />
                           
                            </Col> */}
                        </Row>
            
        </div>

        const componente_entregas = <div>
            
            <Row gutter={15}>
                            {/* <Col md={12}>
                                <h4 level={4} className="mt-0 mb-0">Ventana horaria para entregas</h4>
                            </Col> */}
                            <Col md={4}>
                                <label className="form-control-label">Desde</label>
                                <input className="form-control" type="time" name="dropoff_earliest" defaultValue={configRuteo.dropoff_earliest} onChange={onChangeConfigRuteo} />
                               
                            </Col>
                            <Col md={4}>
                                <label className="form-control-label">Hasta</label>
                                <input className="form-control" type="time" name="dropoff_latest" defaultValue={configRuteo.dropoff_latest} onChange={onChangeConfigRuteo} />
                               
                            </Col>
                            <Col md={4}>
                                <label className="form-control-label">Tiempo en entrega</label>
                                    <input className="form-control" type="number" name="duration_dropoff" defaultValue={configRuteo.duration_dropoff} onChange={onChangeConfigRuteo} />
                               
                            </Col>
                            {/* <Col md={12}>
                            <label className="form-control-label">Es estricto el tiempo de llegada en relación a la duración de la parada?</label>
                                <Select defaultValue={configRuteo.dropoff_type} options={optiones_estricto_tiempo} onChange={(val) => onChangeConfigRuteoSelect(val,'dropoff_type')} />
                           
                            </Col> */}
                        </Row>
            
        </div>

        const componende_tiempo_descanso = <div>
            {/* <Switch checkedChildren="DESCANSO ACTIVADO" unCheckedChildren="TOCA PARA ACTIVAR DESCANSO" onChange={handleCheckDescanso} defaultChecked={configRuteo.descanso} /> */}
            {
                configRuteo.descanso === true ? <div>
                
                <Row gutter={15}>
                                <Col span={24}>
                                    <h4 level={4} className="mt-0 mb-0">Horario de descanso para el conductor de vehículo</h4>
                                </Col>
                                <Col span={8}>
                                    <label className="form-control-label">Duración (Min.)</label>
                                    <input type="number" name="break_duration" defaultValue={configRuteo.break_duration} onChange={onChangeConfigRuteo} />
                                   
                                </Col>
                                <Col span={8}>
                                    <label className="form-control-label">Hora más temprana para el descanso</label>
                                    <input type="time" name="break_earliest_start" defaultValue={configRuteo.break_earliest_start} onChange={onChangeConfigRuteo} />
                                   
                                </Col>
                                <Col span={8}>
                                    <label className="form-control-label">Hora más tarde para el descanso</label>
                                    <input type="time" name="break_latest_end" defaultValue={configRuteo.break_latest_end} onChange={onChangeConfigRuteo} />
                                   
                                </Col>
                            </Row>
                
                </div> : false
            }
            
        </div>

        const configuraciones = [
            {
                label: "Capacidad general de vehículo",
                children: componente_capacidad
            },
            {
                label: "Horario de trabajo",
                children: componente_horario
            },
            // {
            //     label: "Horario de descanso",
            //     children: componende_tiempo_descanso
            // },
            {
                label: "Ventana horaria para recogidas",
                children: componente_recogidas
            },
            {
                label: "Ventana horaria para entregas",
                children: componente_entregas
            }
        ]

        return <div ref={configuracionGeneralRef}>
            <Modal width={"70%"} title="Configura tus preferencias" show={showModalConfig} onHide={() => setShowModalConfig(false)} >
                <Modal.Header closeButton >
                    <Modal.Title>Configuración general</Modal.Title>
                </Modal.Header>
                {/* <Tabs defaultActiveKey="1" items={configuraciones.map((item,i) => ({...item, key: (i+1)}))} /> */}
                <Modal.Body>
                <p style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }} className="mt-0"><HiCursorClick /> Toca la opción para configurar</p>
                <Accordion>
                    {
                        configuraciones.map((r,i) => {
                            return <Card>
                            <Accordion.Toggle className="hover" as={Card.Header} eventKey={r.label}>
                            <FaChevronDown /> {r.label}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={r.label}>
                                <Card.Body>
                                    {r.children}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        })
                    }
                </Accordion>
                </Modal.Body>
            </Modal>
            <ButtonGroup>
            <Button disabled={loading} size="sm" className="mb-3" variant="light"       onClick={() => setShowModalConfig(true)} ><IoMdSettings /> CONFIGURACIÓN</Button>
            <Button disabled={loading} size="sm" className="mb-3" variant="success"     onClick={() => crearConductorNuevo()}><MdOutlineRoute /> {loading ? "RUTEANDO, ESPERE UN MOMENTO..." : "RUTEAR"}</Button>
            </ButtonGroup>
        </div>
    }

    const mostrarVehiculos = () => {

        return <div style={{ height: "80vh", maxHeight: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
            { vehiculos.length > 0 ? <Button className="mt-3 w-100" size="sm" variant="light" onClick={() => setVehiculos([...[], ...[]])} ><MdDeleteOutline /> LIMPIAR VEHÍCULOS</Button> : false }
            <div style={{ marginTop: 20 }}>
            <h4 className="mt-0 mb-0" style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }}>{vehiculos.length} Vehículos</h4>
            <p style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }}><HiCursorClick /> Toca el punto para configurar el vehículo</p>
            {
                vehiculos.map((vehiculo,i) => {
                    return <div key={`veh-${i}`}>
                        <Card size="small" className="mb-2">
                        {/* <p className="mb-0">Vehículo</p> */}
                        <DetalleVehiculo data={vehiculo} onChange={(punto) => handleChangeVehiculo(punto)} onDelete={(data) => onDeleteVehicle(data)} />
                        </Card>
                    </div>
                })
            }
            </div>
        </div>
    }

    const mostrarPuntos = () => {
        if(!puntos) return false
        if(Array.isArray(puntos) !== true) return false

        

        return <div style={{ height: "80vh", maxHeight: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
            <h4 className="mt-0 mb-0" style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }}>{puntos.length} Puntos</h4>
            <p style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }}><PiCursorClickFill /> Toca el punto para configurar la parada</p>
            {reprocesoCoordenadas()}
            <Row className="mt-3">
            {
                puntos.map((eq,iq) => {
                    const i_origin = puntos.findIndex(p => p._id === eq.from)
                    const origin = !eq.from || eq.from === "warehouse" ? origen : puntos[i_origin]

                    const direccion = getDireccion1(eq)
                    eq.description = direccion

                    return <Col md={12} key={`punto-${eq._id}`}>
                        <DetallePunto origin={origin} data={eq} onChange={(punto) => handleChangePunto(punto)} onDelete={(id) => eliminarPunto(id)} />
                        { eq.capabilities ? eq.capabilities.map(item => <Badge style={{ marginTop: 10 }}>{item.title}</Badge> ) : false }
                        { configRuteo.activate_boxes === true ? eq.bultos > 0 ? <Badge style={{ marginTop: 10 }}>{eq.bultos} Bultos</Badge> : <Badge color="red">Sin bultos</Badge> : false }
                        { configRuteo.activate_weight === true ? eq.peso > 0 ? <Badge style={{ marginTop: 10 }}>{eq.peso} KG VOL.</Badge> : <Badge color="red">Sin peso</Badge> : false }
                        {(iq+1) < puntos.length ? <hr style={{ marginBottom: 10, marginTop: 10 }} /> : false}
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const handleMouseEnter = (id_vehiculo) => {
        return setVehicleHover(id_vehiculo)
    }
    
    const handleMouseEnterPoint = (id_vehicle, i_point) => {
        return setPointHover({ id_vehicle, i_point })
    }

    const handleMouseLeavePoint = () => {
        return setPointHover(false)
    }

    const handleMouseLeave = () => {
        return setVehicleHover(false)
    }

    const resumenRuta = (paradas) => {
        if(!paradas) return false
        if(Array.isArray(paradas) !== true) return false
        if(paradas.lenght < 1) return false
        return <div>
            <Row >
                <Col xs={4}>
                <p className="mb-0" style={{ fontWeight: "bold" }} >Inicio</p>
                <p>{fechaUTCATiempo(paradas[0].eta)}</p>
                </Col>
                <Col xs={4}>
                <p className="mb-0" style={{ fontWeight: "bold" }} >Término</p>
                <p>{fechaUTCATiempo(paradas[(paradas.length - 1)].eta)}</p>
                </Col>
                <Col xs={4}>
                <p className="mb-0" style={{ fontWeight: "bold" }} >Puntos</p>
                <p>{paradas.length}</p>
                </Col>
            </Row>
        </div>
    }

    const mostrarNoIncluidos = () => {
        if(!resultados) return false
        if(!resultados.dropped) return false
        if(!resultados.dropped.shipments) return false
        if(resultados.dropped.shipments.length < 1) return false

        let paradas = {
            warehouse: {
                description: origen.description
            }
        }
        for( const point of resultados.dropped.shipments ){
            paradas[point.name] = point
        }

        return <div>
            <h4 level={4} className="mt-0 mb-0">{resultados.dropped.length} Puntos no incluidos</h4>
            {
                resultados.dropped.shipments.map((drop,i) => {
                    return <div key={`drop-${i}`}>
                        {drop.name}
                        <p className="mb-0" >{paradas[drop.name]?.description}</p>
                        {(i+1) < resultados.dropped.shipments.length ? <hr style={{ marginBottom: 10, marginTop: 10 }} /> : false}
                    </div>
                })
            }
        </div>
    }

    const botonVolver = () => {
        if(resultados){
            if( visualizationType !== "results" ) return <Button className="mb-3" size="sm" type="primary" style={{ width: "100%" }}  onClick={() => setVisualizationType('results')} ><MdOutlinePlace /> VER RUTA SUGERIDA</Button>
        }
    }

    const recalcularGeometrias = async () => {
        const rutas = resultados.routes.filter(ru => recalculo.includes(ru.vehicle)).map(route => {

            const puntos = route.stops.map((stop,i) => {
                return [ stop.location_metadata.supplied_coordinate[0], stop.location_metadata.supplied_coordinate[1] ]
            })

            return {
                vehicle: route.vehicle,
                puntos
            }

        })

        const url = `${urlapi}/ruteo/draw-polyline`
        setLoadingRecalculo(true)
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                routes: rutas
            }),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                toast.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res)){
                setResultados(prev => {
                    let actual = {...prev}

                    actual.routes.map((ruta,po) => {
                        const i = res.findIndex(r => r.vehicle === ruta.vehicle)
                        if( i > -1) ruta.geometrias = res[i].geometrias
                        return ruta
                    })

                    return {...{}, ...actual}
                })
            }
            setRecalculo([])
            return setLoadingRecalculo(false)
        })
        .catch(error => {
            toast.error("No se pudo efectuar la operación")
            return setLoadingRecalculo(false)
        })

    }

    const mostrarRecalculo = () => {
        if(recalculo.length < 1) return false
        return <div>
            <Button disabled={loadingRecalculo} variant="light" size="sm" className="mb-3 w-100" onClick={() => recalcularGeometrias()} ><RiRouteLine /> REFRESCAR CAMINOS</Button>
        </div>
    }

    const seleccionarRuta = (indexRoute, zone) => {
        desactivarCheckbox()
        console.log({ indexRoute, zone })
        setSeleccionRuta({ indexRoute, zone })
    }

    const reordenarItems = (stops, indexRoute) => {

        setResultados(prev => {
            let actual = {...prev}
            actual.routes[indexRoute].stops = stops
            return {...{}, ...actual}
        })

        setRecalculo(prev => {
            let actual = [...prev]
            if(!actual.includes(indexRoute)) actual.push(indexRoute)
            return actual
        })
    }
 
    const showByVisualizationType = () => {
        switch (visualizationType) {
            case "routing":
                return <Tabs defaultActiveKey="puntos">
                    <Tab eventKey="puntos" title={<div><MdPlace /> Puntos</div>} className="pt-3">
                        {mostrarPuntos()}
                    </Tab>
                    <Tab eventKey="vehiculos" className="pt-3" title={<div><PiTruckBold /> Vehículos</div>}>
                        <ModalConductores onClick={(data) => onSelectVehiculo(data)} />
                        {mostrarVehiculos()}
                    </Tab>
                </Tabs>
            case "results":
                let paradas = {
                    warehouse: {
                        description: origen.description
                    }
                }
                for( const point of puntos ){
                    paradas[point._id] = point
                }

                const results = resultados.routes.map((route,pos) => {
                    let identificador = route.vehicle
                    const i = vehiculos.findIndex(v => v._id === identificador)
                    if( i > -1 ) identificador = vehiculos[i].titulo

                    const ids_locations = route.stops.map(p => p.location)

                    const datos_completos = puntos.filter( punt => {
                        return ids_locations.includes(punt._id)
                    })

                    let zonas = obtenerZonasDePedidos(datos_completos)

                    return {
                        key: `route-${pos}`,
                        label: <div
                            onMouseEnter={(e) => handleMouseEnter(route.vehicle)}
                            onMouseLeave={handleMouseLeave}
                        ><h6 className="mt-0 mb-0"><FaCircle color={colores[pos]} /> {identificador}</h6>
                        <Badge ><MdPlace style={{ verticalAlign: "middle" }} /> {route.stops.filter(p => p.type === "dropoff").length} Entregas <FaChevronDown /></Badge>
                        
                        </div>,
                        children: <div>
                            {resumenRuta(route.stops)}
                            { zonas.map((com,iz) => {

                            let seleccionada = false
                            if(seleccionRuta) if(seleccionRuta.zone === com && seleccionRuta.indexRoute ===  pos) seleccionada = true

                            return <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Toca para reasignar</Tooltip>
                            }
                            ><Badge onClick={() => seleccionarRuta(pos, com)} variant={ seleccionada ? "success" : "dark"} style={{ fontWeight: "normal", textTransform: "uppercase", fontSize: 9, color: "#cac8c8" }} className="mr-2 mb-2 p-2 hover" md={4} key={`z-${iz}`}>{com}</Badge></OverlayTrigger>
                            
                            })} 
                            <hr/>
                            <ReactSortable list={route.stops} setList={(d) => reordenarItems(d, pos)}>
                            {
                                route.stops.map((stop,ii) => {
                                    return <div key={`${route.vehicle}-${ii}`}
                                    onMouseEnter={(e) => handleMouseEnterPoint(route.vehicle, ii)}
                                    onMouseLeave={handleMouseLeavePoint}
                                    >
                                        <p className="mb-0 hover" >
                                            {
                                                stop.type === "dropoff" ? <Form.Check
                                                inline
                                                name={stop.location}
                                                type="checkbox"
                                                className={`delpa-checkpedido-ruteo mr-0 ruta-${pos}-${ii}`}
                                                id={stop.location}
                                                onChange={verificarCheckBoxes}
                                            /> : false
                                            }
                                            {
                                                stop.type === "dropoff" ? <MdDragIndicator className="hover mr-1" color="gray" size={20} style={{ verticalAlign: "top" }} /> : false
                                            }
                                         <b style={{ fontWeight: "bold", backgroundColor: "gray", fontSize: 12, verticalAlign: "middle", padding:"1px 5px", borderRadius: 6, color:"white" }}>{(ii+1)}</b> {paradas[stop.location]?.description}</p>
                                        <Badge style={{ marginTop: 5 }} variant={stop.type === "dropoff" ? "success" : "primary"}><MdPlace /> {stop.type === "dropoff" ? "ENTREGA" : "PUNTO DE CARGA"}</Badge>
                                        <Badge color="blue" className="mb-3" >ETA {fechaUTCATiempo(stop.eta)}</Badge>
                                        {(ii+1) < route.stops.length ? <hr style={{ marginBottom: 10, marginTop: 10 }} /> : false}
                                    </div>
                                })
                            }
                            </ReactSortable>
                        </div>
                    }
                })

                return <div style={{ height: "80vh", maxHeight: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
                    <Button className="mb-3" size="sm" variant="light" onClick={() => setVisualizationType('routing')} ><FaArrowLeft /> VOLVER</Button>
                    {mostrarNoIncluidos()}
                    <h4>{results.length} Rutas sugeridas</h4>
                    {mostrarRecalculo()}
                    <Accordion defaultActiveKey={''} >
                        {
                            results.map(r => {
                                return <Card>
                                    <Accordion.Toggle className="hover" as={Card.Header} eventKey={r.key} style={{ textTransform: "uppercase" }}>
                                        {r.label}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={r.key}>
                                        <Card.Body>
                                            {r.children}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            })
                        }
                    </Accordion>
                    <Button type="primary" loading={loading} className="mt-3" style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => confirmarRutas({})} >CONFIRMAR {results.length} RUTAS</Button>
                </div>
            default:
                break;
        }
    }

    const moderARutaZona = (indexRouteNew) => {
        const seleccionRutaCopia = {...seleccionRuta}
        const { indexRoute, zone } = seleccionRutaCopia
        
        setRecalculo(prev => {
            let actual = [...prev]
            const id_actual = resultados.routes[indexRoute].vehicle
            const id_nuevo = resultados.routes[indexRouteNew].vehicle

            if(!actual.includes(id_actual)) actual.push(id_actual)
            if(!actual.includes(id_nuevo)) actual.push(id_nuevo)

            return actual
        })


        setResultados(prev => {
            let actual = {...prev}

            let stops = []
            let posiciones_eliminar = []

            actual.routes[indexRoute].stops.map((stop,ii) => {
                let data_completa = false
                const i = puntos.findIndex(p => p._id === stop.location)
                if(i > -1) data_completa = puntos[i]
                if(!data_completa) return false
                const zona_actual = obtenerZonaDeOrden(data_completa)
                if(zona_actual) if(zona_actual === zone){
                    stops.push(stop)
                    posiciones_eliminar.push(ii)
                    // actual.routes[indexRoute].stops.splice(ii,1)
                }
            })

            actual.routes[indexRoute].stops = actual.routes[indexRoute].stops.filter((p,ii) => !posiciones_eliminar.includes(ii) )
            console.log(stops)

            const ultima_actual = actual.routes[indexRouteNew].stops[  actual.routes[indexRouteNew].stops.length - 1  ]
            const ultimoIndex = actual.routes[indexRouteNew].stops.length - 1
            actual.routes[indexRouteNew].stops.splice( ultimoIndex, 1 )
            actual.routes[indexRouteNew].stops = [...actual.routes[indexRouteNew].stops,  ...stops]
            actual.routes[indexRouteNew].stops.push(ultima_actual)

            
            return actual
        })

        desactivarCheckbox()
        setSeleccionRuta(false)
    }

    const moderARuta = (indexRoute) => {

        const ids = [...checlBoxIds]

        let ids_involved = []

        setResultados(prev => {
            let actual = {...prev}
            let stops = []

            for( const id of ids ){
            
                actual.routes.map((route,pos) => {

                    route.stops.map((stop,ii) => {
                        if(stop.location === id){
                            stops.push(stop)
                            actual.routes[pos].stops.splice(ii,1)
                            if(!ids_involved.includes(route.vehicle)) ids_involved.push(route.vehicle)
                       }
                    })

                })

             }

             const ultima_actual = actual.routes[indexRoute].stops[  actual.routes[indexRoute].stops.length - 1  ]
             const ultimoIndex = actual.routes[indexRoute].stops.length - 1
             actual.routes[indexRoute].stops.splice( ultimoIndex, 1 )
             actual.routes[indexRoute].stops = [...actual.routes[indexRoute].stops,  ...stops]
             actual.routes[indexRoute].stops.push(ultima_actual)

             return actual
        })

        
        setRecalculo(prev => {
            let actual = [...prev]

            const id_nuevo = resultados.routes[indexRoute].vehicle
            if(!actual.includes(id_nuevo)) actual.push(id_nuevo)

            for( const id of ids_involved ){
                if(!actual.includes(id)) actual.push(id)
            }

            return actual
        })

        desactivarCheckbox()
    }

    const modalSeleccion = () => {
        if(checlBoxIds.length < 1) return false

        let excluir = []

        for( const id of checlBoxIds ){
            resultados.routes.map((route,pos) => {
                route.stops.map((stop,ii) => {
                    if(stop.location === id) excluir.push(route.vehicle)
                })
            })
         }


        return <Card className='mb-3 p-3' style={{ position: "fixed", bottom: 10, right: 10, zIndex:10 }}>
            <Button variant="light" size="sm" className="mb-2" onClick={() => desactivarCheckbox()} >CANCELAR</Button>
            <h5 className='mb-0'>{checlBoxIds.length} Pedidos seleccionados</h5>
            <p className='mb-2'><HiCursorClick /> Toca la ruta para mover estos pedidos dentro</p>
            {
                resultados.routes.map((route,pos) => {

                    if(excluir.includes(route.vehicle)) return false

                    let identificador = route.vehicle

                    const i = vehiculos.findIndex(v => v._id === identificador)

                    if( i > -1 ) identificador = vehiculos[i].titulo
                    return <h5 key={`ruta-${pos}`} className="mt-0 mb-3 hover"
                    onClick={() => moderARuta(pos)}
                    ><FaCircle color={colores[pos]} /> {identificador} <Badge >{route.stops.filter(p => p.type === "dropoff").length} Entregas</Badge> </h5>
                })
            }
        </Card>
    }

    const modalSeleccionRuta = () => {
        if(!seleccionRuta) return false

        return <Card className='mb-3 p-3' style={{ position: "fixed", bottom: 10, right: 10, zIndex:10 }}>
            <Button variant="light" size="sm" className="mb-2" onClick={() => setSeleccionRuta(false)} >CANCELAR</Button>
            <h5 className='mb-0'>Zona seleccionada <b>{seleccionRuta.zone}</b></h5>
            <p className='mb-2'><HiCursorClick /> Toca la ruta para mover los pedidos de <b>{seleccionRuta.zone}</b> </p>
            {
                resultados.routes.map((route,pos) => {

                    if(pos === seleccionRuta.indexRoute) return false

                    let identificador = route.vehicle

                    const i = vehiculos.findIndex(v => v._id === identificador)

                    if( i > -1 ) identificador = vehiculos[i].titulo
                    return <h5 key={`ruta-${pos}`} className="mt-0 mb-3 hover"
                    onClick={() => moderARutaZona(pos)}
                    ><FaCircle color={colores[pos]} /> {identificador} <Badge >{route.stops.filter(p => p.type === "dropoff").length} Entregas</Badge> </h5>
                })
            }
        </Card>
    }

    const onSelectMapPin = (id) => {
        const identificador = `parada-${id}`
        if(document.getElementById(identificador)){
            document.getElementById(identificador).scrollIntoView({ behavior: 'smooth' })
            document.getElementById(identificador).click()
        }
    }

    return <div>
        {modalSeleccion()}
        {modalSeleccionRuta()}
        <Modal width={"70%"} title="Configura tus preferencias" show={loading} >
                <Modal.Body className="text-center">
                    <img src={`${url_images}/animations/Holding Map Paper And Finding Navigation.gif`} style={{ width: "60%", marginBottom: -20 }} />
                    <h2 className="mb-0" style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>Procesando información</h2>
                    <p className="mb-4">Espere un momento...</p>
                </Modal.Body>
        </Modal>
        <SelectorBodega onChange={(data) => seleccionarBodegaOrigen({...data, description: `${data.titulo ? `${data.titulo} ` : ""}${data.direccion}`})} />
        {modalConfiguracion()}
        
        <Row>
            <Col md={8}>
                <MapaRuteo resize={resize} onSelect={(id) => onSelectMapPin(id)} hovered={vehicleHover} point_hovered={pointHover} height={"90vh"} markers={marcadores} resultados={resultados} /></Col>
            <Col md={4}>
            {botonVolver()}
            {showByVisualizationType()}
            </Col>
        </Row>
    </div>
}

export default RuteoCargas;