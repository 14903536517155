import { useEffect, useState } from "react"
import PantallaCargando from "../../general/pantalla_cargando"
import SelectorZonaAvanzado from "../../../components/RadioOperacion/selector_zona_avanzado"
import SelectorServicios from "../../servicios/selector"
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap"
import { url_images } from "../../../lib/global/data"
import { toast } from "react-toastify"
import { cerrarSesion } from "../../../redux/actions/session"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"

const DetalleReglaCotizacion = (props) => {
    const {
        provider
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ loadingSaving, setLoadingSaving ] = useState(false)
    let default_config = { tipo: "economic", zones: [] }
    const [ configuracion, setConfiguracion ] = useState(default_config)
    const [ tipo, setTipo ] = useState('economic')
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const esquema = {
        economic: {
            name: "economic",
            description: "El más económico"
        },
        servicetype: {
            name: "servicetype",
            description: "Preferir tipo de servicio"
        },
        byzones: {
            name: "byzones",
            description: "Definir por zonas"
        },
    }

    const obtenerConfiguracion = async ()=>{
        setConfiguracion({...{}, ...default_config})
        setLoading(true)
        return fetch(`${urlapi}/pricing/config`,{
            method:'POST',
            body: JSON.stringify({
                provider
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setConfiguracion({ ...{}, ...res})
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const actualizarConfiguracion = async (actual)=>{
        setLoadingSaving(true)
        return fetch(`${urlapi}/pricing/config`,{
            method:'PUT',
            body: JSON.stringify(actual),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                
            }
            return setLoadingSaving(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingSaving(false)
        })
    }


    useEffect(() => {
        obtenerConfiguracion()
    }, [provider])

    if(!provider) return false


    const handleChange = (e) => {
        const { name, value } = e.target
        return setConfiguracion(prev => {
            let actual = {...prev}
            actual[name] = value
            actual.tipo_servicio = ''
            actualizarConfiguracion(actual)
            return {...{}, ...actual}
        })
    }

    const onChangeServicio = (e, pos) => {
        return setConfiguracion(prev => {
            let actual = {...prev}
            if(!isNaN( parseInt(pos)) ){
                actual.zones[pos].tipo_servicio = e.value
            } else {
                actual.tipo_servicio = e.value
            }
            actualizarConfiguracion(actual)
            return actual
        })
    }
    
    const onAgregarZona = (e) => {
        return setConfiguracion(prev => {
            let actual = {...prev}
            if(!actual.zones) actual.zones = []
            const i = actual.zones.findIndex(z => z._id === e._id)
            if(i > -1){
                toast.error("Ya agregaste esta zona a tus preferencias")
            } else {
                actual.zones.unshift({ _id: e._id, pais_code: e.pais_code, local_name: e.local_name, slug: e.slug, titulo: e.titulo })
            }
            actualizarConfiguracion(actual)
            return { ...{}, ...actual }
        })
    }

    const eliminarZona = (e) => {
        return setConfiguracion(prev => {
            let actual = {...prev}
            actual.zones.splice(e,1)
            actualizarConfiguracion(actual)
            return { ...{}, ...actual }
        })
    }

    const handleCheck = e => {
        return setConfiguracion(prev => {
            let actual = {...prev}
            actual.active = e.target.checked
            actualizarConfiguracion(actual)
            return {...{}, ...actual}
        })
    }

    const mostrarZonas = () => {
        if(!configuracion) return false
        if(typeof configuracion !== "object") return false
        if(!configuracion.zones) return false

        let rut_proveedor = ''
 
        if(provider._id) if(provider.rut) rut_proveedor = provider.rut

        return <div>
            <Row>
            {
                configuracion.zones.map((zona,iz) => {
                    return <Col md={12} key={`zona-${iz}`}>
                        <Card className="p-2">
                            <h5>{zona.titulo}</h5>
                            <SelectorServicios defaultValue={zona.tipo_servicio} titulo="Tipo de servicio asignado" onChange={data => onChangeServicio(data,iz)} rut_proveedor={rut_proveedor} />
                            <Button size="sm" variant="danger-outline" onClick={() => eliminarZona(iz)}>ELIMINAR</Button>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const mostrarSegunTipo = () => {

        let rut_proveedor = ''
 
        if(provider._id) if(provider.rut) rut_proveedor = provider.rut

        switch (configuracion.tipo) {
            case esquema.economic.name:
                return <div className="p-3 text-center">
                    <img src={`${url_images}/animations/modules/Global delivery.gif`} style={{ width: "60%", maxWidth: 150, marginTop: -40 }} />
                    <h5 className="mb-0">El sistema asignará la tarifa</h5>
                    <h3 className="mb-0" style={{ fontWeight: "bold" }}>más económica</h3>
                    <h5 className="mb-0">a la cotización.</h5>
                </div>
            case esquema.byzones.name:
                return <div>
                    <SelectorZonaAvanzado condicion={{ local_name: "region" }} onSelected={(data) => onAgregarZona(data)} />
                    {mostrarZonas()}
                </div>
            case esquema.servicetype.name:
                return <div>
                    <SelectorServicios defaultValue={configuracion.tipo_servicio} titulo="Tipo de servicio a cotizar" onChange={data => onChangeServicio(data)} rut_proveedor={rut_proveedor} />
                </div>
            default:
                break;
        }
    }

    if(loading) return <PantallaCargando />

    const mostrar_loading = loadingSaving === true ? <div className="d-block text-center"><Spinner animation="border" size="sm" /></div> : false  
    return <div>
        <Card className="p-3 shadow">
        { provider.logotipo ? <div className="text-center"><img className="mb-3" src={provider.logotipo} style={{ maxHeight: 40, maxWidth: "60%", margin: "0 auto" }} />{mostrar_loading}</div>  : <div className="text-center"><h5 className='mb-3' style={{ textTransform: "uppercase" }}>{provider.razon_social}</h5>{mostrar_loading}</div>  }
        <div className="mb-3">
        <Form.Group className="mb-0" controlId="active">
                                <Form.Check type="switch" label="Activar" defaultChecked={configuracion.active === true} onChange={handleCheck} />
                                </Form.Group>

            <label className="form-control-label d-block">Tipo de preferencia en la tarifa</label>
            <select className="form-control" name="tipo" value={configuracion.tipo} onChange={handleChange}>
                {
                    Object.keys(esquema).map(key => {
                        return <option value={esquema[key].name}>{esquema[key].description}</option>
                    })
                }
            </select>
        </div>
        {mostrarSegunTipo()}
        </Card>
    </div>
}

export default DetalleReglaCotizacion