import 'moment/locale/es';
import { Button, Card, Col, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap'
import { useState } from "react";
import data from '../../lib/backend/data'
import { toast } from "react-toastify";
import { fechaATexto } from "../../lib/helpers/helpers";
import { Link } from "react-router-dom";
import OrdenesListado from '../ordenes/listado';
import { url_images } from '../../lib/global/data';
import { FaWhatsapp } from 'react-icons/fa';

const DetailFull = (props) => {
    let vehiculo = false
    const centro_mapa = { lat: -33.458829, lng: -70.654644 }
    const zoom_mapa = 14
    let marcadores = []
    const [codigos, setCodigos] = useState(props.vehiculo.codes)
    const [ loadingWS, setLoadingWS] = useState(false)
    const [eliminandoCodigo, setEliminandoCodigo] = useState(false)
    const [loadingCodigo, setLoadingCodigo] = useState(false)
    const token = props.token ? props.token : false

    if (props.vehiculo) {
        if (props.vehiculo) {
            vehiculo = props.vehiculo
        }
    }

    const eliminarCodigo = async (id) => {
        setEliminandoCodigo(true)
        return fetch(`${data.urlapi}/conductores/deletecode?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(pros => pros.json())
            .then(pros => {
                if (!pros) {
                    toast.error('Sin datos')
                    return setLoadingCodigo(false)
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage)
                    return setLoadingCodigo(false)
                } else if (pros.deletedCount === 1) {
                    const i = codigos.findIndex(co => co._id === id)
                    if (i > -1) {
                        codigos.splice(i, 1)
                        toast.success('Borrado exitosamente')
                        setCodigos(codigos)
                    }
                }
                return setEliminandoCodigo(false)
            })
            .catch(error => {
                toast.error('No se pudo realizar esta operación')
                return setEliminandoCodigo(false)
            })
    }

    const mostrarCodigos = (codes) => {
        if (codes.length < 1) return <p className='text-primary' style={{ fontWeight: 900 }}>No hay códigos generados</p>
        return <div>
            {
                codes.map((codigo, i) => {
                    return <Card key={`code-${codigo._id}`} className="p-2 mb-3 shadow-sm">
                        <h5 className="mb-0" style={{ fontWeight: 900 }}>{codigo.code} {eliminandoCodigo === true ? <Spinner animation="border" /> : <Button style={{ fontSize: 11 }} size="sm" variant="danger" className="text-white px-1 py-0 mb-1" onClick={() => eliminarCodigo(codigo._id)} ><b>ELIMINAR</b></Button>}</h5>
                        <p className="mb-0" style={{ fontSize: 12 }}><b>Creado:</b> {fechaATexto(codigo.createdAt)}</p>
                    </Card>
                })
            }
        </div>
    }

    const crearCodigo = () => {
        setLoadingCodigo(true)
        return fetch(`${data.urlapi}/conductores/newcode?id=${vehiculo._id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(pros => pros.json())
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')
                    return setLoadingCodigo(false)
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingCodigo(false)
                } else if (res._id) {
                    codigos.push(res)
                    toast.success('Código creado exitosamente')
                    setCodigos(codigos)
                }
                return setLoadingCodigo(false)
            })
            .catch(error => {
                toast.error('No se pudo realizar esta operación')
                setLoadingCodigo(false)
            })
    }

    const notificarInstrucciones = () => {
        setLoadingWS(true)
        return fetch(`${data.urlapi}/conductores/send-instructions?id=${vehiculo._id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(pros => pros.json())
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                } else if (res.sucess) {
                    toast.success('Realizado exitosamente')
                }
                return setLoadingWS(false)
            })
            .catch(error => {
                toast.error('No se pudo realizar esta operación')
                setLoadingWS(false)
            })
    }

    const envioInstrucciones = () => {
        return <Button className='shadow-sm mt-auto' size="sm" variant='success' disabled={loadingWS} style={{ fontSize: 12 }} onClick={() => notificarInstrucciones()} ><i class="fa-brands fa-whatsapp"></i> { loadingWS ? <b>ENVIANDO...</b> : <b>ENVIAR INSTRUCCIONES POR WHATSAPP</b>}</Button>
    }

    if (!vehiculo) return <div className='p-5'>
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
                <img alt="pedidos" src="images/pedidos.png" style={{ width: "50%" }} />
                <h4 className='text-primary' style={{ fontWeight: 900 }}>Selecciona un vehículo de la lista para visualizarlo</h4>
            </Col>
        </Row>
    </div>

    return <div>

        <Tabs defaultActiveKey="finalizadas" id="uncontrolled-tab-example">
            <Tab eventKey="finalizadas" title="Órdenes finalizadas" className='bg-white pt-4'>
               <OrdenesListado titulo="Órdenes" showSearch={false} upWhenClick={false} showAddButton={false} force={true} showExporter={false} showFilter={false} token={token} condicion_default={{ idconductor: vehiculo._id, finalizado: true }} />
            </Tab>
            <Tab eventKey="orden" title="Órdenes pendientes" className='bg-white pt-4'>
               <OrdenesListado titulo="Órdenes" showSearch={false} upWhenClick={false} showAddButton={false} force={true} showExporter={false} showFilter={false} token={token} condicion_default={{ idconductor: vehiculo._id, finalizado: { $nin: [ true ] } }} />
            </Tab>
            <Tab eventKey="orden_visible" title="Órdenes visibles en app" className='bg-white pt-4'>
               <OrdenesListado titulo="Órdenes" showSearch={false} upWhenClick={false} showAddButton={false} force={true} showExporter={false} showFilter={false} token={token} condicion_default={{ idconductor: vehiculo._id, show_on_app: true }} />
            </Tab>
            <Tab eventKey="info" title="Información General" className='bg-white pt-4'>
                <Row>
                    <Col md={6} className='pr-1 mb-2'>
                        <Card className="d-flex flex-column p-3 mb-3 text-primary bg-white shadow-sm h-100">
                            <img src={`${url_images}/animations/notifications.gif`} style={{ width: 150 }} />
                            <h5 style={{ fontWeight: 900 }}>Conductor</h5>
                            <label className="mb-0 form-control-label d-block" style={{ fontWeight: 700 }}>Nombres y apellidos</label>
                            <h6>{vehiculo.nombres} {vehiculo.apellidos}</h6>
                            <label className="mb-0 form-control-label d-block" style={{ fontWeight: 700 }}>ID</label>
                            <h6>{vehiculo.rut ? vehiculo.rut : 'Sin datos'}</h6>
                            <label className="mb-0 form-control-label d-block" style={{ fontWeight: 700 }}>Móvil</label>
                            <h6 className="2">{vehiculo.phone}</h6>
                            {envioInstrucciones()}
                        </Card>
                      
                    </Col>

                    <Col md={6} className='pl-1 mb-2'>
                        <Card className="d-flex flex-column p-3 mb-3 text-primary bg-white shadow-sm h-100">
                            <img src={`${url_images}/animations/flota.gif`} style={{ width: 150 }} />
                            <h5 style={{ fontWeight: 900 }}>Vehículo</h5>
                            <label className="mb-0 form-control-label d-block" style={{ fontWeight: 700 }}>Modelo</label>
                            <h6>{vehiculo.modelo}</h6>
                            <label className="mb-0 form-control-label d-block" style={{ fontWeight: 700 }}>Año</label>
                            <h6>{vehiculo.ano}</h6>
                            <label className="mb-0 form-control-label d-block" style={{ fontWeight: 700 }}>Patente</label>
                            <h6 className='mb-3'>{vehiculo.patente ? vehiculo.patente.toUpperCase() : ''}</h6>
                            <Button className='shadow-sm mt-auto' size="sm" variant='primary' style={{ fontSize: 12 }}> <Link className='text-white' to={`/vehiculos/edit/${vehiculo._id}`}><i className="fa-solid fa-arrow-up-right-from-square pl-1"></i> <b>VER DETALLES</b></Link></Button>
                            {/* <hr /> */}
                        </Card>
                    </Col>
                </Row>
                <Row>
                <Col md={12}>
                    <Card className="p-3 mb-3 text-primary bg-white shadow-sm">
                        <div>
                            <h4 className='m-0' style={{ fontWeight: 900 }}><i className="fas fa-lock"></i> Códigos {loadingCodigo === true ? <Spinner animation="border" /> : <OverlayTrigger
                                placement={'right'}
                                overlay={
                                    <Tooltip >Crear nuevo</Tooltip>
                                }
                            >
                                <i className="fa-solid fa-circle-plus" onClick={() => crearCodigo()}></i>
                            </OverlayTrigger>}</h4>
                            <p className='py-0 mb-1' style={{ fontSize: 11 }}><i class="fa-solid fa-circle-info text-warning"></i> Código de acceso para app móvil</p>
                        </div>
                        {mostrarCodigos(codigos)}
                    </Card>
                </Col>
                </Row>
            </Tab>
        </Tabs>

    </div >
}

export default DetailFull
